// cookie settings
export const daysToExpire = 3;
export const cookieName = 'gcb';
export const cookieNameLegacy = 'gcb-legacy';
export const cookieSecurity = location.protocol === 'https:';


export const vivixLogoImage = 'https://gcb-gotervix-homolog.s3-us-west-2.amazonaws.com/static/images/vivix-logo.png'
export const faviconImage = 'https://gcb-gotervix-homolog.s3-us-west-2.amazonaws.com/static/images/favicon.png'


export const ROLE_ADMIN = 'administrator'
export const ROLE_FATURISTA = 'faturista'
export const ROLE_EMPLOYEE = 'employee'
export const ROLE_PROVIDER = 'provider'
export const ROLE_SSMA = 'ssma'

export const ROLES_LABELS = {
  administrator: 'Administrador',
  driver: 'Motorista',
  employee: 'Colaborador',
  faturista: 'Faturista',
  provider: 'Fornecedor',
  robot: 'Robô',
  sap_service: 'Serviço SAP',
  ssma: 'SSMA',
}


export const ALLOWED_FILES = ['pdf', 'png', 'jpg', 'jpeg', 'doc', 'docx'];
