import React, { useContext } from 'react';

import SearchInput from '../SearchInput';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';

import { handleNextPrevious, getPages } from '../../helpers/pagination/utils';
import { Link } from 'react-router-dom';

import AuthContext, { isOnlyProvider } from '../../utils/auth'

import './styles.scss';

const ProviderScopesTable = ({ data, currentPage, setCurrentPage, pageSize, setSearch }) => {

  const userInfo = useContext(AuthContext);
  const onlyProvider = isOnlyProvider(userInfo.role)

  const getStatusColor = (status) => {
    const statusColors = {
      open: '#FABC34',
      approved: '#00B26B',
      waiting: '#707070',
      pendent: '#D92550',
      missing: '#ff8000',
    };

    return statusColors[status];
  };

  const getStatusLabel = (status) => {
    const statusColors = {
      open: 'Em aberto',
      approved: 'Aprovada',
      waiting: 'Aguardando análise',
      pendent: 'Pendente com restrições',
      missing: 'Pendente Dados Fornecedor',
    };

    return statusColors[status];
  };

  return (
    <section className='provider-scopes-table fade-in'>
      <div className='header'>
        <div className='title'>ESCOPOS DE SERVIÇO CONTRATADOS</div>
          <SearchInput
            setValue={ setSearch }
            placeholder={ 'Buscar por gestor do contrato' }
          />
      </div>
      <div className='table-container'>
        <table className='table table-striped table-borderless'>
          <thead>
            <tr>
              <th scope='col'><div id='scope-id'>ID do Escopo</div></th>
              <th scope='col'>Título do escopo</th>
              <th scope='col'>Gestor do contrato</th>
              <th scope='col'>Período da atividade</th>
              <th scope='col'>Acesso refeitório</th>
              <th scope='col'>Status</th>
              {
                    !onlyProvider && (
                      <th scope='col'>Fornecedor</th>
                    )
              }
            </tr>
          </thead>
          <tbody>
            {
              data.results && data.results.map((item, index) => (
                <tr className='fade-in' key={ item.id }>
                  <td>
                    <Link className='link' to={`/fornecedor/escopos-contratados/${item.id}`}>
                      { item.id }
                    </Link>
                  </td>
                  <td style={{textAlign: 'left'}}>
                    <Link className='link' to={`/fornecedor/escopos-contratados/${item.id}`}>
                      { item.title }
                    </Link>
                  </td>
                  <td>
                    <Link className='link' to={`/fornecedor/escopos-contratados/${item.id}`}>
                      { item.requester }
                    </Link>
                    </td>
                  <td>
                    <Link className='link' to={`/fornecedor/escopos-contratados/${item.id}`}>
                      { item?.initial_date } - { item?.end_date }
                    </Link>
                  </td>
                  <td>
                    <Link className='link' to={`/fornecedor/escopos-contratados/${item.id}`}>
                      { item.meal }
                    </Link>
                  </td>
                  <td className='status'>

                      <div
                        style={{ backgroundColor: getStatusColor(item.status) }}
                        className='status-container'
                      >
                        <Link className='link' to={`/fornecedor/escopos-contratados/${item.id}`}>
                            { getStatusLabel(item.status) }
                        </Link>
                      </div>
                  </td>
                  {
                    !onlyProvider && (
                      <td>{ item.provider_company }</td>
                    )
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    <div className = 'paginator'>
        <div className='paginator-container'>
          <nav>
            <ul className='pagination'>
              <li className={`page-item ${data.previous ? '' : 'disabled'}`}>
                <a
                  className='page-link'
                  onClick={() => handleNextPrevious(data.previous, setCurrentPage)}
                  >
                  <div className='item-right'>
                      <TiChevronLeft />
                      <p>Anterior</p>
                    </div>
                </a>
              </li>
              {
                getPages(data.count, pageSize) && getPages(data.count, pageSize).map(page => (
                  <li
                    key={page}
                    onClick={() => setCurrentPage(page)}
                    className={`page-item ${page == currentPage ? 'active' : ''} `}>
                    <a className='page-link'>{ page }</a>
                  </li>
                ))
              }
              <li className={`page-item ${data.next ? '' : 'disabled'}`}>
                <a
                  className='page-link'
                  onClick={() => handleNextPrevious(data.next, setCurrentPage)}
                  >
                    <div className='item-right'>
                      <p>Próximo </p>
                      <TiChevronRight />
                    </div>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  )
};

export default ProviderScopesTable;
