const handleNextPrevious = (link, setCurrentPage) => {
    let page = 1
    if(link.includes('page'))
      page = link.slice(-1);
    setCurrentPage(page);
}

const getPages = (tableDataCount, pageSize) => {
  const numPages = Math.ceil(tableDataCount / pageSize);
  const pages = Array.from({ length: numPages }, (item, index) => index+1);
  return pages
}


export {
    handleNextPrevious, getPages
}