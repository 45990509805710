import React, { useState, useEffect, useRef } from 'react';

import CollaboratorDocumentation from '../CollaboratorDocumentation';
import CalendarInput from '../../../components/CalendarInput';
import Loading from '../../../components/Loading';
import Toast from '../../../components/Toast';
import Modal from '../../../components/Modal';
import SupportedExtensionsInfo from '../../../components/SupportedExtensionsInfo';
import { FaTrash } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { stringToDate, dateToString } from '../../../helpers/forms/convert';
import { DocumentManagementService } from '../../../services';

import './styles.scss';
import { filepathToBasename } from '../../../helpers/forms/parse';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { downloadDocumentFile, isFileExtensionSupported } from '../../../utils/files';
import { AiOutlinePlus } from 'react-icons/ai';
import { BsPaperclip, BsUpload } from 'react-icons/bs';


const Documentations = ({ scopeId }) => {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [equipamentsDocuments, setEquipamentsDocuments] = useState([]);
  const [employeesDocuments, setEmployeesDocuments] = useState([]);
  const [equipamentsSectionOpen, setEquipamentsSectionOpen] = useState(false);
  const [showNewEquipamentForm, setShowNewEquipamentForm] = useState(false);
  const [newEquipamentFile, setNewEquipamentFile] = useState(null);
  const [newEquipamentDescription, setNewEquipamentDescription] = useState('');
  const [newEquipamentExpireDate, setNewEquipamentExpireDate] = useState('');
  const [shouldUpdateEmployeeData, setShouldUpdateEmployeeData] = useState(true);
  const [shouldUpdateEquipmentData, setShouldUpdateEquipmentData] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalButtons, setModalButtons] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [showSupportedExtensions, setShowSupportedExtensions] = useState(false);
  const attachFile = useRef(null);

  const getStatusColor = (status) => {
    if (!status) return 'waiting';
    const classes = {
      'waiting': 'waiting',
      'according': 'according',
      'not_according': 'not-according',
      'not_applicable': 'not-applicable-color'
    }
    if (!Object.keys(classes).includes(status)) {
      throw new Error('Status do documento está incorreto')
    }
    return classes[status]
  }

  const getEquipamentsDocuments = async () => {
    try {
      const { data } = await DocumentManagementService.getProviderEquipamentsDocumentations();
      setEquipamentsDocuments(data);
      setShouldUpdateEquipmentData(false);
    } catch (error) { }
  }

  const getEmployeesDocuments = async () => {
    try {
      const { data } = await DocumentManagementService.getProviderEmployeesDocumentations(scopeId);
      setEmployeesDocuments(data);
      setShouldUpdateEmployeeData(false);
    } catch (error) { }
  }

  useEffect(() => {
    getEquipamentsDocuments();
    getEmployeesDocuments();
    setPageIsLoading(false);
  }, [])

  useEffect(() => {
    getEmployeesDocuments();
    setShouldUpdateEmployeeData(false);
    if (shouldUpdateEquipmentData) {
      getEquipamentsDocuments();
      setShouldUpdateEquipmentData(false);
    }
  }, [shouldUpdateEmployeeData, shouldUpdateEquipmentData])

  const handleNewEquipamentFormChange = (field, value) => {
    const setField = {
      file: setNewEquipamentFile,
      description: setNewEquipamentDescription,
      expireDate: setNewEquipamentExpireDate
    }
    setField[field](value);
  }

  const clearNewEquipament = () => {
    setNewEquipamentFile(null);
    setNewEquipamentDescription('');
    setNewEquipamentExpireDate('');
    setShowNewEquipamentForm(false);
  }

  const fileDrop = (event) => {
    event.preventDefault();
    const dragFile = event.dataTransfer.files;
    handleFile(dragFile);
  }

  const handleFileChange = (event) => {
    const inputFile = event.target.files;
    handleFile(inputFile);
  }

  const handleFile = (addFile) => {
    if (addFile.length) {
      const file = addFile[0];

      if (!isFileExtensionSupported(file)) {
        setShowSupportedExtensions(true);
        return;
      }

      file.id = `${(new Date()).getTime()}-${0}`;
      file.link = URL.createObjectURL(file);
      file.file_name = file.name.replace(/\s/g, '_');

      setShowSupportedExtensions(false);
      setNewEquipamentFile(file);
    }
  }

  const showDeleteDocumentModal = (document, equipmentName) => {
    const buttons = getEquipModalButtons(document.id);
    setModalTitle(
      'Confirmação de remoção de documento de equipamentos'
    )
    setModalBody(
      `Tem certeza que deseja remover o arquivo referente ao documento "${equipmentName}"?`
    )
    setModalButtons(buttons);
    setModal(true);
  }

  const removeEquipamentFile = async (equipamentId) => {
    try {
      Toast.warning('Removendo documentação do equipamento');
      await DocumentManagementService.removeProviderEquipamentDocumentation(equipamentId);
      let equipamentIndex = equipamentsDocuments.findIndex((equipamentDocument) => equipamentDocument.id == equipamentId);
      if (equipamentIndex != -1) {
        equipamentsDocuments.splice(equipamentIndex, 1);
        setEquipamentsDocuments(equipamentsDocuments);
      }
      Toast.hide();
      Toast.success('Documentação removida com sucesso')
      setShouldUpdateEquipmentData(true);
    } catch (error) { }
  }

  const isEquipmentFormValid = () => {
    // verifica se todos os campos do form de novo equipamento não são nulos
    let message;
    if (!newEquipamentFile) {
      message = 'Verifique se o documento do certificado foi inserido no formulário';
    } else if (!newEquipamentDescription) {
      message = 'Verifique se a descrição do certificado foi inserida no formulário';
    } else if (!newEquipamentExpireDate) {
      message = 'Verifique se a data de validade do documento foi inserida no formulário';
    }
    if (message) {
      Toast.error(message);
      return false;
    }
    return true;
  }

  const handleFileDownload = async (documentId) => {
    if (!documentId) return;
    Toast.warning(`Iniciando download do documento`);
    const { status, message } = await downloadDocumentFile(documentId);
    Toast.hide()
    if (status == 200) {
      Toast.success(message);
      return;
    }
    Toast.error(message);
  }

  const sendNewEquipament = async () => {
    if (!isEquipmentFormValid()) return
    Toast.warning('Cadastrando novo equipamento')
    const formData = new FormData();
    formData.append('file', newEquipamentFile);
    formData.append('expires_at', newEquipamentExpireDate);
    formData.append('description', newEquipamentDescription);

    try {
      const { data } = await DocumentManagementService.postProviderEquipamentDocumentation(formData);
      equipamentsDocuments.push(data);
      setEquipamentsDocuments(equipamentsDocuments);
      clearNewEquipament();
      Toast.hide();
      Toast.success('Documento submetido com sucesso');
      setShouldUpdateEquipmentData(true);
    } catch (error) {
      Toast.hide();
      const { response: { data } } = error;
      let errors = data.error || { message: [data.message] } || {};
      Object.keys(errors).map(function (field) {
        Toast.error(errors[field][0]);
      });
    }
  }

  const tratFileName = (fileName) => {
    return filepathToBasename(fileName);
  }

  const getEquipModalButtons = (equipmentId) => {
    const buttons = [
      {
        className: 'cancel',
        label: 'Cancelar',
        onClick: function () {
          setModal(false)
        }
      },
      {
        className: 'confirm',
        label: 'Confirmar',
        onClick: function () {
          removeEquipamentFile(equipmentId);
          setModal(false);
        }
      }
    ]
    return buttons;
  }

  return (
    <section className='scope-collaborators-page-documentations fade-in fade-in-1s'>
      <Modal
        active={modal}
        disableFunction={setModal}
        disableWhenClickAway={true}
        fixed={true}
      >
        <ConfirmationModal title={modalTitle} body={modalBody} buttons={modalButtons} />
      </Modal>
      {
        pageIsLoading && (
          <div className='loading'>
            <Loading />
          </div>
        )
      }
      {
        !pageIsLoading && (
          <>
            <div className='documents-container'>
              <div className='equipaments-container'>
                <div className='equipaments-header'>
                  <div className='equipaments-container-title'>
                    <div className='equipaments-container-title-icon'>
                      <MdKeyboardArrowRight className={equipamentsSectionOpen ? 'rotate' : ''} />
                    </div>
                    <span onClick={() => setEquipamentsSectionOpen(!equipamentsSectionOpen)}>Documentação de Equipamentos</span>
                  </div>
                  {equipamentsSectionOpen == true &&
                    <button
                      className='add-new-document'
                      onClick={() => setShowNewEquipamentForm(true)}
                      disabled={showNewEquipamentForm}
                    >
                      <AiOutlinePlus className='icon' />
                      Adicionar nova documentação
                    </button>
                  }
                </div>
                {
                  equipamentsSectionOpen ?

                    <div className='documents-infos'>
                      <div className='documents-list'>
                        {
                          equipamentsDocuments && equipamentsDocuments.map((equipament, index) => (
                            <div className='document' key={`equipament-documentation-${index}`}>
                              <div className='document-file'>
                                <div className='file fields'>
                                  <span className='field-description'>CERTIFICADO {index + 1}*</span>
                                  <div className={`file-upload ${getStatusColor(equipament.status)}`} disabled>
                                    <div
                                      className='drop-zone'
                                      disabled
                                    >
                                      <div>
                                        <BsPaperclip />
                                        <span onClick={(e) => handleFileDownload(equipament.id)}>
                                          {tratFileName(equipament.file) || '-----------------'}
                                        </span>
                                      </div>
                                      <div
                                        className={`delete-file ${getStatusColor(equipament.status)}`}
                                        onClick={() => showDeleteDocumentModal(equipament, `Certificado ${index + 1}`)}
                                      >
                                        <FaTrash className='icon' />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='document-data'>
                                <div className='document-description fields'>
                                  <span className='field-description'>DESCRIÇÃO DO ARQUIVO*</span>
                                  <input
                                    type='text'
                                    value={equipament.description}
                                    placeholder='Descreva o arquivo'
                                    className='input-fields'
                                    disabled
                                  />
                                </div>
                                <div className='document-date fields'>
                                  <span className='field-description'>DATA DE VALIDADE*</span>
                                  <CalendarInput
                                    id={`equipament_expires_at-${equipament.id}`}
                                    name='equipament_expires_at'
                                    minDate={new Date()}
                                    initialValue={stringToDate(equipament.expires_at)}
                                    className='input input-fields'
                                    disabled
                                  />
                                </div>
                              </div>
                              {
                                equipament?.reason &&
                                <div className='document-analysis'>
                                  <label className='label reason-title'>MOTIVO DA ANÁLISE</label>
                                  <div className='reason-content'>
                                    <span>{equipament.reason}</span>
                                  </div>
                                </div>
                              }
                            </div>
                          ))
                        }
                        {
                          showNewEquipamentForm
                            ?
                            <div className='document'>
                              <div className='document-file'>
                                <div className='file fields'>
                                  <span className='field-description'>CERTIFICADO*</span>
                                  <div className='file-upload'>
                                    <input
                                      type='file'
                                      ref={attachFile}
                                      onChange={handleFileChange}
                                      hidden
                                    />
                                    <div
                                      className='drop-zone'
                                      onDragOver={(event) => event.preventDefault()}
                                      onDragEnter={(event) => event.preventDefault()}
                                      onDragLeave={(event) => event.preventDefault()}
                                      onDrop={fileDrop}
                                      onClick={() => attachFile.current.click()}
                                    >
                                      <span className='send-file'>
                                        {
                                          newEquipamentFile
                                            ?
                                            newEquipamentFile.file_name
                                            :
                                            <>
                                              <BsUpload className='icon' />
                                              Clique ou solte um documento para carregar o arquivo
                                            </>
                                        }
                                      </span>
                                    </div>
                                  </div>
                                  {
                                    showSupportedExtensions && <SupportedExtensionsInfo />
                                  }
                                </div>
                                <div className='document-actions'>
                                  <button
                                    className='btn save'
                                    onClick={sendNewEquipament}
                                  >
                                    Salvar
                                  </button>
                                  <div
                                    className='delete-file'
                                    onClick={clearNewEquipament}
                                  >
                                    <FaTrash />
                                  </div>
                                </div>
                              </div>
                              <div className='document-data'>
                                <div className='document-description fields'>
                                  <span className='field-description'>DESCRIÇÃO DO ARQUIVO*</span>
                                  <input
                                    type='text'
                                    placeholder='Descreva o arquivo'
                                    className='input-fields'
                                    value={newEquipamentDescription}
                                    onChange={(e) => handleNewEquipamentFormChange('description', e.target.value)}
                                  />
                                </div>
                                <div className='document-date fields'>
                                  <span className='field-description'>DATA DE VALIDADE*</span>
                                  <CalendarInput
                                    name='equipament_expires_at'
                                    minDate={new Date()}
                                    className='input input-fields'
                                    initialValue={newEquipamentExpireDate ? stringToDate(newEquipamentExpireDate) : ''}
                                    onChange={(date) => handleNewEquipamentFormChange('expireDate', dateToString(date))}
                                  />
                                </div>
                              </div>
                            </div>
                            :
                            null
                        }
                      </div>
                    </div>
                    :
                    null
                }
              </div>
            </div>
            {
              employeesDocuments && employeesDocuments.map((employee, index) =>
                <CollaboratorDocumentation
                  key={index}
                  employee={employee.employee_provider}
                  ssmaRequirements={employee.ssma_requirements}
                  scopeId={scopeId}
                  shouldUpdateData={() => setShouldUpdateEmployeeData(!shouldUpdateEmployeeData)}
                  setModal={setModal}
                  setModalTitle={setModalTitle}
                  setModalBody={setModalBody}
                  setModalButtons={setModalButtons}
                />
              )
            }
          </>
        )
      }
    </section>
  )

}

export default Documentations;
