import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const dateToString = (date) => {
  return format(date, 'dd/MM/yyyy');
}

const dateToCSVfilename = (date) => {
  return format(date, `dd-MM-yyyy_HH_mm_ss`);
}

const stringToDate = (string) => {
  let [day, month, year] = string.split('/');
  let date = new Date(`${year}/${month}/${day}`);
  return date;
}

export {
  dateToString,
  stringToDate,
  dateToCSVfilename
}