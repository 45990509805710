import Validator from '@this-empathy/javascript-validator';
import { stringToDate } from './convert';

const isCpfValid = (cpf) => {
    // valida o algoritmo do cpf
    return Validator.cpf('cpf', cpf).valid;
}

const isCnhValid = (cnh) => {
    // valida o algoritmo do cnh
    return Validator.cnh('cnh', cnh).valid;
}

const isDateEqualGreaterThanToday = (date) => {
    // função para checar a validade da cnh nos forms de criação/edição do motorista
    let newDate = stringToDate(date);
    let today = new Date();
    today = today.setHours(0,0,0,0);
    return newDate >= today;
}

const isVivixNumberValid = (vivixNumber, isSaving) => {
    if (vivixNumber == '' || isSaving) return true;
    const pattern = /[A-Z]{2}\d{2}\_[A-Z]\d{2}\_[A-Z]{2}\d{3}\_[A-Z]\d\_\d{4}\_\d{2}/;
    return pattern.test(vivixNumber);
}

/*
 * Rececbe uma string do seguinte formato: "13:59"
 */
const isTimeValid = (time) => {
    if (!time) return false

    let [hour, minute] = time.split(':')
    hour = Number(hour)
    minute = Number(minute)

    if (isNaN(hour) || isNaN(minute)) return false
    if (hour < 0 || hour > 23) return false
    if (minute < 0 || minute > 59) return false

    return true
}

export {
    isCpfValid,
    isDateEqualGreaterThanToday,
    isCnhValid,
    isVivixNumberValid,
    isTimeValid,
}
