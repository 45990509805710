import React from 'react';

import './styles.scss';

import * as SourceCKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '../../helpers/ckeditor/ckeditor';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br.js';

const CKClassic = ({ data, setData, setValue, getValues, inputName }) => {
  const config = {
    language: 'pt-br',
    toolbar: [
      'heading', 'bold', 'italic',
      // 'underline', 'strikethrough', 'fontSize', 'fontFamily', 'fontColor',
      '|',
      'bulletedList', 'numberedList',
      'indent', 'outdent',
      // 'alignment'
      '|',
       'insertTable', 'selectAll', 'undo', 'redo', 'enter', 'shiftEnter', 'imageUpload',
    ],
    image: {
      styles: [
          'alignLeft'
      ],
      toolbar: [
          'imageStyle:alignLeft'
      ]
    }
    // removePlugins: [
    //   'MediaEmbed', 'Image', 'EasyImage', 'ImageCaption', 'ImageStyle', 'ImageToolbar',
    //   'BlockQuote', 'Link', 'ImageUpload'
    // ]
  }

  return  (
    <>
      <SourceCKEditor
        editor={ ClassicEditor }
        config={ config }

        data={ getValues(inputName) || ''}
        onChange={( event, editor ) => {
          setValue(inputName, editor.getData(), {
            shouldValidate: true, shouldDirty: true
          })
        }}
      />
    </>
  );
}

export { CKClassic }
