import React from 'react';

import { AiOutlineInfoCircle } from 'react-icons/ai';

import { ALLOWED_FILES } from '../../constants'

import './styles.scss';

const SupportedExtensionsInfo = () => {
  return (
    <div className='info'>
      <AiOutlineInfoCircle className='icon'/>
      <span className='text'>
        Extensões de aquivo suportadas: {ALLOWED_FILES.join(', ')}
      </span>
    </div>
  )
}

export default SupportedExtensionsInfo;