import { DocumentManagementService } from '../services/';
import { ALLOWED_FILES } from '../constants';

const getFilenameFromContentDisposition = (headers) => {
  const contentDisposition = headers['content-disposition'];
  if (!contentDisposition) {
    console.log('content-disposition nao encontrado', headers);
    throw new Error('Nome do arquivo não encontrado no header do response');
  }
  const filename = contentDisposition.split('=').slice(-1)[0];
  return filename;
}

const saveFile = (response, filename=null) => {
  const { data, headers } = response;
  // workaround para realizar o download do arquivo renomeado
  var blob = new Blob([response.data], { type: data.type });
  var link = window.document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename || getFilenameFromContentDisposition(headers);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const downloadDocumentFile = async (documentId, docName=null) => {
  try {
    const response = await DocumentManagementService.downloadDocument(documentId);
    await saveFile(response, docName);
    const message = 'Download concluído com sucesso';
    return { 'status': response.status, message };
  } catch (error) {
    const { response: { data, status } } = error;
    const blob = new Blob([data], {type: 'application/json'});
    const dataText = await blob.text()
    const messageObj = JSON.parse(dataText);
    const message = messageObj.message || messageObj.error || messageObj.detail;
    return {status, message};
  }
}

const isFileExtensionSupported = (file) => {
  if (!file) return false;

  const fileExtension = String(file.name.split('.').pop()).toLowerCase()
  if (!ALLOWED_FILES.includes(fileExtension)) return false;

  return true;
}

export { downloadDocumentFile, saveFile, isFileExtensionSupported };
