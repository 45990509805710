import React, { useState, useContext } from 'react';

import { MdKeyboardArrowDown } from 'react-icons/md';
import { FaRegUser } from 'react-icons/fa';

import AuthContext, { getRolesLabels, isOnlyProvider } from '../../utils/auth';

import './styles.scss';


const UserOptions = ({ aliasName, doLogOut }) => (
  <div className='header-user-opitions fade-in'>
    <div className='user'>
      <div className='text'>
        <span className='name'>{aliasName}</span>
        <span className='role'>{getRolesLabels()}</span>
      </div>
    </div>
    <div className='button-container'>
      <button
        onClick={ doLogOut }
        className='button'
      >
        Sair
      </button>
    </div>
  </div>
)

const Header = ({ sideNavWidth, doLogOut, isExpanded }) => {
  const [userOptions, setUserOptions] = useState(false);
  const userInfo = useContext(AuthContext)

  const aliasName = isOnlyProvider(userInfo.roles) ? userInfo.company : userInfo.name

  return (
    <section
      style={{
        width: `calc(100% - ${sideNavWidth})`,
        marginLeft: `${sideNavWidth}`
      }}
      className={`main-header ${isExpanded ? 'expanded' : ''}`}>
      {/* <div className='breadcrumb'>
        <AiFillHome className='icon' />
        <span className='text'>/GoterVix - Faturista</span>
      </div> */}
      <div className='left'>
        <div className='user-container'>
          <button
            onClick={() => setUserOptions(!userOptions)}
            className='avatar'
          >
            <FaRegUser className='user-icon' />
            <MdKeyboardArrowDown className='icon' />
            {
              userOptions && (
                <UserOptions
                  setActive={setUserOptions}
                  aliasName={aliasName}
                  doLogOut={ doLogOut }
                />
              )
            }
          </button>
          <div className='text-container'>
            <span className='name'>{aliasName}</span>
            <span className='role'>{getRolesLabels()}</span>
          </div>
        </div>
        {/* <div className='notifications-container'>
          <button className='button'>
            <BsBellFill className='icon'/>
            <span className='ball'>5</span>
          </button>
        </div> */}
      </div>
    </section>
  );
}

export default Header
