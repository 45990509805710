import React from 'react';

import './styles.scss';

/*
Apenas foi definido neste componente, o layout do header, body e footer com botões da classe cancel e confirm.

O parâmetro buttons deve ser uma lista de objetos com as propriedades necessárias, por exemplo:
buttons = [
  {
    'className': 'cancel',
    'label': 'Cancelar'
    'onClick': function
  }, 
  {
    'className': 'confirm',
    'label': 'Confirmar',
    'onClick': function
  }, 
]
*/ 


const ConfirmationModal = ({ title, body, buttons}) => {
  return (
    <div className='modal-container'>
      <div className='header'>{ title }</div>
      <div className='body'>{ body }</div>
      <div className='footer'>
        {
          Array.isArray(buttons) &&
          buttons.map( (btn, index) => {
            return (
              <button
                key={index}
                className={`button ${btn.className}`}
                onClick={() => btn.onClick ? btn.onClick() : null}
              >
              { btn.label }
              </button>
            )
          })
        }
      </div>
    </div>
  );
}

export default ConfirmationModal;