import React from 'react';

import './styles.scss';

const ProviderScopeSummary = ({ data }) => {
  return (
    <div className='scope-info'>
      <div className='scope-info-column'>
        <p className="id-escopo">ID do Escopo: {data.id || ''}</p>
        <p className="titulo-atividade">Título da atividade: <br />{data.title || ''}</p>
        <p>Nº do pedido: {data.purchase_order_number || ''}</p>
      </div>
      <div className='scope-info-column'>
        <p>Período da atividade: <br /> de: {data.initial_date || ''} até {data.end_date || ''}</p>
        <p>Gestor do contrato: <br /> {data.requester || ''}</p>
        <p>E-mail para notificações: <br />{data.provider_email || ''}</p>
      </div>
      <div className='scope-info-column'>
        <p>Telefone (Principal): <br />{data.provider_phone_main || ''}</p>
        <p>Telefone (SSMA): <br />{data.provider_phone_ssma || ''}</p>
        <p>Telefone (RH): <br />{data.provider_phone_rh || ''}</p>
        <p>Telefone (Área Técnica): <br />{data.provider_phone_technical_area || ''}</p>
      </div>
    </div>
  )
}

export default ProviderScopeSummary;