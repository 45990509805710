import api from './api'

class VisitationService {
  static visitHistory(page, pageSize, driverId, body) {
    return api.post(
      `visitations/search/?id=${driverId || ''}&page=${page}&limit=${pageSize}`,
      body,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static preRegistrations(page, pageSize, search) {
    return api.post(
      `visitations/search/?page=${page}&limit=${pageSize}`,
      {
        type: 'pre-register',
        search: search || ''
      },
      { headers: { ...api.getAuthToken() } }
    );
  }

  static statusList() {
    return api.get('visitations/status/', { headers: { ...api.getAuthToken() } })
  }

  static createPreRegister(data) {
    return api.post('users/drivers/', { ...data })
  }

  static validatePreRegister(visitId, data) {
    // valida um pre-registro de motorista
    return api.patch(`visitations/${visitId}/`, { ...data }, {
      headers: {
        ...api.getAuthToken(),
      }
    });
  }

  static vehicles(page, pageSize) {
    return api.get(
      `vehicles/?page=${page}&limit=${pageSize}`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static vehiclesFromDriver(page, pageSize, data) {
    return api.post(
      `vehicles/vehicles-from-driver/?page=${page}&limit=${pageSize}`,
      { ...data },
      { headers: { ...api.getAuthToken() } }
    );
  }

  static put_status(id, body) {
    return api.put(
      `visitations/${id}/change_status/`,
      body,
      { headers: { ...api.getAuthToken() } }
    )
  }

  static retrive(id) {
    return api.get(
      `visitations/${id}/`,
      { headers: { ...api.getAuthToken() } }
    )
  }

  static downloadCSVFile(body) {
    return api.post(
      'visitations/export/csv/ ', body, {
      headers: {
        ...api.getAuthToken(),
      },
      responseType: 'blob'
    });
  }
}

export default VisitationService;
