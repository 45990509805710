class KeepLoggedLocalStorage {
  static getKeepLogged() {
    return localStorage.getItem('KeepLogged') == 'true'
  }

  static setKeepLogged(value) {
    return localStorage.setItem('KeepLogged', value)
  }
}

export { KeepLoggedLocalStorage }
