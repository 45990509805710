import React, { useState, useEffect, useContext } from 'react'

import { DocumentManagementService } from '../../../services'

import Loading from '../../../components/Loading'
import TableFilter from '../../../components/TableFilter'
import TablePeriodFilter from '../../../components/TablePeriodFilter'
import DocumentationTable from '../../../components/ssma/DocumentationTable'
import FilterContext from '../../../components/SideNav/context'
import FilterContent from '../../../components/FilterContent'
import Toast from '../../../components/Toast';
import { dateToCSVfilename } from '../../../helpers/forms/convert';
import { saveFile } from '../../../utils/files';

import './styles.scss'


const SSMADocumentation = ({ history }) => {
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  //const [filterItems, setFilterItems] = useState([]);
  // const [period, setPeriod] = useState({})
  const [filterBody, setFilterBody] = useState(null);
  const { setFilter, setFilterItems, setChanges, setPeriod, period, filterItems } = useContext(FilterContext);
  const [searchText, setSearchText] = useState('');
  const [shouldUpdateData, setShouldUpdateData] = useState(false);


  const getTableItems = async (newBody) => {
    let body = newBody !== undefined ? newBody : {
      ...filterBody,
      search: searchText,
    };
    let response;

    console.log(body)

    if (!tableData) {
      const { data } = await DocumentManagementService.searchProviderHiredServiceScopes(1, 10);
      response = data;
    } else {
      const { data } = await DocumentManagementService.searchProviderHiredServiceScopes(currentPage, pageSize, body);
      response = data;
    }
    setTableData(response)
  }

  const refreshTable = async () => {
    if (currentPage == 1) {
      const { data } = await DocumentManagementService.searchProviderHiredServiceScopes(1, 10)
      setTableData(data)
      setShouldUpdateData(false)
    } else {
      setCurrentPage(1)
    }
  }

  const getBody = (childData) => {
    let newBody = {
      search_company_or_manager: childData.search,
      analysis_status: childData.status,
      type_search: childData.type_search.split(" ")[0]
    }

    if (childData.provide_scope_initial_date && childData.provide_scope_end_date) {
      newBody.provide_scope_initial_date = childData.provide_scope_initial_date.replaceAll('-', '/').split("/").reverse().join("/"),
        newBody.provide_scope_end_date = childData.provide_scope_end_date.replaceAll('-', '/').split("/").reverse().join("/")
    }

    newBody.manage_scopes = false;

    setCurrentPage(1)
    getTableItems(newBody);
  }

  const statusArray = [
    { label: "Pendente com restriçoes", status: "pendent", checked: false },
    { label: "Em análise", status: "analyzing", checked: false },
    { label: "Aprovada", status: "approved", checked: false },
    { label: "Aguardando", status: "waiting", checked: false },
    { label: "Pendente Dados Fornecedor", status: "missing", checked: false },
  ];

  // Array responsável por definir as opções de escolha da pesquisa
  const searchArray = [
    { label: "ID do Escopo", checked: true },
    { label: "Título do escopo", checked: false },
    { label: "Gestor do contrato", checked: false },
    { label: "Tipo de documentação", checked: false },
  ];

  const getFilterItems = async () => {
    setFilterItems([
      {
        title: 'Status da análise', id: 'analysis_status', options: [
          { id: 'waiting', name: 'Aguardando' },
          { id: 'approved', name: 'Aprovada' },
          { id: 'analyzing', name: 'Em análise' },
          { id: 'pendent', name: 'Pendente com restrições' },
          { id: 'missing', name: 'Pendente Dados Fornecedor' },
        ]
      },
      {
        title: 'Status da documentação', id: 'documentation_status', options: [
          { id: 'according', name: 'Conforme' },
          { id: 'not_according', name: 'Não conforme' },
          { id: 'not_applicable', name: 'Não aplicável' },
        ]
      },
    ])
  }

  const handdleTableChangePage = (page) => {
    setCurrentPage(page)
  }

  const handdleFilterChange = (data) => {
    setFilterBody(data)
    setCurrentPage(1)
  }

  // const handlePeriod = (period) => {
  //   setPeriod(period)
  //   setCurrentPage(1)
  // }

  const handleCSVButton = async () => {
    // base do body antes de populado
    let body = {
      id: tableData.results[0].csv_data,
      csv_type: "ssma"
    }

    // map populando o body
    // tableData.results.map((item)=>{
    //   body.hired_ids.push(item.id)
    // })

    // console.log(body)

    try {
      Toast.warning('Gerando CSV da análise de documentações');
      const response = await DocumentManagementService.downloadCSVFile(body);
      Toast.hide();

      let filename = `análise de documentações-ssma-${dateToCSVfilename(new Date)}.csv`
      await saveFile(response, filename);
      Toast.success('CSV gerado com sucesso');
    } catch (error) {
      console.error(error);
      let errorData = error?.response?.data;
      if (errorData?.message) Toast.error(errorData.message);
    }
  }

  const goToDetail = (id) => {
    history.push(`/ssma/analise-documentacao/${id}`)
  }

  useEffect(() => {
    getTableItems()
  }, [currentPage, filterBody, searchText, period])

  useEffect(() => {
    setFilter(true);
    setChanges(() => (value) => handdleFilterChange(value));
    getFilterItems();
    setPeriod(true);
    return () => {
      setPeriod(false);
      setFilter(false);
      setChanges(() => () => { });
      setFilterItems([]);
    }
  }, [])


  useEffect(() => {
    if (!shouldUpdateData) return
    refreshTable()
  }, [shouldUpdateData])

  return (
    <section className='ssma-documentation-page'>
      <div className='page-header'>
        <div className='left filters'>
          {/* {
            filterItems.toString() && (
              <TableFilter
                data={ filterItems }
                handleChange={ handdleFilterChange }
              />
            )
          } */}

        </div>
      </div>
      <div className='table-wrapper'>
        {
          tableData ? (
            <div>
              <FilterContent
                titleText={'ANÁLISE DE DOCUMENTAÇÕES DE ESCOPOS DE SERVIÇO'}
                searchFilter={searchArray}
                statusFilter={statusArray}
                onDataFromChild={getBody} // Passando a função como prop
                handleCSVButton={() => { handleCSVButton() }} // Passando a função de download do csv
                Dates={true}
              />
              <DocumentationTable
                data={tableData}
                currentPage={currentPage}
                pageSize={pageSize}
                handdleChangePage={handdleTableChangePage}
                shouldUpdateData={setShouldUpdateData}
                onItemClick={goToDetail}
              />
            </div>
          ) : (
            <div className='load-container'>
              <Loading />
            </div>
          )
        }
      </div>
    </section>
  );
}

export default SSMADocumentation
