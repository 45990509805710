import React, { useEffect, useState } from 'react';
import { format } from 'date-fns'; // import de tratamento de datas
import CalendarInput from '../../components/CalendarInput/index';
import { AiOutlineFilter, AiOutlineSearch } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import './styles.scss';

export const FILTERS_ENUM = {
  COMPANY_AND_EMPLOYEE_STATUS: 'COMPANY_AND_EMPLOYEE_STATUS',
  OTHERS: 'OTHERS',
};

// é um componente que cria filtros para pesquisar e selecionar dados com base em status, datas e termos específicos.
export default function FilterContent({ titleText, searchFilter, statusFilter, onDataFromChild, titleDates, handleCSVButton, Dates, othersCheckbox, filters = [] }) {

  const [initialDateErrorMessage, setInitialDateErrorMessage] = useState('');
  const [finalDateErrorMessage, setFinalDateErrorMessage] = useState('');
  const [arrivalDateErrorMessage, setArrivalDateErrorMessage] = useState('');
  const [visibleFilter, setVisibleFilter] = useState(false); // responsável por exibir o filtro da tabela
  const [selectedInitialDate, setSelectedInitialDate] = useState(null);
  const [selectedFinalDate, setSelectedFinalDate] = useState(null);
  const [selectedArrivalDate, setSelectedArrivalDate] = useState(null);
  const [checkboxes, setCheckboxes] = useState(statusFilter); // Mantém o estado dos checkboxes
  const [radioButtons, setRadioButtons] = useState(searchFilter); // Mantém o estado dos radio buttons
  const [otherCheckboxes, setOtherCheckboxes] = useState(othersCheckbox); // Mantém o estado dos checkboxes
  const [companyAndEmployeeStatusCheckboxes, setCompanyStatusCheckboxes] = useState();
  const [searchText, setSearchText] = useState(''); // Armazena o valor do input de texto
  const [hasDate, setHasDate] = useState(Dates);
  const filtersTypes = filters.map((x) => x.type);

  // função para lidar com a mudança do valor do input de texto e atualizar o estado
  const handleSearchTextChange = (event) => {
    const text = event.target.value;
    setSearchText(text);
  };

  // Atualiza o estado do checkbox específico
  const handleCheckboxChange = (index) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index].checked = !newCheckboxes[index].checked;
    setCheckboxes(newCheckboxes);
  };

   // Atualiza o estado do checkbox pelo tipo do filtro
   const handleCheckboxByType = (index, type) => {
    const checkbox = {};

    if (type === FILTERS_ENUM.OTHERS) {
      checkbox.data = otherCheckboxes;
      checkbox.handler = (c) => setOtherCheckboxes(c);
    } else if (type === FILTERS_ENUM.COMPANY_AND_EMPLOYEE_STATUS) {
      checkbox.data = companyAndEmployeeStatusCheckboxes;
      checkbox.handler = (c) => setCompanyStatusCheckboxes(c);
    }

    if (checkbox.data && checkbox.handler) {
      const newCheckboxes = [...(checkbox?.data || [])];
      newCheckboxes[index].checked = !newCheckboxes[index].checked;
      checkbox.handler(newCheckboxes);
    }
  };

  // Atualiza o estado do radio button específico
  const handleRadioButtonChange = (index) => {
    const newRadioButtons = radioButtons.map((item, idx) => ({
      ...item,
      checked: idx === index,
    }));
    setRadioButtons(newRadioButtons);
  };

  // responsável por filtra dados com base em datas, status e termos de pesquisa, e retorna os resultados filtrados.
  const handleSearch = () => {
    const selectedCheckboxes = checkboxes.filter((item) => item.checked);
    const selectedRadioButton = radioButtons.find((item) => item.checked);
    let status = []

    selectedCheckboxes.map((item) => {
      status.push(item.status)
    })

    // Verifique se ambas as datas estão preenchidas
    if (selectedInitialDate || selectedFinalDate) {
      if (!selectedInitialDate) {
        setInitialDateErrorMessage('Preencher as duas datas é obrigatório');
        return;
      }
      if (!selectedFinalDate) {
        setFinalDateErrorMessage('Preencher as duas datas é obrigatório');
        return;
      }
    }

    // criação do body de pesquisa
    const childData = {
      search: searchText,
      status,
      area: [],
      otherCheckboxes,
      companyAndEmployeeStatusCheckboxes: (companyAndEmployeeStatusCheckboxes || [])?.filter(x => x.checked),
      type_search: selectedRadioButton ? selectedRadioButton['label'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '',
    };

    if (selectedInitialDate && selectedFinalDate) {
      childData.provide_scope_initial_date = format(selectedInitialDate, 'yyyy-MM-dd')
      childData.provide_scope_end_date = format(selectedFinalDate, 'yyyy-MM-dd')
    }

    if (selectedArrivalDate) {
      childData.arrivalDate = format(selectedArrivalDate, 'dd-MM-yyyy')
    }


    onDataFromChild(childData);
  };

  // responsável por gerenciar a data inicial, validando e atualizando o estado conforme necessário.
  const handleInitialDateChange = (date) => {
    setSelectedInitialDate(date);
    if (date) {
      setInitialDateErrorMessage('');
    } else {
      setInitialDateErrorMessage('');
      setFinalDateErrorMessage('');
    }
  }

  // responsável por gerenciar a data final, validando e atualizando o estado conforme necessário.
  const handleFinalDateChange = (date) => {
    setSelectedFinalDate(date);
    if (date) {
      setFinalDateErrorMessage('');
    } else {
      setInitialDateErrorMessage('');
      setFinalDateErrorMessage('');
    }
  }

  // responsável por gerenciar a data de chegada da tela dos motoristas, validando e atualizando o estado conforme necessário.
  const handleArrivalDateChange = (date) => {
    setSelectedArrivalDate(date);
    if (date) {
      setArrivalDateErrorMessage('');
    } else {
      setArrivalDateErrorMessage('');
    }
  }

  useEffect(() => {
    filters.forEach((x) => {
      if (x?.type === FILTERS_ENUM.OTHERS) {
        setOtherCheckboxes(x.data);
      } else if (x?.type === FILTERS_ENUM.COMPANY_AND_EMPLOYEE_STATUS) {
        setCompanyStatusCheckboxes(x.data);
      }
    });
  }, [JSON.stringify(filters)]);

  // responsável por renderizar a interface do filtro com botões, checkboxes, inputs e calendários para pesquisa e seleção de datas.
  return (
    <div>
      <div className='header' style={{ backgroundColor: 'white' }}>
        <div className='title'>
          {titleText}
        </div>
        {handleCSVButton && (
          <div className='right' onClick={handleCSVButton} style={{ marginRight: '3em' }}>
            <p>
              <strong>Download da planilha</strong>
              <FiDownload
                size={22}
                className='icon'
              />
            </p>
          </div>
        )}
        <button className='filter-button' onClick={() => { visibleFilter ? setVisibleFilter(false) : setVisibleFilter(true) }}>
          <AiOutlineFilter size={'1rem'} className='icon' />
          Filtros
        </button>
      </div>
      {visibleFilter && (
        <div>
          <div className='filter-container'>
            <div className='filter-content row'>
              <div className="col-sm-12 col-md-5 col-lg-5">
                <label className='label-option'>BUSCAR ESCOPO POR:</label>
                <div className='radio-button-container'>
                  {radioButtons.map((item, index) => (
                    <div className='radio-horizontal' key={index}>
                      <input
                        type="radio"
                        name="status"
                        checked={item.checked}
                        onChange={() => handleRadioButtonChange(index)}
                      />
                      <label className='margin-options'>{item.label}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-sm-12 col-md-7 col-lg-7 margin-content my-col-sm my-col-md">
                <label className='label-option'>STATUS DO ESCOPO:</label>
                <div className='radio-button-container'>
                  {checkboxes.map((item, index) => (
                    <div className='radio-horizontal' key={index}>
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      <label className='margin-options'>{item.label}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {!hasDate ? null : (
              <label className='filter-content label-option'>{titleDates ? titleDates : "PERIODO DA ATIVIDADE:"}</label>
            )}
            <div className='filter-content row'>
              {!hasDate ? null : (
                <div className='col-sm-12 col-md-6 row padding-content'>
                  <div className="col-sm-12 col-md-6" style={{ paddingLeft: '1em' }}>
                    <div className='label'>
                      <label>DATA INICIAL</label>
                      <div className="date-content">
                        <CalendarInput
                          maxDate={selectedFinalDate}
                          className={`date-input${initialDateErrorMessage ? ' input-error' : ''}`}
                          initialValue={selectedInitialDate}
                          onChange={handleInitialDateChange}
                          showPopperArrow={false}
                          popperPlacement="bottom-start"
                          popperModifiers={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            zIndex: 9999
                          }}
                        />
                      </div>
                      {initialDateErrorMessage && <p style={{ color: "red" }}>{initialDateErrorMessage}</p>}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className='label' htmlFor="date-content">
                      <label >DATA FINAL</label>
                      <div className="date-content">
                        <CalendarInput
                          minDate={selectedInitialDate}
                          className={`date-input${finalDateErrorMessage ? ' input-error' : ''}`}
                          initialValue={selectedFinalDate}
                          onChange={handleFinalDateChange}
                          showPopperArrow={false}
                          popperPlacement="bottom-start"
                          popperModifiers={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                          }}
                        />
                      </div>
                      {finalDateErrorMessage && <p style={{ color: "red" }}>{finalDateErrorMessage}</p>}
                    </div>
                  </div>
                </div>
              )}

              <div className='col-sm-12 col-md-6 row padding-content'>
                <div className="col-sm-12 col-md-6" style={{ paddingLeft: '1em' }}>
                  <div className='label'>
                    <label>DATA DE CHEGADA</label>
                    <div className="date-content">
                      <CalendarInput
                        className={`date-input${arrivalDateErrorMessage ? ' input-error' : ''}`}
                        initialValue={selectedArrivalDate}
                        onChange={handleArrivalDateChange}
                        showPopperArrow={false}
                        popperPlacement="bottom-start"
                        popperModifiers={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          zIndex: 9999
                        }}
                      />
                    </div>
                    {arrivalDateErrorMessage && <p style={{ color: "red" }}>{arrivalDateErrorMessage}</p>}
                  </div>
                </div>
              </div>
              {filtersTypes?.some((x) =>
                [
                  FILTERS_ENUM.COMPANY_AND_EMPLOYEE_STATUS,
                  FILTERS_ENUM.OTHERS,
                ].includes(x)
              ) && (
                <div className='col-sm-12 col-md-6 col-lg-6'>
                  <div className='row'>
                    {filtersTypes?.includes(
                      FILTERS_ENUM.COMPANY_AND_EMPLOYEE_STATUS
                    ) && (
                      <div className=' col-6 py-0'>
                        <label className='label-option'>
                          Status empresa/colaborador:
                        </label>
                        <div className='radio-button-container'>
                          {companyAndEmployeeStatusCheckboxes.map(
                            (item, index) => (
                              <div className='radio-horizontal' key={index}>
                                <input
                                  type='checkbox'
                                  checked={item.checked}
                                  onChange={() =>
                                    handleCheckboxByType(
                                      index,
                                      FILTERS_ENUM.COMPANY_AND_EMPLOYEE_STATUS
                                    )
                                  }
                                />
                                <label className='margin-options'>
                                  {item.label}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}

                    {filtersTypes?.includes(FILTERS_ENUM.OTHERS) && (
                      <div className='col-6'>
                        <label className='label-option'>OUTROS:</label>
                        <div className='radio-button-container'>
                          {otherCheckboxes.map((item, index) => (
                            <div className='radio-horizontal' key={index}>
                              <input
                                type='checkbox'
                                checked={item.checked}
                                onChange={() =>
                                  handleCheckboxByType(
                                    index,
                                    FILTERS_ENUM.OTHERS
                                  )
                                }
                              />
                              <label className='margin-options'>
                                {item.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className={hasDate ? 'col-sm-12 col-md-6 search-content my-col-sm my-col-md' : 'col-sm-12 col-md-6 search-content my-col-sm'}>
                <input
                  className='search-input'
                  type="text"
                  value={searchText}
                  onChange={handleSearchTextChange}
                  placeholder="Digite o texto da pesquisa aqui"
                />
                <button className='search-button' onClick={handleSearch}>
                  <AiOutlineSearch size={'1rem'} className='icon' />
                  PESQUISAR
                </button>
              </div>
            </div>
          </div>
        </div>)}
    </div>
  )
}