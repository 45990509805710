import React, { useState, useEffect, useContext } from 'react';

import Loading from '../Loading';
import SearchInput from '../SearchInput';
import Toast from '../Toast';
import { FiDownload } from 'react-icons/fi';
import { IoMdArrowDropright } from 'react-icons/io';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';
import FilterContext from '../SideNav/context';

import { handleNextPrevious, getPages } from '../../helpers/pagination/utils';
import DocumentManagementService from '../../services/DocumentManagementService';
import FilterContent, { FILTERS_ENUM } from '../../components/FilterContent'

import { dateToCSVfilename } from '../../helpers/forms/convert';
import { saveFile } from '../../utils/files';


import './styles.scss';

const isRowExpandable = (item) => {
  if (item?.documents && item.documents.length > 0) return true;
  if (item?.employees && item.employees.length > 0) return true;
  return false;
}

const getStatusColor = (status) => {
  const statusColors = {
    Inapta: '#D92550',
    Inapto: '#D92550',
    Apta: '#00B26B',
    Apto: '#00B26B',
    waiting: '#FABC34',
    according: '#00B26B',
    not_applicable: '#707070',
    not_according: '#D92550',
  };

  return statusColors[status];
};

const getStatusLabel = (status) => {
  const statusColors = {
    waiting: 'Aguardando análise',
    according: 'Conforme',
    not_applicable: 'Não aplicável',
    not_according: 'Não conforme',
  };

  return statusColors[status];
};

const SSMAReportsTableEmployeeRow = ({ employee }) => {
  const expandableRow = isRowExpandable(employee);
  const [open, setOpen] = useState(false);

  return (
    <>
      <tr
        className={`fade-in ${expandableRow ? 'clickable-row' : ''}`}
        onClick={() => { if (expandableRow) setOpen(!open) }}
      >
        <td></td>
        <td className='employee-row'>
          <IoMdArrowDropright className={`arrow-icon${open ? '-down' : ''}`} />
          {employee.name}
        </td>
        <td></td>
        <td></td>
        <td className='status'>
          <div
            style={{ backgroundColor: getStatusColor(employee.status) }}
            className='status-container'
          >
            {employee.status}
          </div>
        </td>
        <td></td>
      </tr>
      {
        open && employee.documents && employee.documents.map((document, index) => (
          <tr className='fade-in' key={`sssma-reports-table-row-employee-document-${index}`}>
            <td></td>
            <td></td>
            <td>
              {document.document_type_name}
            </td>
            <td>
              {document.expires_at}
            </td>
            <td></td>
            <td className='status'>
              <div
                style={{ backgroundColor: getStatusColor(document.status) }}
                className='status-container'
              >
                {getStatusLabel(document.status)}
              </div>
            </td>
          </tr>
        ))
      }
    </>
  )
}

const SSMAReportsTableCompanyRow = ({ company }) => {
  const expandableRow = isRowExpandable(company);
  const [open, setOpen] = useState(false);

  return (
    <>
      <tr
        className={`fade-in ${expandableRow ? 'clickable-row' : ''}`}
        onClick={() => { if (expandableRow) setOpen(!open) }}
      >
        <td>
          <IoMdArrowDropright className={`arrow-icon${open ? '-down' : ''}`} />
        </td>
        <td>
          {company.company}
        </td>
        <td>
          {company.type_documents}
        </td>
        <td></td>
        <td className='status'>
          <div
            style={{ backgroundColor: getStatusColor(company.status) }}
            className='status-container'
          >
            {company.status}
          </div>
        </td>
        <td></td>
      </tr>
      {
        open && company.documents && company.documents.map((companyDocument, index) => (
          <tr className='fade-in' key={`sssma-reports-table-row-company-document-${index}`}>
            <td></td>
            <td></td>
            <td>
              {companyDocument.name}
            </td>
            <td>
              {companyDocument.expires_at}
            </td>
            <td></td>
            <td className='status'>
              <div
                style={{ backgroundColor: getStatusColor(companyDocument.status) }}
                className='status-container'
              >
                {getStatusLabel(companyDocument.status)}
              </div>
            </td>
          </tr>
        ))
      }
      {
        open && company.employees && company.employees.map((employee, index) => (
          <SSMAReportsTableEmployeeRow
            key={`sssma-reports-table-row-employee-${index}`}
            employee={employee}
          />
        ))
      }
    </>
  )
}

const SSMAReportsTable = ({ tableData }) => {
  return (
    <table className='table table-striped table-borderless'>
      <thead>
        <tr>
          <th scope='col'></th>
          <th scope='col'>Empresa fornecedora</th>
          <th scope='col'>Documentações</th>
          <th scope='col'>Validade documentação</th>
          <th scope='col'>Status empresa/colaborador</th>
          <th scope='col'>Status documentação</th>
        </tr>
      </thead>
      <tbody>
        {
          tableData && tableData.map((company, index) => (
            <SSMAReportsTableCompanyRow
              key={`sssma-reports-table-row-${index}`}
              company={company}
            />
          ))
        }
      </tbody>
    </table>
  )
}

const SSMAReports = () => {
  const pageSize = 10;
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [filterBody, setFilterBody] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setFilter, setFilterItems, setChanges } = useContext(FilterContext)

  // Array responsável por definir as opções de filtro de status da tabela
  const statusArray = [
    { label: "Pendente com restrições", status: "pendent", checked: false },
    { label: "Em aberto", status: "open", checked: false },
    { label: "Aprovada", status: "approved", checked: false },
    { label: "Aguardando análise", status: "waiting", checked: false },
    { label: "Pendente Dados Fornecedor", status: "missing", checked: false },
  ];

  // Array responsável por guardar o status da empresa e do funcionário
  const companyAndEmployeeStatusData = [
    { label: "Apta", status: "apto", checked: false },
    { label: "Inapta", status: "inapto", checked: false },
  ];

  // Array responsável por definir as opções de escolha da pesquisa
  const searchArray = [
    { label: "ID do Escopo", checked: true },
    { label: "Nome da Empresa", checked: false },
    { label: "Nome do Colaborador", checked: false },
    { label: "Número do pedido", checked: false },
  ];

  const othersArray = [
    { label: "Escopo vinculado", status: "has_scope", checked: true },
    { label: "Ativo", status: "is_active", checked: false },
  ];

  const getTableData = async (newBody) => {
    let body = newBody !== undefined ? newBody : {
      ...filterBody,
      has_scope: true,
    };
    try {
      let result = await DocumentManagementService.getCompaniesAndEmployeesDocumentations(currentPage, pageSize, body);
      setData(result.data);
      setLoading(false);
    } catch (error) { }
  }

  useEffect(() => {
    setCurrentPage(1);
    getTableData();
  }, [searchText])

  const getBody = (childData) => {
    setLoading(true);
    let body = {
      scope_status: childData.status,
    };

    if (childData.type_search === 'id do escopo') {
      body['scope_id'] = childData.search;
    } else if (childData.type_search === 'nome da empresa') {
      body['company_name'] = childData.search;
    } else if (childData.type_search === 'nome do colaborador') {
      body['employee_name'] = childData.search;
    } else if (childData.type_search === 'numero do pedido') {
      body['order_number'] = childData.search;
    }

    body['has_scope'] = childData.otherCheckboxes[0].checked;
    body['is_active'] = childData.otherCheckboxes[1].checked;

    body['company_status'] = childData.companyAndEmployeeStatusCheckboxes?.map(x => x.status)
    body['employee_status'] = childData.companyAndEmployeeStatusCheckboxes?.map(x => x.status)

    if (body['document-company-status']) {
      body['document-company-status'] = body['document-company-status'].map((filter) => filter.split('_')[1]);
    }
    if (body['document-employee-status']) {
      body['document-employee-status'] = body['document-employee-status'].map((filter) => filter.split('_')[1]);
    }

    setCurrentPage(1)
    setFilterBody(body)
    getTableData(body);
  }

  const getFilterItems = () => {
    const items = [
      {
        title: 'Status da análise', id: 'document-analysis-status', options: [
          { id: 'according', name: 'Conforme' },
          { id: 'not_according', name: 'Não Conforme' },
          { id: 'waiting', name: 'Aguardando análise' },
          { id: 'not_applicable', name: 'Não aplicável' },
        ]
      },
      {
        title: 'Status empresa', id: 'document-company-status', options: [
          { id: 'company_able', name: 'Apta' },
          { id: 'company_unable', name: 'Inapta' }
        ]
      },
      {
        title: 'Status colaborador', id: 'document-employee-status', options: [
          { id: 'employee_able', name: 'Apto' },
          { id: 'employee_unable', name: 'Inapto' }
        ]
      }
    ];
    setFilterItems(items);
  }

  const handleFilterChange = (filters) => {
    setFilterBody(filters);
    setCurrentPage(1);
  }
  useEffect(() => {
    setFilter(true);
    setChanges(() => (value) => handleFilterChange(value));
    getFilterItems();
    return () => {
      setFilter(false);
      setChanges(() => () => { });
      setFilterItems([]);
    }
  }, [])


  const handleCSVButton = async () => {
    try {
      let providerIds = { provider_ids: data.include };
      Toast.warning('Gerando CSV das empresas e colaboradores');
      const response = await DocumentManagementService.downloadReportsCSVFile(providerIds);
      Toast.hide();
      let filename = `relatorio-fornecedores-${dateToCSVfilename(new Date)}.csv`
      await saveFile(response, filename);
      Toast.success('CSV gerado com sucesso');
    } catch (error) {
      console.error(error.response);
      let errorData = error?.response?.data;
      if (errorData?.message) Toast.error(errorData.message);
    }
  }

  useEffect(() => {
    getTableData();
  }, [currentPage])

  return (
    <section className='ssma-reports-table-page'>
      <div className='table-wrapper'>
        {
          data ? (
            <section className='companies-and-employees-table fade-in'>
              {/* <div className='header'>
                <div className='title'>
                  EMPRESAS FORNECEDORAS E COLABORADORES TERCEIROS
                </div>
                <div className='right' onClick={handleCSVButton}>
                  <FiDownload
                    size={22}
                    className='icon'
                    title='Download do relatório em csv'
                  />
                  <p>Download da planilha</p>
                </div>
                <SearchInput
                  setValue={setSearchText}
                  placeholder={'Buscar por nome da empresa'}
                />
              </div> */}

              <FilterContent
                titleText={'EMPRESAS FORNECEDORAS E COLABORADORES TERCEIROS'}
                searchFilter={searchArray}
                statusFilter={statusArray}
                filters={[
                  { type: FILTERS_ENUM.OTHERS, data: othersArray },
                  {
                    type: FILTERS_ENUM.COMPANY_AND_EMPLOYEE_STATUS,
                    data: companyAndEmployeeStatusData,
                  },
                ]}
                othersCheckbox={othersArray}
                onDataFromChild={getBody} // Passando a função como prop
                handleCSVButton={() => { handleCSVButton() }} // Passando a função de download do csv
                Dates={false}
              />

              {!loading ? (
                <>
                  <div className='table-container'>
                    <SSMAReportsTable tableData={data.results} />
                  </div>

                  <div className='paginator-container'>
                    <nav>
                      <ul className='pagination'>
                        <li className={`page-item ${data.previous ? '' : 'disabled'}`}>
                          <a
                            className='page-link'
                            onClick={() => {
                              setLoading(true)
                              handleNextPrevious(data.previous, setCurrentPage)
                            }}
                          >
                            <div className='item-right'>
                              <TiChevronLeft />
                              <p>Anterior</p>
                            </div>
                          </a>
                        </li>
                        {
                          getPages(data.count, pageSize) && getPages(data.count, pageSize).map(page => (
                            <li
                              key={page}
                              onClick={() => {
                                setLoading(true)
                                setCurrentPage(page)
                              }}
                              className={`page-item ${page == currentPage ? 'active' : ''} `}>
                              <a className='page-link'>{page}</a>
                            </li>
                          ))
                        }
                        <li className={`page-item ${data.next ? '' : 'disabled'}`}>
                          <a
                            className='page-link'
                            onClick={() => {
                              setLoading(true)
                              handleNextPrevious(data.next, setCurrentPage)
                            }}
                          >
                            <div className='item-right'>
                              <p>Próximo </p>
                              <TiChevronRight />
                            </div>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </>
              ) : (
                <div className='load-container'>
                  <Loading />
                </div>
              )}

            </section>
          ) : (
            <div className='load-container'>
              <Loading />
            </div>
          )
        }
      </div>
    </section>
  )
}

export default SSMAReports;
