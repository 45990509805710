import React from 'react';
import './styles.scss';

export default function ModalWarning({isOpen, textModal, handleYesClick,  handleNoClick}) {
// isOpen: é um valor booleano que indica se o modal deve ser exibido ou não.
// textModal: é uma string que contém o texto a ser exibido dentro do modal.
// handleYesClick e handleNoClick: são funções que serão chamadas quando o usuário clicar nos botões "Sim" ou "Não", respectivamente.

  if (isOpen){ 
    // se true o componente retorna uma estrutura HTML a ser renderizada.
    return (
      <div className="modal">
        <div className="modal-content">
            <div className="modal-text">{textModal}</div>
            <div className="modal-buttons">
              <button onClick={handleYesClick}>Sim</button>
              <button onClick={handleNoClick}>Não</button>
            </div>
        </div>
      </div>
    )
  }
  //se falso, o componente retorna null, o que significa que nada será renderizado na tela.
  return null
}