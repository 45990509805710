import React, { useState, useEffect } from 'react';

import ConfirmationModal from '../ConfirmationModal';
import Loading from '../Loading';
import Modal from '../Modal';
import SearchInput from '../SearchInput';
import Switch from 'react-switch';
import Toast from '../Toast';
import { IoMdArrowDropright } from 'react-icons/io';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';

import { handleNextPrevious, getPages } from '../../helpers/pagination/utils';
import DocumentManagementService from '../../services/DocumentManagementService';

import './styles.scss';

const CompanyAccessRow = ({ company }) => {
  const [open, setOpen] = useState(false);
  const [companyObj, setCompanyObj] = useState({ ...company });
  const [password, setPassword] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalButtons, setModalButtons] = useState([]);

  const getBody = (employeeId = null) => {
    let employees_ids;
    let access_permitted;

    if (employeeId) {
      employees_ids = [employeeId];
      let employee = companyObj.employees.find(elem => elem.id == employeeId);
      access_permitted = !employee.access_permitted;
    } else {
      employees_ids = companyObj.employees.map(elem => elem.id);
      access_permitted = !companyObj.access_permitted;
    }

    return {
      company_id: companyObj.id,
      employees_ids: employees_ids,
      access_permitted: access_permitted
    }
  }

  const updateAccesses = async (employeeId) => {
    let body = getBody(employeeId);
    try {
      await DocumentManagementService.updateCompaniesAndEmployeesAccesses(body);
      const { data } = await DocumentManagementService.getCompanyAndEmployeesAccesses(companyObj.id);
      setCompanyObj(data);
    } catch (error) {
      Toast.error('Atualização de acesso não confirmada. Por favor, recarregue a página.')
    }
    setShowModal(false);
  }

  const handleToggleButton = (employeeId = null) => {
    let body = getBody(employeeId);

    if (body.access_permitted == false) {
      let message = `Tem certeza que deseja bloquear ${companyObj.name} e todos seus colaboradores?`
      if (employeeId) {
        let employee = companyObj.employees.find(elem => elem.id == employeeId);
        message = `Tem certeza que deseja bloquear o colaborador ${employee.name}?`
      }
      showBlockConfirmationModal(message, employeeId);
    } else {
      updateAccesses(employeeId);
    }
  }

  const generateNewPassword = async () => {
    let body = { company_id: companyObj.id }
    try {
      const { data } = await DocumentManagementService.getNewPassword(body);
      setPassword(data.data);
      Toast.success('Senha gerada com sucesso!')
    } catch (error) {
      Toast.error(error.response.data.message)
    }
    setShowModal(false);
  }

  const showBlockConfirmationModal = (message, employeeId) => {
    setModalTitle('Bloqueio de acesso');
    setModalBody(message);
    setModalButtons(
      [
        {
          className: 'cancel',
          label: 'Cancelar',
          onClick: () => setShowModal(false)
        },
        {
          className: 'confirm',
          label: 'Confirmar',
          onClick: () => updateAccesses(employeeId)
        }
      ]
    );
    setShowModal(true);
  }

  const showNewPasswordConfirmationModal = () => {
    setModalTitle('Geração de senha');
    setModalBody(`Tem certeza que deseja gerar uma nova senha para o fornecedor ${companyObj.name}?`);
    setModalButtons(
      [
        {
          className: 'cancel',
          label: 'Cancelar',
          onClick: () => setShowModal(false)
        },
        {
          className: 'confirm',
          label: 'Confirmar',
          onClick: () => generateNewPassword()
        }
      ]
    );
    setShowModal(true);
  }

  return (
    <>
      <tr>
        <td
          className='align-left icon-col'
          onClick={() => setOpen(!open)}
        >
          {
            companyObj.employees.length > 0 ? (
              <IoMdArrowDropright className={`arrow-icon${open ? '-down' : ''}`} />
            ) : <></>
          }
        </td>
        <td
          className='align-left company-name-col'
          onClick={() => setOpen(!open)}
        >
          {companyObj.name}
        </td>
        <td className='switch-col'>
          <Switch
            checked={companyObj.access_permitted}
            disabled={companyObj.employees.length > 0 ? false : true}
            onChange={() => handleToggleButton()}
            onColor='#000'
            onHandleColor='#f0f0f0'
            checkedIcon={null}
            uncheckedIcon={null}
            handleDiameter={18}
            height={24}
            width={36}
          />
          <span>
            {companyObj.access_permitted ? 'Sim' : 'Não'}
          </span>
        </td>
        <td className='password-col'>
          <input
            className={`btn ${password ? 'password-generated' : ''}`}
            onClick={(event) => {
              if (!password) showNewPasswordConfirmationModal();
              else {
                event.target.select()
                document.execCommand('copy');
                Toast.success('Senha copiada!');
              }
            }}
            value={password ? password : 'Gerar nova senha'}
          />
        </td>
      </tr>
      {
        open && companyObj.employees && companyObj.employees.map((employee, index) => (
          <tr className='fade-in' key={`employee-access-table-row-${index}`}>
            <td className='icon-col'></td>
            <td className='align-left employee-name-col'>
              {employee.name}
            </td>
            <td className='switch-col'>
              <Switch
                checked={employee.access_permitted}
                onChange={() => handleToggleButton(employee.id)}
                onColor='#000'
                onHandleColor='#f0f0f0'
                checkedIcon={null}
                uncheckedIcon={null}
                handleDiameter={18}
                height={24}
                width={36}
              />
              <span>
                {employee.access_permitted ? 'Sim' : 'Não'}
              </span>
            </td>
            <td className='password-col'></td>
          </tr>
        ))
      }
      <Modal
        active={showModal}
        disableFunction={setShowModal}
        disableWhenClickAway={true}
        fixed={true}
      >
        <ConfirmationModal title={modalTitle} body={modalBody} buttons={modalButtons} />
      </Modal>
    </>
  )
}

const ProvidersAndEmployeesAccesses = () => {
  var mounted = false;
  const pageSize = 10;
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');

  const getTableData = async () => {
    let body = { search: searchText }
    try {
      let result = await DocumentManagementService.getCompaniesAndEmployeesAccesses(currentPage, pageSize, body);
      if (mounted) setTableData(result.data);
    } catch (error) { }
  }

  useEffect(() => {
    mounted = true;
    getTableData();
    return () => mounted = false
  }, [currentPage, searchText])

  return (
    <section className='provider-hired-scopes-providers-table'>
      <div className='table-wrapper'>
        {
          tableData ? (
            <section className='companies-and-employees-accesses-table fade-in'>
              <div className='header'>
                <SearchInput
                  setValue={setSearchText}
                  placeholder={'Buscar por nome da empresa'}
                />
              </div>
              <div className='table-container'>
                <table className='table table-striped table-borderless'>
                  <thead>
                    <tr>
                      <th scope='col' className='align-left icon-col'></th>
                      <th scope='col' className='align-left'>Empresa fornecedora e colaboradores</th>
                      <th scope='col'>Acesso Vivix liberado</th>
                      <th scope='col'>Senha do fornecedor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableData.results && tableData.results.map((company, index) => (
                        <CompanyAccessRow
                          key={`company-access-table-row-${index}-${company.id}`}
                          company={company}
                        />
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <div className='paginator-container'>
                <nav>
                  <ul className='pagination'>
                    <li className={`page-item ${tableData.previous ? '' : 'disabled'}`}>
                      <a
                        className='page-link'
                        onClick={() => handleNextPrevious(tableData.previous, setCurrentPage)}
                      >
                        <div className='item-right'>
                          <TiChevronLeft />
                          <p>Anterior</p>
                        </div>
                      </a>
                    </li>
                    {
                      getPages(tableData.count, pageSize) && getPages(tableData.count, pageSize).map(page => (
                        <li
                          key={page}
                          onClick={() => setCurrentPage(page)}
                          className={`page-item ${page == currentPage ? 'active' : ''} `}>
                          <a className='page-link'>{page}</a>
                        </li>
                      ))
                    }
                    <li className={`page-item ${tableData.next ? '' : 'disabled'}`}>
                      <a
                        className='page-link'
                        onClick={() => handleNextPrevious(tableData.next, setCurrentPage)}
                      >
                        <div className='item-right'>
                          <p>Próximo </p>
                          <TiChevronRight />
                        </div>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </section>
          ) : (
            <div className='load-container'>
              <Loading />
            </div>
          )
        }
      </div>
    </section>
  );
}

export default ProvidersAndEmployeesAccesses;
