import React, { useState, useEffect } from 'react';

import './styles.scss';

import VisitsTable from '../../components/VisitsTable';

const VisitHistory = () => {

  return (
    <VisitsTable
      hasLink={true}
      pageSize={ 10 }
      showSearchBar={true}
    />
  );
}

export default VisitHistory;
