import React, { useEffect, useState } from 'react';

import './styles.scss';

import { UserService } from '../services'

import { BrowserRouter, Switch, useLocation } from 'react-router-dom';

import queryString from 'querystring';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import SideNav from '../components/SideNav';
import { Provider } from '../components/SideNav/context';
import Header from '../components/Header';

import AuthContext, {
  userInfoIsUpdated,
  getToken, getName, getCompany, getRoles,
  isFaturista, isAdmin, isEmployee,
  isProvider, isOnlyProvider,
  isSSMA,
} from '../utils/auth';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Toast from '../components/Toast';

import {
  Login,
  Register,
  PreRegistration,
  VisitHistory,
  Driver,
  NewRegister,
  NewEscopoDeServico,
  ServiceScopeTable,
  SSMAPage,
  RobotUpload,
  ScopeCollaborators,
  FirstAccess,
  ProviderDocuments,
  ProviderHiredScopesTable,
  RegisterOccurrence,
  SSMADocumentation,
  HiredScopes,
  SSMAValidateDocuments,
  Reports,
  HiredScopeDetail
} from '../pages';


let authInterval = null

const RedirectLogin = () => {
  const token = localStorage.getItem('TOKEN')

  if (!token) {
    // window.location.href = "/login"
    window.location.href = "/login/vivix/colaboradores/"
  }
  return null;
}

const DjangoAdmin = () => {
  window.location.replace('/admin/')
  return null
}

const Routes = () => {
  const [user, setUser] = useState({
    isAuthenticated: getToken(),
    token: '',
    refreshToken: '',
    name: getName(),
    roles: getRoles(),
    company: getCompany(),
  })
  const [isExpanded, setIsExpanded] = useState(false);

  const sideNavWidth = isExpanded ? '256px' : '50px';
  const pagePaddingX = isExpanded ? '4px' : '4px';
  const pagePaddingBotton = isExpanded ? '4px' : '4px';
  const pagePaddingTop = isExpanded ? '4px' : '4px';

  const handleIsExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const getUserInfos = async () => {
    try {
      const token = getToken()
      const { data } = await UserService.getUserInfo();

      const newUserInfo = {
        isAuthenticated: true,
        token: token,
        name: data.name,
        roles: data.user.roles,
        company: data.company,
      }

      if (!userInfoIsUpdated(newUserInfo)) {
        if (data.name) localStorage.setItem('NAME', data.name)
        localStorage.setItem('COMPANY', data.company)
        localStorage.setItem('ROLES', JSON.stringify(data.user.roles))
        setUser(newUserInfo)
      }

      return data
    } catch (error) {
      const { response } = error;
      if (response?.status == 401) {
        Toast.error('Sessão expirada. Realize o Login novamente.')
        doLogOut();
      }
    }
  }

  const setUserInfos = async (token) => {
    localStorage.setItem('TOKEN', token)

    const infos = await getUserInfos();

    let path = '/colaborador/escopos-de-servico'
    if (isProvider(infos.user.roles)) {
      path = '/fornecedor/escopos-contratados'
      if (infos.is_first_access) path = '/fornecedor/primeiro-acesso'
    }

    if (isSSMA(infos.user.roles)) {
      path = '/ssma/documentacoes'
    }

    if (isAdmin(infos.user.roles)) path = '/colaborador/escopos-de-servico'

    return [true, path]
  }

  const doLogin = async (dataLogin) => {
    try {
      const { data } = await UserService.getToken(dataLogin);
      const token = data.access;

      return await setUserInfos(token)
    } catch (error) {
      Toast.error('Credenciais incorretas. Verifique o seu email e senha com o gestor do seu contrato.');
      return false
    }
  }

  const doLogOut = () => {
    try {
      let path = '/login'
      if (user && isOnlyProvider(user.roles)) path = '/login'

      clearInterval(authInterval)

      localStorage.removeItem('TOKEN')
      localStorage.removeItem('NAME')
      localStorage.removeItem('ROLES')
      localStorage.removeItem('COMPANY')

      setUser({
        ...user,
        isAuthenticated: false,
        token: '',
        name: '',
        roles: [],
        company: '',
      })
      window.location.href = path
    } catch (e) {
      console.error(e)
    }

    return null;
  }

  const checkUserAuth = async () => {
    const token = getToken()
    if (token) await getUserInfos()
  }

  const checkDeviceWidth = () => {
    if (window.innerWidth > 768) setIsExpanded(true)
  }

  useEffect(() => {
    checkDeviceWidth();
    checkUserAuth();

    // workaround para checar se o usuario está ativo através do header
    authInterval = setInterval(async () => {
      if (window.location.pathname != '/login' && !window.location.pathname.includes('/pre-cadastro/')) {
        await getUserInfos();
        if (!getToken()) doLogOut();
      }
    }, 10000)

    return () => clearInterval(authInterval)
  }, [])

  return (
    <Provider>
      <AuthContext.Provider value={user}>
        <AuthContext.Consumer>
          {user => (
            <BrowserRouter>
              <Switch>
                <PublicRoute restricted={true} path='/' component={() => <RedirectLogin />} exact />
                <PublicRoute restricted={true} path='/login' component={() => <Login doLogin={doLogin} setUserInfos={setUserInfos} />} exact />
                <PublicRoute restricted={true} path='/pre-cadastro/:url_token' component={Register} />
                <PublicRoute restricted={false} path='/logout' component={doLogOut} exact />
                <PrivateRoute path='/robot-upload/:type' component={RobotUpload} exact />
                <PrivateRoute path='/fornecedor/primeiro-acesso' component={FirstAccess} exact />
                {
                  user.isAuthenticated && (
                    <section className='internal-area'>
                      <SideNav
                        handleIsExpanded={handleIsExpanded}
                        sideNavWidth={sideNavWidth}
                        isExpanded={isExpanded}
                      />
                      <Header
                        sideNavWidth={sideNavWidth}
                        doLogOut={doLogOut}
                        isExpanded={isExpanded}
                      />
                      <div
                        style={{
                          paddingLeft: `calc(${sideNavWidth} + ${pagePaddingX})`,
                          paddingRight: `${pagePaddingX}`,
                          paddingBottom: `${pagePaddingBotton}`,
                          paddingTop: `${pagePaddingTop}`
                        }}
                        className='internal-area-wrapper'>
                        <div className='page'>
                          {
                            isFaturista(user.roles) && (
                              <>
                                <PrivateRoute path='/faturista/novo-cadastro' component={NewRegister} exact />
                                <PrivateRoute path='/faturista/pre-cadastros' component={PreRegistration} exact />
                                <PrivateRoute path='/faturista/historico-de-visitas' component={VisitHistory} exact />
                                <PrivateRoute path='/faturista/motorista/:id' component={Driver} exact />
                              </>
                            )
                          }
                          {
                            isEmployee(user.roles) && (
                              <>
                                <PrivateRoute path='/colaborador/novo-escopo-de-servico' component={NewEscopoDeServico} exact />
                                <PrivateRoute path='/colaborador/escopos-de-servico' component={ServiceScopeTable} exact />
                                <PrivateRoute path='/colaborador/escopos-de-servico/:id' component={NewEscopoDeServico} exact />
                                <PrivateRoute path='/colaborador/escopos-de-servico/clone/:id' component={NewEscopoDeServico} exact />
                                <PrivateRoute path='/colaborador/escopos-de-servico/:id/parecer-ssma' component={SSMAPage} exact />
                                <PrivateRoute path='/colaborador/escopos-contratados/todos/' component={HiredScopes} />
                                <PrivateRoute path='/colaborador/escopos-contratados/meus-escopos/' component={HiredScopes} />
                                <PrivateRoute path='/colaborador/escopo-contratado/:id' component={HiredScopeDetail} />
                                <PrivateRoute path='/colaborador/escopos-contratados/acessos-fornecedores/' component={HiredScopes} />
                                <PrivateRoute path='/colaborador/registrar-ocorrencia' component={RegisterOccurrence} exact />
                                <PrivateRoute path='/relatorios' component={Reports} exact />
                                <PrivateRoute path='/relatorios/motoristas' component={Reports} />
                              </>
                            )
                          }
                          {
                            isSSMA(user.roles) && (
                              <>
                                <PrivateRoute path='/ssma/documentacoes' component={SSMADocumentation} exact />
                                <PrivateRoute path='/ssma/analise-documentacao/:id' component={SSMAValidateDocuments} exact />
                              </>
                            )
                          }
                          {
                            isProvider(user.roles) && (
                              <>
                                <PrivateRoute path='/fornecedor/escopos-contratados' component={ProviderHiredScopesTable} exact />
                                <PrivateRoute path='/fornecedor/escopos-contratados/:id' component={ScopeCollaborators} exact />
                                <PrivateRoute path='/fornecedor/documentos' component={ProviderDocuments} exact />
                              </>
                            )
                          }
                          {
                            isAdmin(user.roles) && (
                              <>
                                <PrivateRoute path='/admin' component={DjangoAdmin} />
                              </>
                            )
                          }
                        </div>
                      </div>
                    </section>
                  )
                }
              </Switch>
              <ToastContainer />
            </BrowserRouter>
          )}
        </AuthContext.Consumer>
      </AuthContext.Provider>
    </Provider>
  );
}

export default Routes
