import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { ProviderService } from '../../../services';

import { dateToString, stringToDate } from '../../../helpers/forms/convert';

import CalendarInput from '../../../components/CalendarInput';
import Toast from '../../../components/Toast';

import './styles.scss';
import { BiBlock } from 'react-icons/bi';

const CollaboratorForm = ({ create, ssmaRequirements, scopeID, collaborator, onSuccess, collapse, turnOff }) => {
  const { register, handleSubmit, errors, control, setValue, getValues, setError, reset } = useForm();
  const [hasAdmissionDate, setHasAdmissionDate] = useState(false);
  const [hasIntegrateDate, setHasIntegrateDate] = useState(false);

  const setCheckboxValues = (items, condition) => {
    items.map(item => {
      setValue(item.requirement_type, condition)
    })
  }

  const handleSSMARequirementsRequire = () => {
    let checkboxes = ssmaRequirements.map(item => (
      getValues(item.type)
    ))

    return (checkboxes.every(e => e == false))
  }

  const populateFields = () => {
    if (!collaborator) return
    setValue('employee_cpf', collaborator.employee_provider.cpf)
    setValue('name', collaborator.employee_provider.name)
    setValue('phone', collaborator.employee_provider.phone)
    setValue('admission_date', stringToDate(collaborator.employee_provider.admission_date))
    setValue('office', collaborator.employee_provider.office)
    setValue('employee_type', collaborator.employee_provider.employee_type)
    setValue('suggestion_integration_date', stringToDate(collaborator.suggestion_integration_date))
    setCheckboxValues(collaborator.ssma_requirements, true)
  }

  const parsePaylod = (data) => {
    const ssma_requirements = ssmaRequirements.reduce((accumulator, item) => {
      if (getValues(item.type)) {
        accumulator.push(item.id)
      }
      return accumulator
    }, [])

    const payload = {
      employee_provider: {
        employee_cpf: data.employee_cpf,
        name: data.name,
        phone: data.phone,
        admission_date: dateToString(data.admission_date),
        office: data.office,
        employee_type: data.employee_type,
      },
      ssma_requirements_id: ssma_requirements,
      provider_scope_service_id: scopeID,
      suggestion_integration_date: dateToString(data.suggestion_integration_date),
    }
    return payload
  }

  const setMasks = () => {
    $('[name="employee_cpf"]').mask('000.000.000-00');
    $('[name="phone"]').mask('+ZZZZZZZZZZZZZZZZZZZ', {
      translation: {
        'Z': {
          pattern: /[0-9]|[()-\s]/, optional: true
        }
      }
    });
    $('[name="admission_date"]').mask('00/00/0000');
    $('[name="suggestion_integration_date"]').mask('00/00/0000');
  }

  const handleRequestErrors = (data) => {
    let errorsKeys = []
    const nonFieldErrors = data.non_field_errors || []
    const employeeProviderErrors = data.employee_provider

    if (Array.isArray(data)) {
      data.map(item => {
        if (item.includes('CPF')) nonFieldErrors.push(item)
      })
    }

    if (employeeProviderErrors) errorsKeys = errorsKeys.concat(Object.keys(employeeProviderErrors))

    if (nonFieldErrors) nonFieldErrors.map(msg => {
      Toast.error(msg)
      if (msg.includes('CPF')) errorsKeys.push('cpf_cadastrado')
    })

    errorsKeys.map(key => {
      if (key.includes('cpf_cadastrado')) {
        setError('employee_cpf', { type: 'validate', message: 'CPF já cadastrado.' });
      }
      else {
        setError(key, { type: 'validate', message: 'Digite um valor válido.' });
        Toast.error(employeeProviderErrors[key][0])
      }
    })
  }

  const handleResetForm = () => {
    reset();
    setValue('admission_date', null)
    setValue('suggestion_integration_date', null)
  }

  const addCollaborator = async (payload) => {
    try {
      const { data } = await ProviderService.addCollaborator(payload)
      Toast.success('Colaborador cadastrado com sucesso.')
      handleResetForm();
      onSuccess();
      collapse();

    } catch (error) {
      console.error(error)
      const { data, status } = await error.response
      if (status === 500) {
        Toast.error('Ocorreu um erro no servidor: 500')
      } else {
        handleRequestErrors(data);
      }
    }
  }

  

  const updateCollaborator = async (payload) => {
    try {
      const { data } = await ProviderService.updateCollaborator(payload, collaborator.id)
      Toast.success('Cadastro atualizado com sucesso.')
      onSuccess();
    } catch (error) {
      console.error(error)
      const { data, status } = await error.response
      if (status === 500) {
        Toast.error('Ocorreu um erro no servidor: 500')
      } else {
        handleRequestErrors(data);
      }
    }
  }

  const handleCancelForm = () => {
    if (create) {
      collapse();
      reset();
    }
    else {
      collapse();
      populateFields();
    }
  }

  const onSubmit = (data) => {
    const payload = parsePaylod(data);

    if (create) addCollaborator(payload);
    else updateCollaborator(payload);
  }

  const getMinDate = () => {
    let minDate = new Date();
    minDate.setHours(minDate.getHours() + 48)
    return minDate
  }

  useEffect(() => {
    setMasks();
    populateFields();
  }, [])

  return (
    <form
      className='collaborator-form-component'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='row'>
        <div className='column col-sm-12 col-lg-6'>
          <div className='input-container'>
            <label className='label' htmlFor='name'>NOME</label>
            <input
              initialValue={getValues('name')}
              className='input'
              placeholder='Nome'
              name='name'
              ref={register({ required: { value: true, message: 'Esse campo é obrigatório.' } })}
            />
            {
              errors.name && (
                <span className='error'>* {errors.name.message}</span>
              )
            }
          </div>
        </div>
        <div className='column col-sm-12 col-lg-6'>
          <div className='input-container'>
            <label className='label' htmlFor='employee_type'>TIPO DE FUNCIONÁRIO</label>
            <div className='radio-container'>
              <div className='item'>
                <input
                  className='input'
                  name='employee_type'
                  type='radio' value='own_employee'
                  ref={register({ required: { value: true, message: 'Esse campo é obrigatório.' } })}
                />
                <label className='label'>Terceiro</label>
              </div>
              <div className='item'>
                <input
                  className='input'
                  name='employee_type'
                  type='radio' value='third_employee'
                  ref={register({ required: { value: true, message: 'Esse campo é obrigatório.' } })}
                />
                <label className='label'>Quarterizado</label>
              </div>
            </div>
            {
              errors.employee_type && (
                <span className='error'>* {errors.employee_type.message}</span>
              )
            }
            {/* */}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='column col-sm-12 col-lg-6'>
          <div className='input-container'>
            <label className='label' htmlFor='employee_cpf'>CPF*</label>
            <input
              id='employee_cpf'
              className='input'
              placeholder='CPF'
              name='employee_cpf'
              ref={register({ required: { value: true, message: 'Esse campo é obrigatório.' } })}
            />
            {
              errors.employee_cpf && (
                <span className='error'>* {errors.employee_cpf.message}</span>
              )
            }
          </div>
        </div>
        <div className='column col-sm-12 col-lg-6'>
          <div className='input-container'>
            <label className='label' htmlFor='admission_date'>DATA DE ADMISSÃO</label>
            <div className='admission_date'>
              <Controller
                id='admission_date'
                name='admission_date'
                as={() => <CalendarInput
                  initialValue={getValues('admission_date')}
                  onChange={
                    date => {
                      setHasAdmissionDate(false);
                      setValue('admission_date', date)
                    }
                  }
                  maxDate={new Date}
                  showPopperArrow={false}
                  popperPlacement="bottom-start"
                  popperModifiers={
                    {
                      position: 'absolute',
                      top: 0,
                      right: 0,
                    }
                  }
                />}
                control={control}
                rules={{
                  validate: (value) => {
                    setHasAdmissionDate(true);
                  }
                }}
              />
              {
                errors.admission_date && (
                  <span className='error'>* {errors.admission_date.message}</span>
                )
              }
              {
                !getValues('admission_date') && hasAdmissionDate && (
                  <span className='error'>* {'Esse campo é obrigatório.'}</span>
                )
              }
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='column col-sm-12 col-lg-6'>
          <div className='input-container'>
            <label className='label' htmlFor='office'>FUNÇÃO</label>
            <input
              className='input'
              placeholder='Função'
              name='office'
              ref={register({ required: { value: true, message: 'Esse campo é obrigatório.' } })}
            />
            {
              errors.office && (
                <span className='error'>* {errors.office.message}</span>
              )
            }
          </div>
        </div>
        <div className='column col-sm-12 col-lg-6'>
          <div className='input-container'>
            <label className='label' htmlFor='phone'>TELEFONE</label>
            <input
              id='phone'
              className='input'
              placeholder='Telefone'
              name='phone'
              ref={register({ required: { value: true, message: 'Esse campo é obrigatório.' } })}
            />
            {
              errors.phone && (
                <span className='error'>* {errors.phone.message}</span>
              )
            }
          </div>
        </div>
      </div>

      {
        ssmaRequirements && ssmaRequirements.length > 0 ?
          <div className='row'>
            <div className='column col-sm-12'>
              <div className='input-container'>
                <label className='label' htmlFor='name'>ATIVIDADES</label>
                <div className='checkbox-container'>
                  {
                    ssmaRequirements && ssmaRequirements.map(item => (
                      <div className='item' key={item.id}>
                        <input
                          className='input'
                          type='checkbox'
                          placeholder={item.name}
                          name={item.type}
                          ref={register({ required: { value: handleSSMARequirementsRequire(), message: 'Esse campo é obrigatório.' } })}
                        />
                        <label className='label' htmlFor={item.type}>{item.name}</label>
                      </div>
                    ))
                  }
                </div>
                {
                  errors.altura && (
                    <span className='error'>* {errors.altura.message}</span>
                  )
                }
              </div>
            </div>
          </div>
          :
          null
      }

      <div className='row'>
        <div className='column col-sm-12 col-lg-6'>
          <div className='input-container'>
            <label className='label' htmlFor='suggestion_integration_date'>SUGESTÃO DE DATA PARA INTEGRAÇÃO</label>
            <Controller
              id='suggestion_integration_date'
              name='suggestion_integration_date'
              as={() => <CalendarInput
                minDate={getMinDate()}
                initialValue={getValues('suggestion_integration_date')}
                onChange={
                  date => {
                    setHasIntegrateDate(false);
                    setValue('suggestion_integration_date', date)
                  }
                }
              />}
              control={control}
              rules={{
                validate: (value) => {
                  setHasIntegrateDate(true);
                }
              }}
            />
            {
              errors.suggestion_integration_date && (
                <span className='error'>* {errors.suggestion_integration_date.message}</span>
              )
            }
            {
              !getValues('suggestion_integration_date') && hasIntegrateDate && (
                <span className='error'>* {'Esse campo é obrigatório.'}</span>
              )
            }

            <div className='info'>
              <AiOutlineInfoCircle className='icon' />
              <span className='text'>
                A integração acontece de segunda-feira a quinta-feira, das 08:00 às 09:30.
              </span>
            </div>
            <div className='info'>
              <AiOutlineInfoCircle className='icon' />
              <span className='text'>
                Necessário um mínimo de 48 horas para SSMA validar documentações.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='disable-collaborator'>
        {!create && (
          <button className='turn-off' type='button' onClick={() => turnOff()} >
            <span className='text'>Desligar colaborador</span>
          </button>
        )}
      </div>
      <div className='row'>
        <div className='column right col-sm-12'>
          <button type='button' className='btn cancel' onClick={handleCancelForm}>Cancelar</button>
          <button type='submit' className='btn save'>Salvar</button>
        </div>
      </div>

    </form>
  )
}

export default CollaboratorForm
