import React from 'react';

import './styles.scss';

const ProviderModal = ({ active, disableFunction, disableWhenClickAway, children, fixed }) => {
    const handleDisableWhenClickAway = () => {
        if (disableWhenClickAway) {
            disableFunction(false)
        }
    }

    return (
        <section
            className={`modal-component ${active ? 'active' : ''}`}
            style={{ position: `${fixed ? 'fixed' : 'absolute'}` }}
        >
            {
                active && (
                    <>
                        <div
                            className='modal-blur fade-in'
                            onClick={handleDisableWhenClickAway}
                        ></div>
                        <div className='child'>
                            {
                                children
                            }
                        </div>
                    </>
                )
            }
        </section>
    );
}

export default ProviderModal;
