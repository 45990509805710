import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import { format, isValid } from 'date-fns'

import { FaCalendar, FaClock } from 'react-icons/fa'

import { isTimeValid } from '../../helpers/forms/isValid'
import { DocumentManagementService } from '../../services'

import Toast from '../../components/Toast'
import CalendarInput from '../../components/CalendarInput'

import './style.scss'


const RegisterOccurrence = ({ history }) => {
  const [companies, setCompanies] = useState([])
  const [company, setCompany] = useState(null)

  const [companyEmployees, setCompanyEmployess] = useState([])
  const [companyEmployee, setCompanyEmployee] = useState(null)

  const [allEmails, setAllEmails] = useState([])
  const [emails, setEmails] = useState(null)

  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState('')
  const [desc, setDesc] = useState('')

  const [errors, setErrors] = useState({})

  useEffect(() => {
    getCompanies()
    getEmails()
  }, [])

  useEffect(() => {
    if (company?.id) getCompanyEmployees(company.id)
    else setCompanyEmployess([])
  }, [company])

  const getEmails = async () => {
    try {
      const { data } = await DocumentManagementService.getEmailsVivix()
      setAllEmails(data)
    } catch(error) {
      console.log('ERROR GET EMAILS', error)
    }
  }

  const getCompanies = async () => {
    try {
      setCompanyEmployee(null)
      const { data } = await DocumentManagementService.getCompanies()
      setCompanies(data)
    } catch(error) {
      console.log('ERROR GET COMPANIES', error)
    }
  }

  const getCompanyEmployees = async (companyID) => {
    try {
      const { data } = await DocumentManagementService.getCompanyEmployees(companyID)
      setCompanyEmployess(data)
    } catch(error) {
      console.log('ERROR GET COMPANIES EMPLOYEES', error)
    }
  }

  const handleRegister = () => {
    const newErrors = {}

    if (!company?.id) newErrors.company = 'Informe a empresa.'
    if (!companyEmployee?.id) newErrors.companyEmployee = 'Informe o colaborador.'
    if (!isValid(date)) newErrors.date = 'Informe uma data válida.'
    if (!isTimeValid(time)) newErrors.time = 'Informe um horário válido.'
    if (!desc) newErrors.desc = 'Informe a descrição da ocorrência.'
    if (!emails?.length) newErrors.emails = 'Informe os e-mails que devem ficar cientes dessa ocorrência.'

    if (!Object.keys(newErrors).length) submitOccurrence()
    else setErrors(newErrors)
  }

  const submitOccurrence = async () => {
    try {
      const body = {
        company_provider: company.id, 
        employee_provider: companyEmployee.id, 
        occurrence_datetime: `${format(date, 'yyyy-MM-dd')}T${time}`,
        description: desc,
        emails: emails.map(e => e.email)
      }
  
      await DocumentManagementService.postOccurrence(body)

      Toast.success('Ocorrência registrada com sucesso!')
      history.push('/ssma/documentacoes')
    } catch(error) {
      console.log('ERROR SUBMIT OCCURRENCE', error)
    }
  }

  return (
    <div id="register-occurrence-page">
      <div className="header">REGISTRAR OCORRÊNCIA</div>
      <div className="body">
        <div className="flex-row-fields">
          <div>
            <label>EMPRESA*</label>
            <Select
              placeholder="Empresa fornecedora"
              options={companies}
              value={company}
              onChange={setCompany}
              getOptionLabel={opt => opt.company}
              getOptionValue={opt => opt.id}
              isClearable
            />
            {
              errors.company && (<span className="error-field">{errors.company}</span>)
            }
          </div>
          <div>
            <label>COLABORADOR*</label>
            <Select
              placeholder="Colaborador"
              options={companyEmployees}
              value={companyEmployee}
              onChange={setCompanyEmployee}
              getOptionLabel={opt => opt.name}
              getOptionValue={opt => opt.id}
              isDisabled={!company}
              isClearable
            />
            {
              errors.companyEmployee && (
                <span className="error-field">{errors.companyEmployee}</span>
              )
            }
          </div>
        </div>
        <div className="flex-row-fields">
          <div>
            <label>DATA DA OCORRÊNCIA*</label>
            <div className="field-container">
              <CalendarInput
                className="date-input no-border"
                placeholder="00/00/0000 00:00"
                initialValue={date}
                maxDate={new Date()}
                onChange={setDate}
                showPopperArrow={false}
                popperPlacement="bottom-start"
                popperModifiers={
                  {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }
                }
              />
              <FaCalendar className='icon icon-opacity' />
            </div>
            {
              errors.date && (<span className="error-field">{errors.date}</span>)
            }
          </div>
          <div>
            <label>HORÁRIO DA OCORRÊNCIA*</label>
            <div className="field-container half-w">
              <FaClock className='icon icon-opacity' />
              <input
                type="time"
                className="no-border"
                placeholder="Empresa fornecedora"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
            {
              errors.time && (<span className="error-field">{errors.time}</span>)
            }
          </div>
        </div>
        <div className="flex-row-fields">
          <div>
            <label>OCORRÊNCIA*</label>
            <textarea
              placeholder="Descreva a ocorrência"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>
            {
              errors.desc && (<span className="error-field">{errors.desc}</span>)
            }
          </div>
        </div>
        <div className="flex-row-fields">
          <div>
            <label>E-MAIL PARA NOTIFICAÇÃO DO REGISTRO DE OCORRÊNCIA*</label>
            <Select
              placeholder="E-mails"
              options={allEmails}
              value={emails}
              onChange={setEmails}
              getOptionLabel={opt => opt.email}
              getOptionValue={opt => opt.email}
              isClearable
              isMulti
            />
            {
              errors.emails && (<span className="error-field">{errors.emails}</span>)
            }
          </div>
          <div></div>
        </div>
      </div>
      <div className="footer">
        <button>Cancelar</button>
        <button
          className="submit"
          onClick={handleRegister}
        >
          Registrar ocorrência
        </button>
      </div>
    </div>
  )
}

export default RegisterOccurrence
