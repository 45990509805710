import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Toast from '../../components/Toast';

import { ServiceScopeService } from '../../services';

import './styles.scss';
import { vivixLogoImage } from '../../constants';

const SSMAPage = ({ match }) => {
  const [requirements, setRequirements] = useState({});
  const history = useHistory();
  const headerText = "Caro Prestador,\nPara acesso a Vivix Vidros Planos e Suas Unidades, é necessário que todos os colaboradores realizem a integração de segurança que ocorrem de Segunda à quinta na unidade fabril Goiana, pontualmente às 08:00h. Ressaltamos que a referida integração será realizada apenas após a inclusão de 100% das documentações abaixo relacionadas.";

  const getRequirements = async () => {
    try {
      const { data } = await ServiceScopeService.getRequirementsDocs(match.params.id);
      setRequirements(data);
    } catch (err) {
      const { response: { status, data } } = err;
      if (status == 404) {
        history.push('/colaborador/escopos-de-servico');
        Toast.error('Escopo de serviço não encontrado');
        return;
      }
      Toast.error('Não é possível obter documentação para este serviço');
    }
  }

  useEffect(() => {
    getRequirements();
  }, []);

  const handlePDFButton = async (e) => {
    try {
      const { data } = await ServiceScopeService.getScopeServicePDF(match.params.id);
      const file = new Blob([data], { type: 'application/pdf' });
      const url = URL.createObjectURL(file);
      window.open(url);
    } catch (error) {
      const { response: { data } } = error;
      const blob = new Blob([data], { type: 'application/json' });
      const dataText = await blob.text()
      const dataJson = JSON.parse(dataText)
      Toast.error(`${dataJson.message}`);
    }
  }

  const renderDocuments = (obj) => {
    if (!obj?.required) {
      return (
        <p className="bold-text">Este serviço não possui documentação para integração</p>
      )
    }
    const { required, ssma_requirements, note_requirement } = obj;
    return (
      <div>
        <div>
          <label className="bold-text">Nº 01 - {required.name}</label>
          <ul>
            {
              required.documents && required.documents.map(document => {
                if (document.description.includes('apresentados ao setor de SSMA')) {
                  let text = document.description.split('.')
                  return (<li key={document.id}>{text[0]}.<b>{text[1]}</b>;</li>);
                }
                return (<li key={document.id}>{document.description};</li>);
              })
            }
          </ul>
        </div>
        {
          ssma_requirements && ssma_requirements.map((item, index) => {
            return (
              <div key={item.id}>
                {/* o contador começa da posição 2 */}
                <label className="bold-text">
                  Nº 0{index + 2} - {item.name}
                </label>
                <ul>
                  {
                    item.documents && item.documents.map(document => {
                      return (<li key={document.id}>{document.description};</li>);
                    })
                  }
                </ul>
                {
                  item.requirement_type == 'ferramenta_manual' &&
                  <>
                    <label className="bold-text">Nota:</label>
                    &nbsp;Equipamentos elétricos com dupla isolação não necessitam de pino terra.
                  </>
                }
              </div>
            )
          })
        }
        <div>
          <div>
            <label className='observation-text'>OBS.: EM CASO DE GERAÇÃO DE RESÍDUOS O DESCARTE É DE RESPONSABILIDADE DO CONTRATADO.</label>
          </div>
          <label className="bold-text">LISTA DE DOCUMENTAÇÃO PARA DESTINAÇÃO DE RESÍDUOS/LOCAÇÃO DE BANHEIRO QUÍMICO</label>
          <ul>
            {
              note_requirement.documents && note_requirement.documents.map(document => {
                return (<li key={document.id} className='bold-text'>{document.description};</li>);
              })
            }
          </ul>
        </div>
      </div>
    )
  }

  return (
    <section className='container-parecer-ssma'>
      <div className='escopo-de-servico-container'>
        <div className='header'>
          <div className='title'>
            PARECER SSMA - MATRIZ DE CATEGORIZAÇÃO E RELAÇÃO DE DOCUMENTOS
          </div>
          <div className='image-container'>
            <img src={vivixLogoImage} />
          </div>
        </div>
        <div className='body'>
          {headerText.split('\n').map((text, index) => <label key={index}>{text}</label>)}
          <label className='bold-text'>
            Documentações para integração
          </label>
          {/* renderiza documentações */}
          {requirements && renderDocuments(requirements)}
          {/* <div className='signature-div'>
            <div className='signature-box' style={{borderRight: 'none'}}>
              <h6 className="title-signature">ASSINATURA SOLICITANTE</h6>
            </div>
            <div className='signature-box'>
              <h6 className="title-signature">ASSINATURA DO DEPARTAMENTO</h6>
            </div>
            <div className='signature-box' style={{borderLeft: 'none'}}>
              <h6 className="title-signature">ASSINATURA SSMA</h6>
            </div>
          </div> */}
        </div>
        <hr />
        <div className="button-footer-container">
          <Link to={'/colaborador/escopos-de-servico'}>
            <button className="button-back">Voltar ao início</button>
          </Link>
          <button className="button-pdf" onClick={handlePDFButton}>Gerar PDF</button>
        </div>
      </div>
    </section>
  );
}


export default SSMAPage;