import React, { useState, useEffect } from 'react';

import './styles.scss';
import ProviderScopeSummary from '../../components/ProviderScopeSummary';
import Collaborators from './Collaborators';
import Documentations from './Documentations';
import { ProviderService } from '../../services';

const ScopeCollaborators = ({ match }) => {

  const [activeMenu, setActiveMenu] = useState('collaborators')
  const [collaborators, setCollaborators] = useState([])
  const [hiredScopeData, setHiredScopeData] = useState({})

  const handleActiveMenu = (menuName) => {
    setActiveMenu(menuName)
  }

  const getCollaborators = async () => {
    try {
      const { data } = await ProviderService.listCollaborators(match.params.id)
      setCollaborators(data)
    } catch (error) {

    }
  }

  const getHiredScopeData = async () => {
    const { data } = await ProviderService.getHiredScopeData(match.params.id);
    setHiredScopeData(data)
  }

  const getData = async () => {
    await getHiredScopeData();
    await getCollaborators();
  }

  useEffect(() => {
    // usando async function para priorizar a renderização dos dados do escopo contratado
    // para entao renderizar os colaboradores
    async function getAsyncData() {
      await getData();
    }
    getAsyncData();
  }, [])

  return (
    <section className='scope-collaborators-page'>
      <div className='page-menu'>
        <input
          className={`input-menu ${activeMenu === 'collaborators' ? 'active' : ''}`}
          type='submit'
          value='Colaboradores'
          onClick={() => handleActiveMenu('collaborators')}
        />
        <input
          className={`input-menu ${activeMenu === 'documentation' ? 'active' : ''}`}
          type='submit'
          value='Documentação de escopo'
          onClick={() => handleActiveMenu('documentation')}
        />
      </div>
      <div className='page-content'>
        <ProviderScopeSummary data={hiredScopeData} />
        {
          activeMenu === 'collaborators' && (
            <Collaborators scopeID={match.params.id} collaborators={collaborators} getCollaborators={getCollaborators} hiredScopeData={hiredScopeData} />
          )
        }

        {
          activeMenu === 'documentation' && (
            <Documentations scopeId={hiredScopeData.id} />
          )
        }
      </div>
    </section>
  );
}

export default ScopeCollaborators
