import api from './api'

class CoreService {
    static getVehicleTypes() {
        return api.get('vehicle-types/');
    }

    static getVehicleInfo(data) {
      return api.post(
        'vehicles/info/',
        { ...data }
      );
  }

    static createPreRegisterUrl() {
        return api.post('pre-register-url/', null, {
          headers: {
            ...api.getAuthToken(),
          }
        });
    }

    static isUrlTokenValid(token) {
        return api.post('pre-register-url/token/', { token });
    }
}

export default CoreService;
