import React, { useContext } from 'react';

import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../utils';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const AuthUser = useContext(AuthContext)

  return (
    <Route { ...rest } render={ props => (
      AuthUser.isAuthenticated
        ? <Component {...props} />
        : <Redirect to='/login' />
      )}
    />
  );
};

export default PrivateRoute;
