import React, { useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'

import './styles.scss'

const Collapse = ({ title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div className="collpase-component">
      <div className="collapse-indicator" onClick={handleCollapse}>
        <FaAngleRight className={`icon ${isCollapsed ? 'icon-collpase' : 'icon-not-collapse'}`} />
      </div>
      <div className="collapse-container">
        <div className="collapse-title" onClick={handleCollapse}>{title}</div>
        <div className={`collapse-content ${isCollapsed ? '' : 'collapse-content-show'}`}>
          { children }
        </div>
      </div>
    </div>
  )
}

export default Collapse
