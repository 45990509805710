import React, { useState, useRef } from 'react';

import './styles.scss';

import { DocumentManagementService } from '../../services';
import Toast from '../Toast';

import { useForm } from 'react-hook-form';


const FireEmployeeModal = ({ employeeData, getCollaborators, setModal, scopeID }) => {
  const attachFiles = useRef(null);
  const [annulmentFile, setAnnulmentFile] = useState(null);
  const { register, handleSubmit, errors } = useForm();

  const fileDrop = (event) => {
    event.preventDefault();
    const dragFile = event.dataTransfer.files;
    handleFile(dragFile);
  }

  const handleFileChange = (event) => {
    const inputFile = event.target.files;
    handleFile(inputFile);
  }

  const handleFile = (addFile) => {
    if (addFile.length) {
      const file = addFile[0];
      file.id = `${(new Date()).getTime()}-${0}`
      file.link = URL.createObjectURL(file)
      file.file_name = file.name.replace(/\s/g, '_')
      setAnnulmentFile(file);
    }
  }

  const onSubmit = async (body) => {
    const formData = new FormData();
    let id = employeeData.employee_provider.id;

    formData.append('reason', body.reason)
    formData.append('justification', body.justification)
    formData.append('document_type', body.document_id)
    formData.append('employee_provider_id', id)
    formData.append('scope_id', scopeID)
    if (annulmentFile) formData.append('document', annulmentFile)

    try {
      const { data } = await DocumentManagementService.postDismissalEmployee(formData);
      Toast.success(data.message);
      getCollaborators()
      setModal(false)
    } catch (err) {
      const { response: { data } } = err;
      Object.keys(data).map(function (field) {
        Toast.error(data[field][0]);
      });
    }
  }

  let employee_provider = employeeData.employee_provider;

  return (
    <div className='fire-employee'>
      <div className='header'>
        Desligar colaborador
      </div>
      <div className='body'>
        <form id='hook-form' className='fire-employee-form' onSubmit={handleSubmit(onSubmit, employee_provider.id)} autoComplete="off">
          <div>
            <p className='sub-title-employee'>Colaborador #1</p>
            <div className='employee-info'>
              <div>
                <p>NOME: {employee_provider.name}</p>
                <p>TELEFONE: {employee_provider.phone}</p>
              </div>
              <div>
                <p>FUNÇÃO: {employee_provider.office}</p>
                <p>ATIVIDADES:
                  {employeeData.ssma_requirements.map(
                    requirement => <p className='ssma-requirements'>{requirement.name}</p>
                  )}
                </p>
              </div>
            </div>

            <label className='label'>MOTIVO*</label>
            <input
              id='reason'
              name='reason'
              className={`input reason`}
              placeholder='Informe o motivo do desligamento'
              type='text'
              ref={register({
                required: 'Esse campo é obrigatório.',
              })}
            />
            {
              errors.reason && (
                <span className='field-error'>
                  {errors.reason.message}
                </span>
              )
            }
          </div>
          <div>
            <label className='label'>JUSTIFICATIVA*</label>
            <textarea
              id='justification'
              name='justification'
              className={`input justification`}
              type='text'
              placeholder='Justifique o desligamento do colaborador'
              ref={register({
                required: 'Esse campo é obrigatório.',
              })}
            />
            {
              errors.justification && (
                <span className='field-error'>
                  {errors.justification.message}
                </span>
              )
            }
          </div>
          <div>
            <label className='label'>DOCUMENTO DE RESCISÃO*</label>
            <div className='upload-container'>
              <input
                type='file'
                ref={attachFiles}
                onChange={handleFileChange}
                hidden
              />
              <div
                className="drop-zone"
                onDragOver={(event) => event.preventDefault()}
                onDragEnter={(event) => event.preventDefault()}
                onDragLeave={(event) => event.preventDefault()}
                onDrop={fileDrop}
                onClick={() => attachFiles.current.click()}
              >
                <span>
                  {
                    annulmentFile
                      ?
                      annulmentFile.file_name
                      :
                      'Experimente soltar um documento aqui ou clique para carregar um arquivo'
                  }
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='footer'>
        <button
          onClick={() => setModal(false)}
          className='btn cancel'
        >
          Cancelar
        </button>
        <button
          type='submit'
          form='hook-form'
          className='btn ok'
        >
          OK
        </button>
      </div>
    </div>
  );
}

export default FireEmployeeModal;
