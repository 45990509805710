import React, { useState, useEffect, useRef } from 'react';

import './styles.scss';

import { useForm } from 'react-hook-form';
import DataListInput from 'react-datalist-input';
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min';
import ConfirmationBox from './ConfirmationBox';

import Modal from '../../components/Modal';
import Input from '../../components/Input';

import { CommonService, CoreService, UserService } from './../../services';

import LogoImage from '../../assets/images/logo.png';
import { useHistory } from 'react-router-dom';
import CookieAlert from '../../components/CookieAlert';
import { Cookies } from 'react-cookie-consent';
import { cookieName } from './../../constants/index';

import CalendarInput from '../../components/CalendarInput/index';

import { isCpfValid, isDateEqualGreaterThanToday, isCnhValid } from './../../helpers/forms/isValid';
import { stringToDate } from './../../helpers/forms/convert';


const Register = ({match}) => {
  const { register, handleSubmit, setError, clearErrors, setValue, errors } = useForm();
  const [driverType, setDriverType] = useState('cliente');
  const [visitationType, setVisitationType] = useState('carga');
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [containerNumber, setContainerNumber] = useState('');
  const [confirmationData, setConfirmationData] = useState({});
  const [modal, setModal] = useState(false);
  const [driverVehicles, setDriverVehicles] = useState([]);
  const [dataListVehicles, setDataListVehicles] = useState([]);
  const [driverVehicle, setDriverVehicle] = useState('');
  const [driverCPF, setDriverCPF] = useState('');
  const vehicleElement = useRef();
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [formVisibility, setFormVisibility] = useState(Cookies.get(cookieName));
  const [disableVehicleInfo, setDisableVehicleInfo] = useState(false);
  const [cnhExpiresAt, setCnhExpiresAt] = useState('');
  let history = useHistory();

  const handleDriverTypeChange = (event) => {
    // altera o tipo de motorista
    let driver_type = event.target.value;
    setDriverType(driver_type);
    // limpa o campo toda alteracao de tipo de motorista
    setValue('client_name', '');
    setValue('company_name', '');
  }

  const handleContainerNumber = (event) => {
    // altera o numero do container
    setContainerNumber(event.target.value);
  }

  const handleVisitationTypeChange = (event) => {
    // altera o tipo de visita do motorista (carga ou descarga)
    let visit_type = event.target.value;
    setVisitationType(visit_type);
  }

  const handleDriverCPF = (event) => {
    let value = event.target.value;
    setDriverCPF(value);
    clearErrors('cpf');
    if (value.length == 14 && !isCpfValid(value)) setCPFError();
  }

  const handleDatePicker = (date) => {
    clearErrors('cnh_expires_at');
    setCnhExpiresAt(date);
  }

  const handleVehicleTypeChange = (selected) => {
    // altera o tipo de veiculo
    setVehicleType(selected.target.value);
  }

  const handleStateChange = (selected) => {
    // altera o estado
    setState(selected.target.value);
  }

  const handleCityChange = (selected) => {
    // altera a cidade
    setCity(selected.target.value);
  }

  const validateFields = () => {
    if (!driverVehicle) {
      setError('license_plate', {type: 'required', message: 'Esse campo é obrigatório.'});
    }
    if (!isCpfValid(driverCPF)) setCPFError();
  }

  const setCPFError = () => {
    setError('cpf', {type: 'validate', message: 'Digite um CPF válido'});
  }

  const fillProfile = async () => {
    if (!isCpfValid(driverCPF)) {
      setCPFError();
      return;
    }
    const { data } = await UserService.postDriverProfile({ cpf: driverCPF });
    const {full_name, phone, vehicles, cnh_number, cnh_expires_at, msg} = data;

    // msg de motorista nao encontrado
    clearVehicleInfo();
    clearDriverInfo();
    clearVisitInfo();
    if (msg) {
      return;
    }

    // atualiza a lista com todos os veiculos do motorista
    setDriverVehicles(vehicles);

    // atualiza os campos do form
    setValue('full_name', full_name);
    setValue('phone', phone);
    setValue('cnh_number', cnh_number);

    // convert string to date
    let date = stringToDate(cnh_expires_at);
    setCnhExpiresAt(date);

    // atualiza a lista de opcoes de veiculos
    const vehiclesArray = mountVehicleOptions(vehicles);
    setDataListVehicles(vehiclesArray)
    clearErrors(['full_name', 'cpf', 'phone', 'cnh_number', 'cnh_expires_at']);
  }

  const mountVehicleOptions = (vehicles) => {
    return vehicles.map((item) => (
        {key: item.id, label: item.license_plate}
      )
    );
  }

  const handleDriverVehicle = (string) => {
    let newString = string.toUpperCase();
    vehicleElement.current.debouncedMatchingUpdateStep(newString);
    setDriverVehicle(newString);
    if (newString.length == 8) {
      vehicleElement.current.state.lastValidItem = {}
      updateVehicleFields({'license_plate': newString});
    }
    clearErrors('license_plate');
  }

  const handleConfirmationData = (data) => {
    setConfirmationData(data);
    setModal(true);
  }

  const onSubmit = (data) => {
    data.cpf = driverCPF;
    data.license_plate = driverVehicle;
    data.visit_type = visitationType;
    data.state = state
    data.city = city
    data.vehicle_type = vehicleType

    if (containerNumber) data.container_number = containerNumber

    if (data.driver_type == 'cliente'){
      delete data.company_name;
    } else if (data.driver_type == 'transportadora'){
      delete data.client_name;
    }

    if (!isCpfValid(data.cpf)) {
      setCPFError();
      return;
    }
    handleConfirmationData(data);
  }

  const clearVehicleInfo = (onlyInfos) => {
    // limpa os campos de informações de veículos
    if (!onlyInfos) {
      setDriverVehicle('');
      setDriverVehicles([]);
      setDataListVehicles([]);
      vehicleElement.current.debouncedMatchingUpdateStep('')
    }
    setState('');
    setCity('');
    setVehicleType('');
    setValue('vehicle_type', '');
    setValue('container_number', '');
  }

  const clearVisitInfo = () => {
    // limpa os campos de nome e telefone do motorista
    setDriverType('cliente');
    setVisitationType('carga');
    setValue('company_name', '');
    setValue('client_name', '');
  }

  const clearDriverInfo = (clearAll=false) => {
    // limpa os campos de nome, telefone, cnh e validade de cnh do motorista
    if (clearAll) {
        // limpa o campo de cpf
        setValue('cpf', '');
        setDriverCPF('');
    }
    setValue('full_name', '');
    setValue('phone', '');
    setValue('cnh_number', '');
    setValue('cnh_expires_at', '');
  }

  const getStates = async () => {
    const { data : { states } } = await CommonService.getStates();
    setStates( states );
  }

  const getVehicleTypes = async () => {
    const { data } = await CoreService.getVehicleTypes();
    setVehicleTypes( data );
  }

  const updateVehicleFields = async (body) => {
    // obtem as informações do veículo
    const { data } = await CoreService.getVehicleInfo(body);
    clearVehicleInfo(true);
    if (!data.license_plate) {
      // se nao existir veiculo, mantem o form de veiculo editavel
      setDisableVehicleInfo(false);
      return;
    }
    fillVehicleProfile(data);
    setDisableVehicleInfo(true);
  }

  const onSelectDatalist = (selectedItem) => {
    let {label} = selectedItem;
    setDriverVehicle(label);
    updateVehicleFields({'license_plate': label});
    clearErrors('license_plate');
  }

  const fillVehicleProfile = (vehicleInfo) => {
    // atualiza os campos de cidade, estado e tipo de veiculo atraves placa do veiculo
    if (vehicleInfo) {
      setState(vehicleInfo.city.state.uf);
      setCity(vehicleInfo.city.name);
      setVehicleType(vehicleInfo.vehicle_type.type);
      setValue('vehicle_type', vehicleInfo.vehicle_type.type);
      setContainerNumber(vehicleInfo.container_number);
      setValue('container_number', vehicleInfo.container_number);
      clearErrors(['state', 'city', 'vehicle_type', 'container_number']);
    } else {
      driverVehicles.map(item => {
        if (item.license_plate == vehicleInfo.license_plate) {
          setState(item.city.state.uf);
          setCity(item.city.name);
          setVehicleType(item.vehicle_type.type);
          setValue('vehicle_type', item.vehicle_type.type);
          setContainerNumber(item.container_number);
          setValue('container_number', item.container_number);
          clearErrors(['state', 'city', 'vehicle_type', 'container_number']);
          return
        }
      })
    }
  }

  useEffect(() => {
    async function checkToken(token){
      try {
        const { data } = await CoreService.isUrlTokenValid(token);
        setIsTokenValid(data.success);
      } catch(err) {
        const {response: { data }} = err;
        alert(data.message)
        history.push('/login')
      }
    }

    // checa se a url é válida
    checkToken(match.params.url_token);
    if (isTokenValid) {
      getStates();
      getVehicleTypes();
      $('#cpf').mask('000.000.000-00');
      $('#phone').mask('(00) 00000-0000');
      $('#cnh_number').mask("00000000000");
      $('#cnh_expires_at').mask('00/00/0000');
      $('.form-input-datalist').mask('SSS-0A00');
      $('.form-input-datalist').attr('name', 'license_plate');
    }
  }, [isTokenValid])

  return (
    <section className='register-page'>
      <CookieAlert onAccept={() => setFormVisibility(true)}/>
      <Modal
        active={modal}
        disableFunction={setModal}
      >
        <ConfirmationBox data={confirmationData} disableFunction={setModal} />
      </Modal>
      <div className='register-container'>
        <div className='form-wrapper'>
            <div className='logo-wrapper'>
                <img className='image' src={ 'https://gcb-gotervix-homolog.s3-us-west-2.amazonaws.com/static/images/logo.png' } />
            </div>
          <div className='text-container'>
            <h3 className='title'>Seja bem-vindo, motorista!</h3>
          </div>
          <div className='form-container'>
            {
            !formVisibility &&
            <label className='cookie-warning'>
              É necessário aceitar as políticas de cookie para ter acesso ao formulário. Atualize a página e tente novamente.
            </label>
            }
            <form className='form' onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <fieldset className='form-fieldset' disabled={!formVisibility}>
              <div className='input-container'>
                <label className='label'>CPF *</label>
                <Input
                  name='cpf'
                  placeholder='CPF *'
                  value={driverCPF}
                  error={ errors.cpf }
                  onChange={e => handleDriverCPF(e)}
                  onBlur={(e) => fillProfile()}
                  onClick={() => clearErrors('cpf')}
                />
                <label className='label'>Nome completo *</label>
                <Input
                  name='full_name'
                  label='Nome completo *'
                  defaultValue=''
                  error={ errors.full_name }
                  register={register({
                    required: 'Esse campo é obrigatório.',
                  })}
                />
                <label className='label'>Telefone *</label>
                <Input
                  name='phone'
                  label='Telefone *'
                  defaultValue=''
                  error={ errors.phone }
                  register={register({
                    required: 'Esse campo é obrigatório.',
                  })}
                />
                <label className='label'>CNH *</label>
                <Input
                  name='cnh_number'
                  label='CNH *'
                  defaultValue=''
                  error={ errors.cnh_number }
                  register={register({
                    required: 'Esse campo é obrigatório.',
                    validate: {
                      size: value => value.length == 11 || 'O número da CNH deve conter 11 dígitos',
                      pattern: value => isCnhValid(value) || 'Digite um número da CNH válido',
                    }
                  })}
                />
                <div className='form-input'>
                  <label className='label'>Data de vencimento da CNH *</label>
                  <CalendarInput
                    name='cnh_expires_at'
                    placeholder='Data de vencimento da CNH *'
                    className='input'
                    minDate={new Date()}
                    initialValue={cnhExpiresAt}
                    onChange={date => handleDatePicker(date)}
                    component={
                      <Input
                        defaultValue=''
                        error={ errors.cnh_expires_at }
                        register={register({
                          required: 'Esse campo é obrigatório.',
                          validate: {
                            value: (value) => isDateEqualGreaterThanToday(value) || 'O documento de CNH está com a data vencida'
                            }
                        })}
                      />
                    }
                  />
                </div>
                <div className='form-input'>
                  <label className='label'>Placa do Veículo (ABC-1234 ou ABC1D23) *</label>
                  <DataListInput
                    placeholder='Placa do Veículo (ABC-1234 ou ABC1D23) *'
                    items={dataListVehicles}
                    inputClassName='form-input-datalist'
                    onSelect={(item) => onSelectDatalist(item)}
                    onInput={(value) => handleDriverVehicle(value)}
                    ref={vehicleElement}
                  />
                  {
                    errors.license_plate && (
                      <span className='field-error'>
                        { errors.license_plate.message }
                      </span>
                    )
                  }
                </div>
                <div className='form-input'>
                  <label className='label'>Estado *</label>
                  <select
                  className='input form-select'
                  name='state'
                  value={state}
                  onChange={(e) => handleStateChange(e)}
                  ref={ register({ required: 'Esse campo é obrigatório.'})}
                  disabled={disableVehicleInfo}
                  >
                    <option value=''>Estado *</option>
                    {
                      states && states.map((item, index) => {
                        return <option key={index} value={item.uf}>{item.uf}</option>
                      })
                    }
                  </select>
                  {
                    errors.state && (
                      <span className='field-error'>
                        { errors.state.message }
                      </span>
                    )
                  }
                </div>
                <div className='form-input'>
                  <label className='label'>Cidade *</label>
                  <select
                  className='input form-select'
                  name='city'
                  value={city}
                  disabled={!state}
                  onChange={(e) => handleCityChange(e)}
                  ref={ register({ required: 'Esse campo é obrigatório.'})}
                  disabled={disableVehicleInfo}
                  >
                    <option value=''>Cidade *</option>
                    {
                      state && states.map((item) => {
                        if (item.uf === state) {
                          return item.cities.map((city) => {
                            return <option key={city.id} value={city.name}>{city.name}</option>
                          })
                        }
                      })
                    }
                  </select>
                  {
                    errors.city && (
                      <span className='field-error'>
                        { errors.city.message }
                      </span>
                    )
                  }
                </div>
                <div className='form-input'>
                  <label className='label'>Tipo do veículo *</label>
                  <select
                  className='input form-select'
                  value={vehicleType}
                  onChange={ (e) => handleVehicleTypeChange(e) }
                  name='vehicle_type'
                  ref={ register({ required: 'Esse campo é obrigatório.'})}
                  disabled={disableVehicleInfo}
                  >
                    <option value=''>Tipo do veículo *</option>
                    {
                      vehicleTypes && vehicleTypes.map((item) => {
                        return <option key={item.id} value={item.type}>{item.name}</option>
                      })
                    }
                  </select>
                  {
                    errors.vehicle_type && (
                      <span className='field-error'>
                        { errors.vehicle_type.message }
                      </span>
                    )
                  }
                </div>
                {
                  vehicleType === 'container' &&
                  <>
                    <label className='label'>Número do container *</label>
                    <Input
                      name='container_number'
                      label='Número do container *'
                      value={containerNumber}
                      onChange={(e) => handleContainerNumber(e)}
                      error={ errors.container_number }
                      register={register({
                        required: 'Esse campo é obrigatório.',
                      })}
                      disabled={disableVehicleInfo}
                    />
                  </>
                }
                <div className='form-check'>
                  <label className='label'>Tipo de motorista</label>
                  <div className='form-check form-row'>
                    <div>
                      <label>
                        <input name='driver_type' type='radio' style={{marginRight:'5px'}} value='cliente' checked={driverType === 'cliente'} onChange={handleDriverTypeChange} ref={ register({ required: 'Esse campo é obrigatório.'})}/>
                            Cliente
                      </label>
                    </div>
                    <div className='form-check-radio'>
                      <label>
                        <input name='driver_type' type='radio'style={{marginRight:'5px'}} value='transportadora'checked={driverType === 'transportadora'} onChange={handleDriverTypeChange} ref={ register({ required: 'Esse campo é obrigatório.'})}/>
                            Transportadora
                      </label>
                    </div>
                  </div>
                </div>
                {
                  driverType && driverType == 'cliente' ?
                  (
                    <>
                      <label className='label'>Nome do Cliente *</label>
                      <Input
                        name='client_name'
                        label='Nome do Cliente *'
                        defaultValue=''
                        error={ errors.client_name }
                        register={register({
                          required: 'Esse campo é obrigatório.',
                        })}
                      />
                    </>
                  ) :
                  (
                    <>
                      <label className='label'>Nome da Transportadora *</label>
                      <Input
                        name='company_name'
                        label='Nome da Transportadora *'
                        defaultValue=''
                        error={ errors.company_name }
                        register={register({
                          required: 'Esse campo é obrigatório.',
                        })}
                      />
                    </>
                  )
                }
                <div className='form-check'>
                  <label className='label'>Tipo de visita *</label>
                  <div className='form-check form-row'>
                    <div>
                      <label>
                        <input
                          name='visit_type'
                          type='radio'
                          value='carga'
                          style={{marginRight:'5px'}}
                          checked={visitationType === 'carga'}
                          onChange={handleVisitationTypeChange}
                          ref={ register({ required: 'Esse campo é obrigatório.'})}
                        />
                          Carga
                      </label>
                    </div>
                    <div className='form-check-radio'>
                      <label>
                        <input
                          name='visit_type'
                          type='radio'
                          value='descarga'
                          style={{marginRight:'5px'}}
                          checked={visitationType === 'descarga'}
                          onChange={handleVisitationTypeChange}
                          ref={ register({ required: 'Esse campo é obrigatório.'})}
                        />
                          Descarga
                      </label>
                    </div>
                  </div>
                </div>
                <div className='form-check consent'>
                  <label>
                    <input
                      name='consent_form'
                      type='checkbox'
                      style={{marginRight:'5px'}}
                      ref={ register({ required: 'Esse campo é obrigatório.'})}
                    />
                      Autorizo que meus dados sejam coletados para a finalidade de controle de acesso físico às dependências da Vivix.
                      Para mais informações sobre a proteção e privacidade de seus dados pessoais, leia a nossa <a href='http://www.gcb.com.br/pdfs/politica-de-privacidade.pdf'>[Política de Privacidade]</a>.
                  </label>
                  {
                    errors.consent_form && (
                      <span className='field-error'>
                        { errors.consent_form.message }
                      </span>
                    )
                  }
                </div>
                <input className='registerButton' type='submit' value='Finalizar Cadastro' onClick={(e) => validateFields()}/>
              </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Register
