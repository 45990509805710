import React, { useState, useEffect } from 'react';

import './styles.scss';

import { GoPencil } from 'react-icons/go';

import { useForm } from 'react-hook-form';

import { UserService } from '../../services'
import Toast from '../../components/Toast';

import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min';

import { isDateEqualGreaterThanToday } from './../../helpers/forms/isValid';
import { stringToDate, dateToString } from './../../helpers/forms/convert';
import CalendarInput from './../../components/CalendarInput/index';

const DriverCard = ({ driverId }) => {
  const [enableToEdit, setEnableToEdit] = useState(false);
  const [driverData, setDriverData] = useState({})
  const [cnhExpiresAt, setCnhExpiresAt] = useState('');

  const { register, handleSubmit, errors } = useForm();

  const getDriverData = async () => {
    const { data } = await UserService.postDriverProfile({ id: driverId })
    if (data) {
      setDriverData(data);
      if (driverData.cnh_expires_at) {
        setCnhExpiresAt(stringToDate(driverData.cnh_expires_at));
      }
    }
  }

  const handleDatePicker = (date) => {
    setCnhExpiresAt(date);
  }

  const onSubmit = async (formData) => {
    formData.cnh_expires_at = dateToString(cnhExpiresAt);
    try {
      const { data } = await UserService.patchDrivers(driverId, formData)
      setDriverData(data);
      setEnableToEdit(false);
      Toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      const {response: {data}} = error;
      const {phone, cpf, cnh_number, cnh_expires_at, non_field_errors} = data;
      if (phone) Toast.error(phone[0]);
      if (cpf) Toast.error(cpf[0]);
      if (cnh_number) Toast.error(cnh_number[0]);
      if (cnh_expires_at) Toast.error(cnh_expires_at[0]);
      if (non_field_errors) Toast.error(non_field_errors[0]);
    }
  }

  useEffect(() => {
      getDriverData();
      $('#cpf').mask('000.000.000-00');
      $('#phone').mask('(00) 00000-0000');
      $('#cnh_number').mask("00000000000");
      $('#cnh_expires_at').mask('00/00/0000');
  }, [enableToEdit])

  return (
    <div className='driver-card'>
      {
        !enableToEdit && (
          <div className='header fade-in'>
            <span className='role'>MOTORISTA</span>
            <span className='name'>{ driverData.full_name }</span>
          </div>
        )
      }
      <div className='body'>
        <form className='form fade-in' onSubmit={ handleSubmit(onSubmit) } autoComplete="off">
          {
            enableToEdit
              ? (
                <>
                  <div className='form-row'>
                    <label htmlFor='full_name' className='label'>MOTORISTA</label>
                    <input
                      type='text'
                      className='input'
                      name='full_name'
                      defaultValue={ driverData.full_name }
                      ref={register({
                        required: 'Esse campo é obrigatório.',
                      })}
                    />
                    {errors.full_name && Toast.error(errors.full_name.message)}
                  </div>
                  <div className='form-row'>
                    <label className='label'>CPF</label>
                    <input
                      id='cpf'
                      type='text'
                      className='input'
                      name='cpf'
                      defaultValue={ driverData.cpf }
                      ref={register({
                        required: 'Esse campo é obrigatório.',
                      })}
                    />
                    {errors.cpf && Toast.error(errors.cpf.message)}
                  </div>
                  <div className='form-row'>
                    <label className='label'>TELEFONE</label>
                    <input
                      id='phone'
                      type='text'
                      className='input'
                      name='phone'
                      defaultValue={ driverData.phone }
                      ref={register({
                        required: 'Esse campo é obrigatório.',
                      })}
                    />
                    {errors.phone && Toast.error(errors.phone.message)}
                  </div>
                  <div className='form-row'>
                    <label className='label'>CNH</label>
                    <input
                      id='cnh_number'
                      type='text'
                      className='input'
                      name='cnh_number'
                      defaultValue={ driverData.cnh_number }
                      ref={register({
                        required: 'Esse campo é obrigatório.',
                      })}
                    />
                    {errors.cnh_number && Toast.error(errors.cnh_number.message)}
                  </div>
                  <div className='form-row'>
                    <label className='label'>VENCIMENTO CNH</label>
                    <CalendarInput
                      name='cnh_expires_at'
                      className='input'
                      minDate={new Date()}
                      initialValue={cnhExpiresAt}
                      onChange={date => handleDatePicker(date)}
                      component={
                        <input
                          ref={register({
                            required: 'Esse campo é obrigatório.',
                            validate: {
                              value: value => isDateEqualGreaterThanToday(value) || 'A data de vencimento da CNH está vencida'
                            },
                          })}
                        />
                      }
                    />
                    {errors.cnh_expires_at && Toast.error(errors.cnh_expires_at.message)}
                  </div>
                </>
              )

              : (
                <>
                  <div className='body-row fade-in'>
                    <span className='key'>CPF</span>
                    <span className='value'>{ driverData.cpf }</span>
                  </div>
                  <div className='body-row fade-in'>
                    <span className='key'>TELEFONE</span>
                    <span className='value'>{ driverData.phone }</span>
                  </div>
                  <div className='body-row fade-in'>
                    <span className='key'>CNH</span>
                    <span className='value'>{ driverData.cnh_number }</span>
                  </div>
                  <div className='body-row fade-in'>
                    <span className='key'>VALIDADE CNH</span>
                    <span className='value'>{ driverData.cnh_expires_at }</span>
                  </div>
                </>
              )
          }
          <div className='footer'>
            {
              enableToEdit
                ? (
                  <>
                    <a onClick={() => setEnableToEdit(!enableToEdit)} className='button cancel fade-in'>
                      <span>Cancelar</span>
                    </a>
                    <button type='submit' className='button done fade-in'>
                      <span>Concluir edição</span>
                    </button>
                  </>
                )

                : (
                  <a onClick={() => setEnableToEdit(!enableToEdit)} className='button fade-in'>
                    <span>Editar dados do motorista</span>
                    <GoPencil />
                  </a>
                )
            }
          </div>
        </form>
      </div>
    </div>
  );
}

export default DriverCard
