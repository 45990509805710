import React, { useState, useEffect } from 'react';
import { isValid, format } from 'date-fns'
import { AiOutlineClose } from 'react-icons/ai';

import CalendarInput from '../CalendarInput';

import './styles.scss';

let debounce = null

const TablePeriodFilter = ({ handlePeriod }) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    try {
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        handlePeriodFilter()
      }, 1000)
    } catch(e) {
      console.log(e)
    }
  }, [startDate, endDate])

  const handlePeriodFilter = () => {
    const period = {}
    if (isValid(startDate)) period.startDate = format(startDate, 'dd/MM/yyyy')
    if (isValid(endDate)) period.endDate = format(endDate, 'dd/MM/yyyy')
    handlePeriod(period)
  }

  return (
    <div className='table-period-filter-component-wrapper'>
      <CalendarInput
        className="input-date"
        placeholder="De 00/00/0000"
        initialValue={startDate}
        onChange={setStartDate}
      />
      {
        !!startDate && (
          <button
            className="reset-date-btn"
            onClick={() => setStartDate(null)}
          >
            <AiOutlineClose className='icon' />
          </button>
        )
      }
      <CalendarInput
        className="input-date"
        placeholder="Até 00/00/0000"
        initialValue={endDate}
        onChange={setEndDate}
      />
      {
        !!endDate && (
          <button
            className="reset-date-btn"
            onClick={() => setEndDate(null)}
          >
            <AiOutlineClose className='icon' />
          </button>
        )
      }
    </div>
  );
}

export default TablePeriodFilter
