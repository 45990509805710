import api from './api'

class RobotService {
  static uploadProviders(body, onUploadProgress) {
    return api.put('upload/providers/', body, {
      headers: {
        ...api.getAuthToken(),
      },
      onUploadProgress,
    })
  }

  static uploadEmployees(body, onUploadProgress) {
    return api.put('users/upload/employees/', body, {
      headers: {
        ...api.getAuthToken(),
      },
      onUploadProgress,
    })
  }
}

export default RobotService
