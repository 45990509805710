import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useHistory
} from "react-router-dom";


import ROUTES from './routes';
import './style.scss';

const HiredScopes = () => {
  let history = useHistory();

  const onClickTd = (id) => {
    history.push(`/colaborador/escopo-contratado/${id}`);
  }

  return (
    <div className="page">
        <Router>
          <div className="nav nav-pills">
            {
              Array.isArray(ROUTES) && ROUTES.map((route) => (
                <div
                  className="nav-item"
                  key={`nav-item-${route.id}`}
                >
                  <NavLink
                    className="nav-link"
                    to={route.path}
                    exact={route.exact}
                    key={`nav-link-${route.id}`}
                  >
                    {route.title}
                  </NavLink>
                </div>

              ))
            }
          </div>
          <Switch>
            {
              Array.isArray(ROUTES) && ROUTES.map((route) => (
                <Route
                  key={`route-providers-hired-scopes-${route.id}`}
                  path={route.path}
                  exact={route.exact}
                  component={(props) => (
                    <route.page onClickTd={onClickTd}/>
                  )}
                />
              ))
            }
          </Switch>
        </Router>
    </div>

  );
}

export default HiredScopes;
