import React, { useState, useEffect } from 'react';

import './styles.scss';

import { Link } from 'react-router-dom';

import { VisitationService } from '../../services/';

import SearchInput from '../SearchInput';
import Toast from '../Toast';
import Loading from '../Loading';

import { MdKeyboardArrowDown } from 'react-icons/md';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';

import { getPages, handleNextPrevious } from '../../helpers/pagination/utils';

import FilterContent from '../../components/FilterContent'
import { dateToCSVfilename } from '../../helpers/forms/convert';
import { saveFile } from '../../utils/files';

const StatusChoice = ({ visitId, active, handleStatus, status, statusList, position }) => {
  const [selectedStatus, setSelectedStatus] = useState(status);

  const getStatusText = () => {
    try {
      return statusList.find(item => item.id == selectedStatus).status
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeStatus = async (id) => {
    const body = { 'status': id }
    try {
      const { data } = await VisitationService.put_status(visitId, body)
      setSelectedStatus(id)
      handleStatus(visitId);
      Toast.success('Status alterado com sucesso!')
    } catch (error) {
      const { response: { data } } = error;
      Toast.error(data.message || data.error);
    }
  }

  const getStatusColor = () => {
    const colors = {
      no_patio: '#D92550',
      entrada: '#F7B924',
      inicio_carregamento: '#30B1FF',
      inicio_descarregamento: '#30B1FF',
      fim_carregamento: '#545CD8',
      fim_descarregamento: '#545CD8',
      saida_balanca: '#3AC47D'
    }

    return colors[selectedStatus]
  }

  return (
    <div className='visit-history-status-choice'>
      <button
        style={{ backgroundColor: `${getStatusColor()}` }}
        onClick={handleStatus}
        className='button'
      >
        <span>{getStatusText(selectedStatus)}</span>
        <MdKeyboardArrowDown className='icon' />
      </button>
      {
        active && (
          <div className={`choices ${position}`}>
            {
              statusList && statusList.map(item => (
                <div
                  key={item.id}
                  className={`item ${item.id == selectedStatus ? 'selected' : ''}`}
                  onClick={() => handleChangeStatus(item.id)}
                >
                  {item.status}
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
}

const VisitsTable = ({ hasLink, pageSize, driverId, showSearchBar }) => {
  const [visitsTableData, setVisitsTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusSelect, setStatusSelect] = useState('');
  const [statusList, setStatusList] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filterBody, setFilterBody] = useState({
    type: 'visitations',
    search: search || '',
  });

  const handleVisitsTableData = async (newBody) => {
    setVisitsTableData({ ...visitsTableData, results: [] });
    setIsLoading(true)

    let body = newBody !== undefined ? newBody : {
      type: 'visitations',
      search: search || '',
    };

    try {
      const { data } = await VisitationService.visitHistory(currentPage, pageSize, driverId, body)
      setVisitsTableData(data);
      setIsLoading(false)
    } catch (error) {
      const { data } = await error.response
      if (data.detail == 'Invalid page.') {
        setCurrentPage(1)
      }
    }
  }

  // Array responsável por definir as opções de filtro de status da tabela
  const statusArray = [
    { label: "Aguardando no pátio", status: "no_patio", checked: false },
    { label: "Entrada", status: "entrada", checked: false },
    { label: "Início do carregamento", status: "inicio_carregamento", checked: false },
    { label: "Fim do carregamento", status: "fim_carregamento", checked: false },
    { label: "Início do descarregamento", status: "inicio_descarregamento", checked: false },
    { label: "Fim do descarregamento", status: "fim_descarregamento", checked: false },
    { label: "Saída da balança", status: "saida_balanca", checked: false },
  ];

  // Array responsável por definir as opções de escolha da pesquisa
  const searchArray = [
    { label: "DT", checked: true },
    { label: "CPF", checked: false },
    { label: "Motorista", checked: false },
    { label: "Cliente", checked: false },
    { label: "Transportadora", checked: false },
  ];

  const getBody = (childData) => {
    let newBody = {
      type: 'visitations',
      search: childData.search,
      status: childData.status
    }

    if (childData.search !== '') {
      newBody.search_type = childData.type_search.split(" ")[0]
    }

    if (childData.arrivalDate) {
      newBody.arrival_date = childData.arrivalDate.replaceAll('-', '/')
    }

    setFilterBody(newBody);
    setCurrentPage(1)
    handleVisitsTableData(newBody);
  }

  const handleCSVButton = async () => {
    // base do body antes de populado
    let body = {
      visitations_id: visitsTableData.results[0].csv_data,
    }

    // map populando o body
    // tableData.results.map((item)=>{
    //   body.hired_ids.push(item.id)
    // })

    // console.log(body)

    try {
      Toast.warning('Gerando CSV do histórico de visitas');
      const response = await VisitationService.downloadCSVFile(body);
      Toast.hide();

      let filename = `historico-visitas-${dateToCSVfilename(new Date)}.csv`
      await saveFile(response, filename);
      Toast.success('CSV gerado com sucesso');
    } catch (error) {
      console.error(error);
      let errorData = error?.response?.data;
      if (errorData?.message) Toast.error(errorData.message);
    }
  }

  const getStatusList = async () => {
    const { data } = await VisitationService.statusList();
    setStatusList(data);
    console.log(data)
  }

  const handleStatus = (id) => {
    setStatusSelect(statusSelect === id ? '' : id)
  }

  useEffect(() => {
    handleVisitsTableData(filterBody);
  }, [currentPage])

  useEffect(() => {
    getStatusList();
  }, [])

  return (
    <section className='visit-history-page fade-in'>
      <div className='table-wrapper'>
        <FilterContent
          titleText={'HISTÓRICO DE VISITAS'}
          searchFilter={searchArray}
          statusFilter={statusArray}
          onDataFromChild={getBody} // Passando a função como prop
          handleCSVButton={() => { handleCSVButton() }} // Passando a função de download do csv
        />
        <div className='table-container'>
          {
            isLoading && (
              <div className='loading-container'>
                <Loading />
              </div>
            )
          }
          <table className='table table-striped table-borderless'>
            <thead>
              <tr>
                <th scope='col'>Data chegada</th>
                <th scope='col'>Hora chegada</th>
                <th width='10%' className='pk' scope='col'>Motorista</th>
                <th scope='col'>Telefone</th>
                <th scope='col'>Placa do veículo</th>
                <th scope='col'>Cliente</th>
                <th scope='col'>Transportadora</th>
                <th scope='col'>Status</th>
                <th scope='col'>DT</th>
                <th scope='col'>CPF</th>
                <th scope='col'>CNH</th>
                <th scope='col'>Validade CNH</th>
                <th scope='col'>Veículo</th>
                <th scope='col'>Estado</th>
                <th scope='col'>Cidade</th>
                <th scope='col'>Nº (container)</th>
                <th scope='col'>Observação</th>
              </tr>
            </thead>
            <tbody>
              {
                visitsTableData.results && visitsTableData.results.map((item, index) => (
                  <tr className='fade-in' key={item.id}>
                    <th className='pk' scope='row'>
                      {
                        hasLink
                          ? (
                            <Link className='link' to={`/faturista/motorista/${item.driver.id}`}>
                              {item.arrival_date || '-'}
                            </Link>
                          ) : (
                            `${item.arrival_date || '-'}`
                          )
                      }
                    </th>
                    <th className='pk' scope='row'>
                      {
                        hasLink
                          ? (
                            <Link className='link' to={`/faturista/motorista/${item.driver.id}`}>
                              {item.arrival_hour || '-'}
                            </Link>
                          ) : (
                            `${item.arrival_hour || '-'}`
                          )
                      }
                    </th>
                    <th className='pk' scope='row'>
                      {
                        hasLink
                          ? (
                            <Link className='link' to={`/faturista/motorista/${item.driver.id}`}>
                              {item.driver.full_name}
                            </Link>
                          )
                          : (
                            `${item.driver.full_name}`
                          )
                      }
                    </th>
                    <td>{item.driver.phone}</td>
                    <td>{item.vehicle.license_plate}</td>
                    <td>{item.client_name || '-'}</td>
                    <td>{item.company_name || '-'}</td>
                    <td>
                      <StatusChoice
                        visitId={item.id}
                        active={statusSelect == item.id}
                        handleStatus={() => handleStatus(item.id)}
                        status={item.status}
                        position={index >= 6 ? 'top' : 'bottom'}
                        statusList={statusList.filter((statusItem) => {
                          let statusItemId = statusItem.id;
                          if (statusItemId.includes('_descarregamento')) {
                            if (item.visit_type !== 'descarga') return;
                          }
                          else if (statusItemId.includes('_carregamento')) {
                            if (item.visit_type !== 'carga') return;
                          }
                          return statusItem;
                        })}
                      />
                    </td>
                    <th className='pk' scope='row'>
                      {
                        hasLink
                          ? (
                            <Link className='link' to={`/faturista/motorista/${item.driver.id}`}>
                              {item.dt.dt_number || '-'}
                            </Link>
                          ) : (
                            `${item.dt.dt_number || '-'}`
                          )
                      }
                    </th>
                    {/* <td>{ item.dt.dt_number || '-' }</td> */}


                    <td>{item.driver.cpf}</td>

                    <td>{item.driver.cnh_number || '-'}</td>
                    <td>{item.driver.cnh_expires_at || '-'}</td>

                    <td>{item.vehicle.vehicle_type.name}</td>
                    <td>{item.vehicle.city.state.uf}</td>
                    <td>{item.vehicle.city.name}</td>


                    <td>{item.vehicle.container_number || '-'}</td>
                    <td>{item.note || '-'}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className='paginator-container'>
          <nav>
            <ul className='pagination'>
              <li className={`page-item ${visitsTableData.previous ? '' : 'disabled'}`}>
                <a
                  className='page-link'
                  onClick={() => handleNextPrevious(visitsTableData.previous, setCurrentPage)}
                >
                  <div className='item-right'>
                    <TiChevronLeft />
                    <p>Anterior</p>
                  </div>
                </a>
              </li>
              {
                getPages(visitsTableData.count, pageSize) && getPages(visitsTableData.count, pageSize).map(page => (
                  <li
                    key={page}
                    onClick={() => setCurrentPage(page)}
                    className={`page-item ${page == currentPage ? 'active' : ''} `}>
                    <a className='page-link'>{page}</a>
                  </li>
                ))
              }
              <li className={`page-item ${visitsTableData.next ? '' : 'disabled'}`}>
                <a
                  className='page-link'
                  onClick={() => { handleNextPrevious(visitsTableData.next, setCurrentPage) }}
                >
                  <div className='item-right'>
                    <p>Próximo </p>
                    <TiChevronRight />
                  </div>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
}

export default VisitsTable
