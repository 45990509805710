import React, { useContext, useState, useEffect, useRef } from 'react'
import { BsPaperclip } from 'react-icons/bs'

import { AuthContext } from '../../utils'
import { RobotService } from '../../services'

import './style.scss'


const UPLOAD_STATUS = {
  SUCCESS: 'Sucesso',
  ERROR: 'Erro',
  WARN: 'Aviso',
}

const DEFAULT_MODAL = {
  isVisible: false,
  isSingleFile: false,
  status: UPLOAD_STATUS.SUCCESS,
  uploadErrors: [],
}

const RobotUpload = ({ match: { params } }) => {
  const userInfo = useContext(AuthContext)
  const attachFiles = useRef(null);
  const [pageReady, setPageReady] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [files, setFiles] = useState([]);
  const [fileHistory, setFileHistory] = useState([])
  const [modal, setModal] = useState(DEFAULT_MODAL)

  useEffect(() => {
    const paramTypes = ['providers', 'employees']
    const rolesAllowed = ['robot', 'administrator']

    if (userInfo?.roles?.some(role => rolesAllowed.includes(role))
      && paramTypes.includes(params.type)) {
      setPageReady(true)
    }
  }, [])

  const dragOver = (e) => e.preventDefault()
  const dragEnter = (e) => e.preventDefault()
  const dragLeave = (e) => e.preventDefault()

  const fileDrop = (e) => {
    e.preventDefault()
    const dragFiles = e.dataTransfer.files
    handleFiles(dragFiles)
  }

  const handleFileChange = (e) => {
    const inputFiles = e.target.files
    handleFiles(inputFiles)
  }

  const handleFiles = (addFiles) => {
    if (addFiles.length) {
      for(let i = 0; i < addFiles.length; i++) {
        const file = addFiles[i];
        file.id = `${(new Date()).getTime()}-${i}`
        file.link = URL.createObjectURL(file)
        file.file_name = file.name.replace(/\s/g, '_')
        setFiles(prevArray => [...prevArray, file])
      }
    }
  }

  const handleUploadFiles = async () => {
    try {
      const { type } = params
      let requestMethod = null

      switch(type) {
        case 'employees': requestMethod = RobotService.uploadEmployees; break;
        case 'providers': requestMethod = RobotService.uploadProviders; break;
        default: requestMethod = null
      }

      if (requestMethod) {
        setIsUploading(true)
        const uploadFiles = [...files]
        const uploadErrors = []

        if (!uploadFiles.length) uploadErrors.push(['Nenhum arquivo foi selecionado!'])

        for (let i = 0; i < uploadFiles.length; i++) {
          const file = uploadFiles[i]
          const progress = document.querySelector(`#progress-${file.id}`)

          try {
            const formData = new FormData()
            formData.append('file', file)

            await requestMethod(formData, (progressEvent) => {
              if (progress) {
                const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100)

                progress.style.width = `${uploadPercentage}%`
                progress.classList.add('progress-uploading')

                if (uploadPercentage === 100) {
                  progress.classList.remove('progress-uploading')
                  progress.classList.add('progress-success')
                  file.success = true
                }
              }
            })
          } catch (error) {
            if (progress) {
              let errorMessage = 'Erro ao tentar fazer upload, verifique sua conexão.'
              console.log(error.response)
              if (error?.response) {
                const errorRes = error.response
                const errorData = errorRes.data

                if (errorRes.status === 400 && errorData?.msg)
                  errorMessage = errorData.msg

                if (errorRes.status === 400 && errorData?.errors)
                  errorMessage = errorData.errors

                if (errorRes.status === 401)
                  errorMessage = 'Seu token expirou! Faça o login novamente.'

                if (errorRes.status === 403)
                  errorMessage = 'Você não tem permissão para fazer esta operação.'
                
                if (errorRes.status === 500 && errorData)
                  errorMessage = `${errorRes.statusText} - ${String(errorData).substr(0, 200)}...`
              }
              
              file.success = false
              uploadErrors.push([file.file_name, errorMessage])
              progress.classList.remove('progress-uploading')
              progress.classList.add('progress-error')
              progress.style.width = '100%'
            }
          }
        }

        attachFiles.current.value = ''
        setFiles([])
        setFileHistory([...fileHistory, ...uploadFiles])
        setIsUploading(false)

        const status = uploadFiles.length <= uploadErrors.length ?
          UPLOAD_STATUS.ERROR : !uploadErrors.length ?
            UPLOAD_STATUS.SUCCESS : UPLOAD_STATUS.WARN

        setModal({
          isVisible: true,
          status,
          uploadErrors,
        })
      }
    } catch (error) {
      setIsUploading(false)
      console.log('handleUploadFiles error', error)
    }
  }

  if (!pageReady) return null

  return (
    <div id="robot-upload-page">
      {
        modal?.isVisible && (
          <div id="response-modal">
            <div className={`status status-${modal.status}`}>
              { modal.status }
            </div>
            <div className="content-modal">
              {
                modal.status === UPLOAD_STATUS.SUCCESS && (
                  'Todos arquivos foram enviados!'
                )
              }
              {
                [UPLOAD_STATUS.ERROR, UPLOAD_STATUS.WARN].includes(modal.status) && (
                  <>
                    {
                      modal.status === UPLOAD_STATUS.ERROR && (
                        'Erro ao enviar os arquivos!'
                      )
                    }
                    {
                      modal.status === UPLOAD_STATUS.WARN && (
                        'Alguns arquivos não foram enviados!'
                      )
                    }
                    {
                      Array.isArray(modal.uploadErrors) && !!modal.uploadErrors.length && (
                        <>
                          <span>Veja abaixo os erros:</span>
                          <ul>
                            {
                              modal.uploadErrors.map((error, index) => (
                                <li key={`error-${index}`}>
                                  { error[0] }
                                  { Array.isArray(error[1]) ? (
                                    <ul>
                                      { 
                                        error[1].map((subError, subIndex) => (
                                          <li key={`subError-${subIndex}`}>
                                            {subError}
                                          </li>
                                        ))
                                      }
                                    </ul>
                                  ) : `- ${error[1]}`}
                                </li>
                              ))
                            }
                          </ul>
                        </>
                      )
                    }
                  </>
                )
              }
            </div>
            <div className="actions-modal">
              <button onClick={() => setModal(DEFAULT_MODAL)}> OK </button>
            </div>
          </div>
        )
      }
      <div className="upload-container">
        <input
          id="attach-files"
          ref={attachFiles}
          type="file"
          multiple
          onChange={handleFileChange}
          hidden
        />
        <div
          className="drop-zone"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={() => attachFiles.current.click()}
        >
          <h2><BsPaperclip /> Anexar arquivos</h2>
          <span>Você pode arrastar e soltar os arquivos</span>
        </div>
        <div className="actions">
          <button
            id="upload-botton"
            className="upload-botton"
            onClick={() => handleUploadFiles()}
            disabled={isUploading}
          >
            Enviar
          </button>
        </div>
        <div className="files-container">
          {
            Array.isArray(files) && files.length > 0 && (
              <>
                <h3>Esperando para upload</h3>
                {
                  files.map((file, index) => (
                    <div key={`file-${index}`} className="files">
                      <div>{file.name}</div>
                      <div id={`progress-${file.id}`} className="progress"></div>
                    </div>
                  ))
                }
              </>
            )
          }
          {
            Array.isArray(fileHistory) && fileHistory.length > 0 && (
              <>
                <h3>Histórico</h3>
                {
                  fileHistory.slice(0).reverse().map((file, index) => (
                    <div key={`file-${index}`} className="files">
                      <div>{file.name}</div>
                      <div
                        id={`progress-${file.id}`}
                        className={`progress progress-done ${file.success ? 'progress-success' : 'progress-error'}`}
                      >
                      </div>
                    </div>
                  ))
                }
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default RobotUpload
