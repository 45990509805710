

import HiredScopes from '../../components/HiredScopes';
import ProvidersAndEmployeesAccesses from '../../components/ProvidersAndEmployeesAccesses';


export default [
  {
    id: "allHiredScopes",
    path: "/colaborador/escopos-contratados/todos",
    page: HiredScopes,
    title: "Todos escopos",
    exact: true
  },
  {
    id: "myHiredScopes",
    path: "/colaborador/escopos-contratados/meus-escopos",
    page: HiredScopes,
    title: "Meus escopos"
    
  },
  {
    id: "providersAccesses",
    path: "/colaborador/escopos-contratados/acessos-fornecedores",
    page: ProvidersAndEmployeesAccesses,
    title: "Fornecedores"
  }
]