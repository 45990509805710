import React, { useState, useEffect, useRef } from 'react';

import './styles.scss';

import { useForm } from 'react-hook-form';

import { IoMdClose } from 'react-icons/io';

import { useHistory } from 'react-router-dom';

import Toast from '../Toast';

import { UserService, CoreService, CommonService, VisitationService } from '../../services';
import { type } from 'jquery';

import { isDateEqualGreaterThanToday, isCnhValid, isCpfValid } from './../../helpers/forms/isValid';
import { stringToDate, dateToString } from './../../helpers/forms/convert';
import CalendarInput from './../../components/CalendarInput/index';


const UserCard = ({ visitID, disableCard }) => {
  let history = useHistory();

  const { register, handleSubmit, setError, clearErrors, setValue, errors } = useForm();
  const [driverCPF, setDriverCPF] = useState('');
  const [dataListVehicles, setDataListVehicles] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleType, setVehicleType] = useState('');
  const [states, setStates] = useState([]);
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [driverType, setDriverType] = useState('cliente');
  const [visitationType, setVisitationType] = useState('carga');
  const [visitData, setVisitData] = useState({})
  const [containerNumber, setContainerNumber] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [cnhExpiresAt, setCnhExpiresAt] = useState('');

  const vehicleElement = useRef();

  const getVisitData = async () => {
    const { data } = await VisitationService.retrive(visitID);
    setVisitData(data)
  }

  useEffect(() => {
    if(Object.entries(visitData).length > 0 ) {
      setDriverCPF(visitData.driver.cpf)
      setVehicleType(visitData.vehicle.vehicle_type.type)
      setState(visitData.vehicle.city.state.uf)
      setCity(visitData.vehicle.city.name)
      setDriverType(visitData.driver_type)
      setVisitationType(visitData.visit_type)
      setContainerNumber(visitData.vehicle.container_number)
      setCnhExpiresAt(stringToDate(visitData.driver.cnh_expires_at));
      setIsDataLoaded(true);
    }
  }, [visitData])

  const setCPFError = () => {
    setError('cpf', {type: 'validate', message: 'Digite um CPF válido'});
  }

  const handleDriverCPF = (event) => {
    let value = event.target.value;
    setDriverCPF(value);
    if (value.length == 14 && !isCpfValid(value)) {
      setCPFError();
    }
    else if (isCpfValid(value)) {
      clearErrors('cpf');
    }
  }

  const handleContainerNumber = (event) => {
    // altera o numero do container
    setContainerNumber(event.target.value);
  }

  const handleVehicleTypeChange = (selected) => {
    setVehicleType(selected.target.value);
  }

  const handleDriverTypeChange = (event) => {
    // altera o tipo de motorista
    let driver_type = event.target.value;
    setDriverType(driver_type);
    // limpa o campo toda alteracao de tipo de motorista
    setValue('client_name', '');
    setValue('company_name', '');
  }

  const handleVisitationTypeChange = (event) => {
    let visit_type = event.target.value;
    setVisitationType(visit_type);
  }

  const handleStateChange = (selected) => {
    setState(selected.target.value);
  }

  const handleCityChange = (selected) => {
    setCity(selected.target.value);
  }

  const handleDatePicker = (date) => {
    clearErrors('cnh_expires_at');
    setCnhExpiresAt(date);
  }

  const validateFields = () => {
    // valida os campos antes de  submeter o formulario
    if (!isCpfValid(driverCPF)) setCPFError();
    clearErrors('cpf');
  }

  const getStates = async () => {
    const { data: { states } } = await CommonService.getStates();
    setStates(states);
  }

  const getVehicleTypes = async () => {
    const { data } = await CoreService.getVehicleTypes();
    setVehicleTypes(data);
  }

  useEffect(() => {
    getVehicleTypes();
    getStates();
    getVisitData();
  }, [])

  useEffect(() => {
    $('#cpf').mask('000.000.000-00');
    $('#phone').mask('(00) 00000-0000');
    $('#cnh_number').mask("00000000000");
    $('#cnh_expires_at').mask('00/00/0000');
    $('#license_plate').mask('SSS-0A00', {
      onKeyPress: function (value, event) {
        event.currentTarget.value = value.toUpperCase();
      }
    });
    $('#dt').mask("#0", {reverse: true})
  }, [isDataLoaded]);

  const onSubmit = async (body) => {
    body['full_name'] = visitData.driver.full_name;
    body.cpf = driverCPF;
    body.cnh_expires_at = dateToString(cnhExpiresAt);
    if (body.driver_type == 'cliente'){
      body.company_name = null;
    } else if (body.driver_type == 'transportadora'){
      body.client_name = null;
    }
    if (!isCpfValid(body.cpf)) {
      setCPFError();
      return;
    }

    try {
      const { data } = await VisitationService.validatePreRegister(visitID, body);
      Toast.success('Visita realizada com Sucesso!');
      history.push('/faturista/historico-de-visitas');
    } catch (error) {
      const {response: {data}} = error;
      if (data.message) {
        Toast.error(data.message);
      } else {
        if (data.errors) {
          let item = data.errors[0];
          Object.keys(item).map(function(key) {
            if (item[key] && item[key] instanceof Array) {
              Toast.error(item[key][0]);
            }
          });
        }
      }
    }
  }

  return (
    <section className='user-card-container'>
      <form className='user-card' onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className='header form-container'>
          <button className='close-button' onClick={() => disableCard(null)}>
            <IoMdClose />
          </button>
          <div className='title'>
            <span className='up'>MOTORISTA</span>
            <span className='down'>{ visitData.driver ? visitData.driver.full_name : '' }</span>
          </div>
          <div className='form-row'>
            <label className='label' htmlFor='cpf'>CPF</label>
            <input
              className='input'
              id='cpf'
              name='cpf'
              value={driverCPF}
              onChange={e => handleDriverCPF(e)}
            />
            {
              errors.cpf && (
                <span className='field-error'>
                  * {errors.cpf.message}
                </span>
              )
            }
          </div>
          <div className='form-row'>
            <label htmlFor='phone' className='label'>TELEFONE</label>
            <input
              className='input'
              id='phone'
              defaultValue={ visitData.driver ? visitData.driver.phone : '' }
              name='phone'
              error={errors.phone}
              ref={register({
                  required: 'Esse campo é obrigatório.',
              })}
            />
            {
              errors.phone && (
                <span className='field-error'>
                  * {errors.phone.message}
                </span>
              )
            }
          </div>
          <div className='form-row'>
            <label htmlFor='cnh_number' className='label'>CNH</label>
            <input
              className='input'
              name='cnh_number'
              id='cnh_number'
              defaultValue={ visitData.driver ? visitData.driver.cnh_number : '' }
              error={errors.cnh}
              ref={register({
                required: 'Esse campo é obrigatório.',
                validate: {
                    size: value => value.length == 11 || 'O número da CNH deve conter 11 dígitos',
                    pattern: value => isCnhValid(value) || 'Digite um número da CNH válido',
                }
              })}
            />
            {
              errors.cnh_number && (
                <span className='field-error'>
                  * {errors.cnh_number.message}
                </span>
              )
            }
          </div>
          <div className='form-row'>
            <label htmlFor='cnh_expires_at' className='label'>Validade CNH</label>
            <CalendarInput
              name='cnh_expires_at'
              className='input'
              minDate={new Date()}
              initialValue={cnhExpiresAt}
              onChange={date => handleDatePicker(date)}
              component={
                <input
                  defaultValue=''
                  ref={register({
                    required: 'Esse campo é obrigatório.',
                    validate: {
                      value: value => isDateEqualGreaterThanToday(value) || 'A data de vencimento da CNH está vencida'
                    },
                  })}
                />
              }
            />
            {
              errors.cnh_expires_at && (
                <span className='field-error'>
                  * {errors.cnh_expires_at.message}
                </span>
              )
            }
          </div>
        </div>
        <div className='body form-container white'>
          <div className='section'>
            DADOS DA VISITA
          </div>
          <div className='form-row'>
            <label className='label'>PLACA</label>
            <input
              className='input'
              name='license_plate'
              id='license_plate'
              defaultValue={ visitData.vehicle ? visitData.vehicle.license_plate : '' }
              error={errors.cnh}
              ref={register({
                  required: 'Esse campo é obrigatório.',
              })}
            />
            {
              errors.license_plate && (
                <span className='field-error'>
                  * {errors.license_plate.message}
                </span>
              )
            }
          </div>
          <div className='form-row'>
            <label className='label'>VEÍCULO</label>
            <select
              className='input form-select'
              value={ vehicleType }
              onChange={(e) => handleVehicleTypeChange(e)}
              name='vehicle_type'
              ref={register({ required: 'Esse campo é obrigatório.' })}
            >
              <option value=''>Tipo do veículo</option>
              {
                vehicleTypes && vehicleTypes.map((item) => {
                  return <option key={item.id} value={item.type}>{item.name}</option>
                })
              }
            </select>
            {
              errors.vehicle_type && (
                <span className='field-error'>
                  {errors.vehicle_type.message}
                </span>
              )
            }
          </div>
          {
            vehicleType === 'container' &&
            <div className='form-row'>
              <label className='label'>Nº CONTAINER</label>
              <input
                className='input'
                id='container_number'
                ref={register({
                  required: 'Esse campo é obrigatório.',
                })}
                name='container_number'
                value={containerNumber}
                onChange={(e) => handleContainerNumber(e)}
              />
                {
                  errors.container_number && (
                    <span className='field-error'>
                      * { errors.container_number.message }
                    </span>
                  )
                }
            </div>
          }
          <div className='form-row'>
            <label className='label'>ESTADO</label>
            <select
              className='input form-select'
              name='state'
              value={state}
              onChange={(e) => handleStateChange(e)}
              ref={register({ required: 'Esse campo é obrigatório.' })}
            >
              <option value=''>Estado</option>
              {
                states && states.map((item, index) => {
                  return <option key={index} value={item.uf}>{item.uf}</option>
                })
              }
            </select>
            {
              errors.state && (
                <span className='field-error'>
                  {errors.state.message}
                </span>
              )
            }
          </div>
          <div className='form-row'>
            <label className='label'>CIDADE</label>
            <select
              className='input form-select'
              name='city'
              value={city}
              disabled={!state}
              onChange={(e) => handleCityChange(e)}
              ref={register({ required: 'Esse campo é obrigatório.' })}
            >
              <option value=''>Cidade</option>
              {
                state && states.map((item) => {
                  if (item.uf === state) {
                    return item.cities.map((city) => {
                      return <option key={city.id} value={city.name}>{city.name}</option>
                    })
                  }
                })
              }
            </select>
            {
                errors.city && (
                    <span className='field-error'>
                        {errors.city.message}
                    </span>
                )
            }
          </div>
          <div className='section'>
            TIPO DE MOTORISTA:
          </div>
          <div className='form-check form-row'>
            <div>
                <label
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '12px'
                  }}
                >
                  <input
                    style={{ marginRight: '5px' }}
                    type='radio'
                    name='driver_type'
                    value='cliente'
                    checked={driverType === 'cliente'}
                    onChange={handleDriverTypeChange}
                    ref={register({ required: 'Esse campo é obrigatório.' })}
                  />
                  Cliente
                </label>
              </div>
              <div className='form-check-radio'>
                <label
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '12px'
                  }}
                >
                    <input
                      name='driver_type'
                      type='radio'
                      style={{ marginRight: '5px' }}
                      value='transportadora'
                      checked={driverType === 'transportadora'}
                      onChange={handleDriverTypeChange}
                      ref={register({ required: 'Esse campo é obrigatório.' })}
                    />
                    Transportadora
                </label>
              </div>
          </div>
          <div className='form-row'>
            <label className='label'>NOME</label>
            {
              driverType && driverType == 'cliente' ?
              (
                <>
                  <input
                    className='input'
                    name='client_name'
                    defaultValue={ visitData.client_name }
                    error={errors.client_name}
                    ref={register({
                        required: 'Esse campo é obrigatório.',
                    })}
                  />
                  {
                    errors.client_name && (
                      <span className='field-error'>
                        * {errors.client_name.message}
                      </span>
                    )
                  }
                </>
              ) :
              (
                <>
                  <input
                    className='input'
                    name='company_name'
                    defaultValue={ visitData.company_name }
                    error={errors.company_name}
                    ref={register({
                        required: 'Esse campo é obrigatório.',
                    })}
                  />
                  {
                    errors.company_name && (
                      <span className='field-error'>
                        * {errors.company_name.message}
                      </span>
                    )
                  }
                </>
              )
            }
          </div>
          <div className='section'>
            TIPO DE VISITA:
          </div>
          <div className='form-check form-row'>
            <div>
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '12px'
                }}
              >
                <input
                  name='visit_type'
                  type='radio'
                  value='carga'
                  style={{ marginRight: '5px' }}
                  checked={visitationType === 'carga'}
                  onChange={handleVisitationTypeChange}
                  ref={register({ required: 'Esse campo é obrigatório.' })}
                />
                Carga
              </label>
            </div>
              <div className='form-check-radio'>
                <label
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '12px'
                  }}
                >
                  <input
                    name='visit_type'
                    type='radio'
                    value='descarga'
                    style={{ marginRight: '5px' }}
                    checked={visitationType === 'descarga'}
                    onChange={handleVisitationTypeChange}
                    ref={register({ required: 'Esse campo é obrigatório.' })}
                  />
                  Descarga
                </label>
              </div>
          </div>
          <div className='form-row'>
            <label style={{color: '#545CD8'}} className='label'>DT</label>
            <input
              className='input'
              id='dt'
              name='dt'
              defaultValue={ visitData.dt || '' }
              ref={register({
                  required: 'Esse campo é obrigatório.',
              })}
            />
            {
              errors.dt && (
                <span className='field-error'>
                  * {errors.dt.message}
                </span>
              )
            }
          </div>
          <div className='form-row'>
            <label className='label'>OBSERVAÇÃO</label>
            <input
              className='input'
              id='note'
              name='note'
              defaultValue=''
              ref={register({})}
            />
          </div>
          <input className='button' type='submit' value='Registrar visita' accessKey='enter' onClick={(e) => validateFields()} />
        </div>
      </form>
    </section>
  );
}

export default UserCard
