import React from 'react';
import {
  ROLE_ADMIN,
  ROLE_EMPLOYEE,
  ROLE_FATURISTA,
  ROLE_PROVIDER,
  ROLE_SSMA,
  ROLES_LABELS,
} from '../constants'

const AuthContext = React.createContext({
  isAuthenticated: false,
  token: '',
  refreshToken: ''
});

const userInfoIsUpdated = (newUserInfo) => {
  const userInfo = {
		isAuthenticated: !!getToken(),
    token: getToken(),
    name: getName(),
    roles: getRoles(),
    company: getCompany(),
  }

  const keys = Object.keys(userInfo)

  for (let i in keys) {
    const key = keys[i]
    if (Array.isArray(userInfo[key]) && Array.isArray(newUserInfo[key])) {
      // Esta comparação só está sendo realizada dessa forma, porque o array vem ordenado alfabeticamente
      if (JSON.stringify(userInfo[key]) !== JSON.stringify(newUserInfo[key]) ) return false
    } else {
      if (userInfo[key] !== newUserInfo[key]) return false
    }
  }

  return true
}

const getToken = () => {
  return localStorage.getItem('TOKEN');
}

const getName = () => {
  return localStorage.getItem('NAME');
}

const getRoles = () => {
  try {
    return JSON.parse(localStorage.getItem('ROLES')) || []
  } catch(e) {
    return []
  }
}

const getRolesLabels = (text = true, separator = ' | ') => {
  try {
    const rolesStorage = JSON.parse(localStorage.getItem('ROLES'))
    const roles = rolesStorage.map(role => ROLES_LABELS[role] || '')

    if (text) return roles.join(separator)
    return roles
  } catch(e) {
    return text ? '' : []
  }
}

const getCompany = () => {
  return localStorage.getItem('COMPANY');
}

const checkRole = (roles, rolesAllowed) => {
  return Array.isArray(roles) && roles.some(role =>
    rolesAllowed.includes(role)
  )
}

const isAdmin = (roles = getRoles()) => {
  return Array.isArray(roles) && (roles.includes(ROLE_ADMIN))
}

const isFaturista = (roles = getRoles()) => {
  return checkRole(roles, [ROLE_FATURISTA, ROLE_ADMIN])
}

const isEmployee = (roles = getRoles()) => {
  return checkRole(roles, [ROLE_EMPLOYEE, ROLE_FATURISTA, ROLE_ADMIN])
}

const isProvider = (roles = getRoles()) => {
  return checkRole(roles, [ROLE_PROVIDER, ROLE_ADMIN])
}

const isOnlyProvider = (roles = getRoles()) => {
  return roles.includes(ROLE_PROVIDER) && !isAdmin(roles)
}

const isSSMA = (roles = getRoles()) => {
  return checkRole(roles, [ROLE_SSMA, ROLE_ADMIN])
}

const isOnlySSMA = (roles = getRoles()) => {
  return roles.includes(ROLE_SSMA) && !isAdmin(roles)
}

export default AuthContext
export {
  userInfoIsUpdated,
  getToken, getName, getCompany, getRoles, getRolesLabels,
  isEmployee, isFaturista, isAdmin,
  isProvider, isOnlyProvider,
  isSSMA, isOnlySSMA,
}
