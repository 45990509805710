import React, { useState, useEffect, useRef } from 'react';

import VehiclesTable from '../../components/VehiclesTable';

import './styles.scss';

import { useForm } from 'react-hook-form';

import DataListInput from 'react-datalist-input';

import Input from '../../components/Input';
import CalendarInput from '../../components/CalendarInput/index';

import { CommonService, UserService, CoreService } from './../../services';

import { isCpfValid, isDateEqualGreaterThanToday, isCnhValid } from './../../helpers/forms/isValid';
import { stringToDate } from './../../helpers/forms/convert';

import Modal from '../../components/Modal';

import ConfirmationBox from '../Register/ConfirmationBox';


const NewRegister = () => {
    const { register, handleSubmit, setError, clearErrors, setValue, errors } = useForm();
    const [driverType, setDriverType] = useState('cliente');
    const [driverCPF, setDriverCPF] = useState('');
    const [driverVehicles, setDriverVehicles] = useState([]);
    const [driverVehicle, setDriverVehicle] = useState('');
    const [visitationType, setVisitationType] = useState('carga');
    const [dataListVehicles, setDataListVehicles] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [vehicleType, setVehicleType] = useState('');
    const [containerNumber, setContainerNumber] = useState('');
    const [states, setStates] = useState([]);
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const vehicleElement = useRef();
    const [confirmationData, setConfirmationData] = useState({});
    const [modal, setModal] = useState(false);
    const [vehicleTableCPF, setVehicleTableCPF] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [cnhExpiresAt, setCnhExpiresAt] = useState('');

    useEffect(() => {
        getStates();
        getVehicleTypes();
        setIsDataLoaded(true);
    }, []);

    useEffect(() => {
        $('#cpf').mask('000.000.000-00');
        $('#phone').mask('(00) 00000-0000');
        $('#cnh_number').mask("00000000000");
        $('#cnh_expires_at').mask('00/00/0000');
        $('#dt').mask("#0", {reverse: true});
        $('.form-input-datalist').mask('SSS-0A00');
        $('.form-input-datalist').attr('name', 'license_plate');
    }, [isDataLoaded]);

    const getStates = async () => {
        const { data: { states } } = await CommonService.getStates();
        setStates(states);
    }

    const getVehicleTypes = async () => {
        const { data } = await CoreService.getVehicleTypes();
        setVehicleTypes(data);
    }

    const handleDriverTypeChange = (event) => {
        // altera o tipo de motorista
        let driver_type = event.target.value;
        setDriverType(driver_type);
        // limpa o campo toda alteracao de tipo de motorista
        setValue('client_name', '');
        setValue('company_name', '');
    }

    const handleDriverCPF = (event) => {
        let value = event.target.value;
        setDriverCPF(value);
        clearErrors('cpf');
        if (value.length == 14 && !isCpfValid(value)) setCPFError();
    }

    const handleDriverVehicle = (string) => {
        let newString = string.toUpperCase();

        vehicleElement.current.debouncedMatchingUpdateStep(newString);

        setDriverVehicle(newString);
        clearErrors('license_plate');
    }

    const handleStateChange = (selected) => {
        // altera o estado
        setState(selected.target.value);
    }

    const handleCityChange = (selected) => {
        // altera a cidade
        setCity(selected.target.value);
    }

    const handleVehicleTypeChange = (selected) => {
        // altera o tipo de veiculo
        setVehicleType(selected.target.value);
    }

    const handleContainerNumber = (event) => {
        // altera o numero do container
        setContainerNumber(event.target.value);
    }

    const handleDatePicker = (date) => {
        clearErrors('cnh_expires_at');
        setCnhExpiresAt(date);
    }

    const handleVisitationTypeChange = (event) => {
        // altera o tipo de visita do motorista (carga ou descarga)
        let visit_type = event.target.value;
        setVisitationType(visit_type);
    }

    const onSelectDatalist = (selectedItem) => {
        let { label } = selectedItem;
        handleDriverVehicle(label);
        fillVehicleProfile(label);
        clearErrors('license_plate');
    }

    const validateFields = () => {
        if (!driverVehicle) {
          setError('license_plate', {type: 'required', message: 'Esse campo é obrigatório.'});
        }

        if (!isCpfValid(driverCPF)) setCPFError();
    }

    const clearForm = () => {
        clearVehicleInfo();
        clearDriverInfo(true);
        clearVisitInfo();
        clearErrors();

    }

    const setCPFError = () => {
        setError('cpf', {type: 'validate', message: 'Digite um CPF válido'});
    }

    const fillProfile = async () => {
        if (!isCpfValid(driverCPF)) {
            setCPFError();
            return;
        }
        const { data } = await UserService.postDriverProfile({cpf: driverCPF});
        const { full_name, phone, vehicles, cnh_number, cnh_expires_at, msg } = data;

        // msg de motorista nao encontrado
        clearVehicleInfo();
        clearDriverInfo();
        clearVisitInfo();
        if (msg) {
            return;
        }

        // atualiza a lista com todos os veiculos do motorista
        setDriverVehicles(vehicles);

        // atualiza os campos do form
        setValue('full_name', full_name);
        setValue('phone', phone);
        setValue('cnh_number', cnh_number);
        // convert string to date
        let date = stringToDate(cnh_expires_at);
        setCnhExpiresAt(date);
        // setVehicleTableCPF(driverCPF);
        // atualiza a lista de opcoes de veiculos
        const vehiclesArray = mountVehicleOptions(vehicles);
        setDataListVehicles(vehiclesArray)
        clearErrors(['full_name', 'cpf', 'phone', 'cnh_number', 'cnh_expires_at']);
    }

    const mountVehicleOptions = (vehicles) => {
        // monta a lista de veiculos ativos que devem ser exibida no datalist
        return vehicles.map((item) => (
            { key: item.id, label: item.license_plate, status: item.active }
        )).filter(item => item.status);
    }

    const fillVehicleProfile = (license_plate) => {
        // atualiza os campos de cidade, estado e tipo de veiculo atraves placa do veiculo
        driverVehicles.map(item => {
            if (item.license_plate == license_plate) {
                setState(item.city.state.uf);
                setCity(item.city.name);
                setVehicleType(item.vehicle_type.type);
                setValue('vehicle_type', item.vehicle_type.type);
                setContainerNumber(item.container_number);
                setValue('container_number', item.container_number);
                clearErrors(['state', 'city', 'vehicle_type', 'container_number']);
            }
        })
    }

    const handleConfirmationData = (data) => {
        setConfirmationData(data);
        setModal(true);
    }

    const onSubmit = (data) => {
        data.cpf = driverCPF;
        data.license_plate = driverVehicle;
        data.visit_type = visitationType;
        if (data.driver_type == 'cliente') {
            delete data.company_name;
        } else if (data.driver_type == 'transportadora') {
            delete data.client_name;
        }
        if (!isCpfValid(data.cpf)) {
            setCPFError();
            return;
        }
        handleConfirmationData(data);
    }

    const clearVehicleInfo = () => {
        // limpa os campos de informações de veículos
        setDriverVehicle('');
        setDriverVehicles([]);
        setDataListVehicles([]);
        vehicleElement.current.debouncedMatchingUpdateStep('')
        setState('');
        setCity('');
        setVehicleType('');
        setValue('vehicle_type', '');
        setValue('container_number', '');
        setVehicleTableCPF('');
        setDataListVehicles([]);
    }

    const clearDriverInfo = (clearAll=false) => {
        // limpa os campos de nome, telefone, cnh e validade de cnh do motorista
        if (clearAll) {
            // limpa o campo de cpf
            setValue('cpf', '');
            setDriverCPF('');
        }
        setValue('full_name', '');
        setValue('phone', '');
        setValue('cnh_number', '');
        setValue('cnh_expires_at', '');
    }

    const clearVisitInfo = () => {
        // limpa os campos de nome e telefone do motorista
        setDriverType('cliente');
        setVisitationType('carga');
        setValue('company_name', '');
        setValue('client_name', '');
        setValue('dt', '');
        setValue('note', '');
      }

    return (
        <div style={{
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          paddingBottom: '30px'
        }}>
            <Modal
                active={modal}
                disableFunction={setModal}
            // disableWhenClickAway={true}
            >
                <ConfirmationBox data={confirmationData} disableFunction={setModal} />
            </Modal>
            <div className='title'>Novo Cadastro</div>
            <form style={{margin: '0px'}} className='row form-register' onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className='col-md-12 col-lg-6 forms-left'>
                    <div className='row forms-driver-data'>
                        <div className='form' >
                            <div className='input-container' style={{position: 'relative', zIndex: '10'}}>
                                <label className='title-label'>DADOS DO MOTORISTA</label>
                                <label className='label'>CPF *</label>
                                <Input
                                    name='cpf'
                                    placeholder='CPF *'
                                    value={driverCPF}
                                    error={ errors.cpf }
                                    onChange={e => handleDriverCPF(e)}
                                    onBlur={(e) => fillProfile()}
                                    onClick={() => clearErrors('cpf')}
                                />
                                <label className='label'>Motorista *</label>
                                <Input
                                    name='full_name'
                                    label='Motorista *'
                                    defaultValue=''
                                    error={errors.full_name}
                                    register={register({
                                        required: 'Esse campo é obrigatório.',
                                    })}
                                />
                                <label className='label'>Telefone *</label>
                                <Input
                                    name='phone'
                                    label='Telefone *'
                                    defaultValue=''
                                    error={errors.phone}
                                    register={register({
                                        required: 'Esse campo é obrigatório.',
                                    })}
                                />
                                <label className='label'>CNH *</label>
                                <Input
                                    name='cnh_number'
                                    label='CNH *'
                                    defaultValue=''
                                    error={errors.cnh_number}
                                    register={register({
                                        required: 'Esse campo é obrigatório.',
                                        validate: {
                                            size: value => value.length == 11 || 'O número da CNH deve conter 11 dígitos',
                                            pattern: value => isCnhValid(value) || 'Digite um número da CNH válido',
                                        }
                                    })}
                                />
                                <label className='label'>Data de vencimento da CNH *</label>
                                <CalendarInput
                                    name='cnh_expires_at'
                                    placeholder='Data de vencimento da CNH *'
                                    className='input'
                                    minDate={new Date()}
                                    initialValue={cnhExpiresAt}
                                    onChange={date => handleDatePicker(date)}
                                    component={
                                    <Input
                                        defaultValue=''
                                        error={ errors.cnh_expires_at }
                                        register={register({
                                        required: 'Esse campo é obrigatório.',
                                        validate: {
                                            value: (value) => isDateEqualGreaterThanToday(value) || 'O documento de CNH está com a data vencida'
                                            }
                                        })}
                                    />
                                    }
                                />
                            </div>


                        </div>
                        <a className='vehiclesSearch' onClick={(e) => setVehicleTableCPF(driverCPF)}>Buscar veículos</a>
                    </div>
                    <div className='row forms-vehicles-data'>
                        <VehiclesTable
                            pageSize={5}
                            driverFilter={true}
                            cpf={vehicleTableCPF}
                            selectVehicle={onSelectDatalist}
                            onClickRow={true}
                        />
                    </div>
                </div>
                <div className='col-md-12 col-lg-6 visit-data'>
                  <div className='visit-data-form-container'>
                    <div className='form-input'>
                        <label className='title-label'>DADOS DA VISITA</label>
                        <label className='label'>Placa do Veículo (ABC-1234 ou ABC1D23) *</label>
                        <DataListInput
                            placeholder='Placa do Veículo (ABC-1234 ou ABC1D23) *'
                            items={dataListVehicles}
                            inputClassName='form-input-datalist'
                            onSelect={(item) => onSelectDatalist(item)}
                            onInput={(value) => handleDriverVehicle(value)}
                            ref={vehicleElement}
                        />
                        {
                            errors.license_plate && (
                                <span className='field-error'>
                                    {errors.license_plate.message}
                                </span>
                            )
                        }
                    </div>
                    <div className='form-input'>
                      <label className='label'>Estado *</label>
                        <select
                            className='input form-select'
                            name='state'
                            value={state}
                            onChange={(e) => handleStateChange(e)}
                            ref={register({ required: 'Esse campo é obrigatório.' })}
                        >
                            <option value=''>Estado *</option>
                            {
                                states && states.map((item, index) => {
                                    return <option key={index} value={item.uf}>{item.uf}</option>
                                })
                            }
                        </select>
                        {
                            errors.state && (
                                <span className='field-error'>
                                    {errors.state.message}
                                </span>
                            )
                        }
                    </div>
                    <div className='form-input'>
                      <label className='label'>Cidade *</label>
                        <select
                            className='input form-select'
                            name='city'
                            value={city}
                            disabled={!state}
                            onChange={(e) => handleCityChange(e)}
                            ref={register({ required: 'Esse campo é obrigatório.' })}
                        >
                            <option value=''>Cidade *</option>
                            {
                                state && states.map((item) => {
                                    if (item.uf === state) {
                                        return item.cities.map((city) => {
                                            return <option key={city.id} value={city.name}>{city.name}</option>
                                        })
                                    }
                                })
                            }
                        </select>
                        {
                            errors.city && (
                                <span className='field-error'>
                                    {errors.city.message}
                                </span>
                            )
                        }
                    </div>
                    <div className='form-input'>
                      <label className='label'>Tipo do veículo *</label>
                        <select
                            className='input form-select'
                            value={vehicleType}
                            onChange={(e) => handleVehicleTypeChange(e)}
                            name='vehicle_type'
                            ref={register({ required: 'Esse campo é obrigatório.' })}
                        >
                            <option value=''>Tipo do veículo *</option>
                            {
                                vehicleTypes && vehicleTypes.map((item) => {
                                    return <option key={item.id} value={item.type}>{item.name}</option>
                                })
                            }
                        </select>
                        {
                            errors.vehicle_type && (
                                <span className='field-error'>
                                    {errors.vehicle_type.message}
                                </span>
                            )
                        }
                    </div>
                    {
                        vehicleType === 'container' &&
                        <Input
                            name='container_number'
                            label='Número do container *'
                            value={containerNumber}
                            onChange={(e) => handleContainerNumber(e)}
                            error={errors.container_number}
                            register={register({
                                required: 'Esse campo é obrigatório.',
                            })}
                        />
                    }
                    <div className='form-check'>
                        <label className='label'>Tipo de motorista *</label>
                        <div className='form-check form-row'>
                            <div>
                                <label>
                                    <input name='driver_type' type='radio' style={{ marginRight: '5px' }} value='cliente' checked={driverType === 'cliente'} onChange={handleDriverTypeChange} ref={register({ required: 'Esse campo é obrigatório.' })} />
                                    Cliente
                                 </label>
                            </div>
                            <div className='form-check-radio'>
                                <label>
                                    <input name='driver_type' type='radio' style={{ marginRight: '5px' }} value='transportadora' checked={driverType === 'transportadora'} onChange={handleDriverTypeChange} ref={register({ required: 'Esse campo é obrigatório.' })} />
                                    Transportadora
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                      driverType && driverType == 'cliente' ? (
                        <label className='label'>Nome do Cliente *</label>
                      ) : (
                        <label className='label'>Nome da Transportadora *</label>
                      )
                    }
                    {
                        driverType && driverType == 'cliente' ?
                            (
                                <Input
                                    name='client_name'
                                    label='Nome do Cliente *'
                                    defaultValue=''
                                    error={errors.client_name}
                                    register={register({
                                        required: 'Esse campo é obrigatório.',
                                    })}
                                />
                            ) :
                            (
                                <Input
                                    name='company_name'
                                    label='Nome da Transportadora *'
                                    defaultValue=''
                                    error={errors.company_name}
                                    register={register({
                                        required: 'Esse campo é obrigatório.',
                                    })}
                                />
                            )
                    }
                    <div className='form-check'>
                        <label className='label'>Tipo de visita *</label>
                        <div className='form-check form-row'>
                            <div>
                                <label>
                                    <input
                                        name='visit_type'
                                        type='radio'
                                        value='carga'
                                        style={{ marginRight: '5px' }}
                                        checked={visitationType === 'carga'}
                                        onChange={handleVisitationTypeChange}
                                        ref={register({ required: 'Esse campo é obrigatório.' })}
                                    />
                                    Carga
                                </label>
                            </div>
                            <div className='form-check-radio'>
                                <label>
                                    <input
                                        name='visit_type'
                                        type='radio'
                                        value='descarga'
                                        style={{ marginRight: '5px' }}
                                        checked={visitationType === 'descarga'}
                                        onChange={handleVisitationTypeChange}
                                        ref={register({ required: 'Esse campo é obrigatório.' })}
                                    />
                                    Descarga
                                </label>
                            </div>
                        </div>
                    </div>
                    <label className='label'>DT *</label>
                    <Input
                        name='dt'
                        label='DT *'
                        defaultValue=''
                        error={errors.dt}
                        register={register({
                            required: 'Esse campo é obrigatório.',
                        })}
                    />
                    <label className='label'>Observação *</label>
                    <Input
                        name='note'
                        label='Observação'
                        defaultValue=''
                        error={errors.note}
                        register={register({
                            required: false,
                        })}
                    />
                    <div className='form-inputs'>
                        <div className='buttom-container'>
                            <input className='cancel' type='button' value='Cancelar' onClick={(e)=> clearForm()}/>
                            <input className='registerVisit' type='submit' value='Registrar visita' onClick={(e) => validateFields()}/>
                        </div>
                    </div>
                  </div>
                </div>
            </form>
      </div>
    );
}

export default NewRegister
