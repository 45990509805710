import api from './api'

class DocumentManagementService {
  static searchProviderHiredServiceScopes(page, pageSize, body) {
    return api.post(
      `documents-management/provider/hired-service-scopes/?&page=${page}&limit=${pageSize}`, body,
      { headers: { ...api.getAuthToken() } },
    );
  }

  static getEmailsVivix() {
    return api.get(
      'documents-management/email-users-vivix/',
      { headers: { ...api.getAuthToken() } },
    )
  }

  static getCompanies() {
    return api.get(
      'documents-management/list-companies/',
      { headers: { ...api.getAuthToken() } },
    )
  }

  static getCompanyEmployees(companyID) {
    return api.get(
      `documents-management/employees-company/${companyID}/`,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static postOccurrence(data) {
    return api.post('documents-management/occurrence/', data,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static getProviderDocumentationsRequired() {
    return api.get(
      `documents-management/provider/documentations/required-documents/`,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static postProviderDocumentations(formData, onUploadProgress) {
    return api.post('documents-management/provider/documentations/', formData, {
      headers: { ...api.getAuthToken() },
      onUploadProgress,
    })
  }

  static patchProviderDocumentations(id, formData, onUploadProgress) {
    return api.patch(`documents-management/provider/documentations/${id}/`, formData, {
      headers: { ...api.getAuthToken() },
      onUploadProgress,
    })
  }

  static getProviderEquipamentsDocumentations() {
    return api.get(
      'documents-management/provider/equipments/documentations/',
      { headers: { ...api.getAuthToken() } },
    )
  }

  static removeProviderEquipamentDocumentation(id) {
    return api.delete(
      `documents-management/provider/equipments/documentations/${id}/`,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static postProviderEquipamentDocumentation(body) {
    return api.post(
      'documents-management/provider/equipments/documentations/', body,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static postProviderEmployeesDocumentation(body) {
    return api.post(
      'documents-management/provider/employees/documentation/', body,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static deleteProviderEmployeesDocumentation(id, scopeID) {
    return api.delete(
      `documents-management/provider/employees/documentation/${id}/`,
      {
        headers: {
          'provider-scope-id': scopeID,
          ...api.getAuthToken(),
        }
      },
    )
  }

  static updateProviderEmployeesDocumentation(body, id) {
    return api.put(
      `documents-management/provider/employees/documentation/${id}/`, body,
      { headers: { ...api.getAuthToken() } },
    )
  }
  static postDismissalEmployee(data) {
    return api.post('documents-management/provider/dismissal-employee-provider/', data, {
      headers: { ...api.getAuthToken() },
    })
  }

  static getProviderEmployeesDocumentations(id) {
    return api.get(
      `documents-management/provider/employees/documentation/${id}/provider_scope_service/`,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static getProviderEmployeesDocumentationsSSMA(id) {
    return api.get(
      `documents-management/provider/employees/documentation/${id}/provider_scope_service_ssma/`,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static getProviderEquipmentsDocumentationsToAnalysis(id) {
    return api.get(
      `documents-management/provider/equipments/documentations/?&provider_scope_id=${id}`,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static getCompaniesAndEmployeesDocumentations(page, pageSize, body) {
    return api.post(
      `documents-management/report-companies/?page=${page}&limit=${pageSize}`, body,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static getVisitationReport(page, pageSize, body) {
    return api.post(
      `documents-management/report-drivers/?page=${page}&limit=${pageSize}`, body,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static postSsmaValidateDocument(body) {
    return api.post('documents-management/ssma/validate-documentation/', body, {
      headers: { ...api.getAuthToken() },
    })
  }

  static getProviderDocumentationsToAnalysis(id) {
    return api.get(
      `documents-management/provider/documentations/required-documents/?&provider_scope_id=${id}`,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static downloadDocument(id) {
    return api.get(`documents-management/documentation/${id}/download/`, {
      headers: { ...api.getAuthToken() },
      responseType: 'blob'
    });
  }

  static downloadReportsCSVFile(body) {
    return api.post(
      `documents-management/report-companies/export/csv/`, body,
      {
        headers: { ...api.getAuthToken() },
        responseType: 'blob'
      }
    );
  }

  static downloadReportDriversCSVFile() {
    return api.get(
      `documents-management/report-drivers/export/csv/`,
      {
        headers: { ...api.getAuthToken() },
        responseType: 'blob'
      }
    );
  }

  static updateEmployeeIntegrationDate(employee_provider_scope_id, body) {
    return api.patch(
      `documents-management/provider/employee-provider-scope/${employee_provider_scope_id}/integration-date/`, body,
      { headers: { ...api.getAuthToken() } },
    );
  }

  static getCompaniesAndEmployeesAccesses(page, pageSize, body) {
    return api.post(
      `documents-management/employees-access/?page=${page}&limit=${pageSize}`, body,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static getCompanyAndEmployeesAccesses(id) {
    return api.get(
      `documents-management/employees-access/${id}`,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static updateCompaniesAndEmployeesAccesses(body) {
    return api.post(
      'documents-management/update-employees-access/', body,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static getNewPassword(body) {
    return api.post(
      'documents-management/generate-company-password/', body,
      { headers: { ...api.getAuthToken() } },
    )
  }

  static downloadCSVFile(body) {
    return api.post(
      'documents-management/hired-scopes/export/csv', body, {
      headers: {
        ...api.getAuthToken(),
      },
      responseType: 'blob'
    });
  }
}

export default DocumentManagementService;
