import React, { useState, useEffect } from 'react';

import './styles.scss';

import DriverCard from '../../components/DriverCard';
import VehiclesTable from '../../components/VehiclesTable';
import VisitsTable from '../../components/VisitsTable';

const Driver = ({ match }) => {

  return (
    <section className='driver-page'>
      <div className='row section-wrapper'>
        <div className='driver-section col-md-12 col-lg-4'>
          <DriverCard driverId={ match.params.id } />
        </div>
        <div className='vehicles-section col-md-12 col-lg-8'>
          <VehiclesTable
            fullList={false}
            pageSize={ 3 }
            driverFilter={false}
            driverId={match.params.id}
            onClickRow={false}
          />
        </div>
      </div>
      <div className='row section-wrapper'>
        <div className='vists-section col-sm-12'>
          <VisitsTable
            driverId={ match.params.id }
            hasLink={ false }
            pageSize={ 4 }
            showSearchBar={false}
          />
        </div>
      </div>
    </section>
  );
}

export default Driver
