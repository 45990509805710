import React, { useState, useEffect } from 'react';

import { KeepLoggedLocalStorage } from './helpers';

import './styles.scss';

import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import LogoImage from '../../assets/images/logo.png';
import { UserService } from './../../services';
import Input from '../../components/Input';
import Toast from '../../components/Toast';

const Login = ({ doLogin, setUserInfos }) => {
  const [keepLogged, setKeepLogged] = useState(KeepLoggedLocalStorage.getKeepLogged())

  let history = useHistory();

  const { register, handleSubmit, errors } = useForm();


  const onSubmit = async (data) => {
    const [login, path] = await doLogin(data)
    if(login) {
      Toast.success('Login efetuado com sucesso!');
      history.push(path)
    } else {
      Toast.error('Usuário ou Senha inválida!');
    }
  }

  useEffect(() => {
    LoginADFS();
  }, [])

  useEffect(() => {
    KeepLoggedLocalStorage.setKeepLogged(keepLogged);
  }, [keepLogged])

  const LoginADFS = async () => {
    try {
      let query = new URLSearchParams(location.search);
      const token = query.get("token");
      if (token) {
        const [login, path] = await setUserInfos(token);
        if (login){
          Toast.success('Login efetuado com sucesso!');
          history.push(path);
        }
        
      }
    } catch (error) {
      console.log(error);
    }

  }

  return (
    <section className='login-page'>
      <div className='login-container'>
        <div className='logo-wrapper'>
          <img className='image' src={ 'https://gcb-gotervix-homolog.s3-us-west-2.amazonaws.com/static/images/logo.png' } />
        </div>
        <div className='form-wrapper'>
          <div className='text-container'>
            <h3 className='title'>Seja bem-vindo de volta!</h3>
            <p className='text'>
              Para acessar seu painel, coloque seu e-mail e senha
            </p>
          </div>
          <div className='form-container'>
            <form className='form' onSubmit={handleSubmit(onSubmit)}>
              <div className='input-container'>
                <Input
                  name='username'
                  label='Usuário'
                  defaultValue=''
                  type='username'
                  error={errors.username}
                  register={register({
                    required: 'Esse campo é obrigatório.',
                  })}
                />
                <Input
                  name='password'
                  label='Senha'
                  defaultValue=''
                  type='password'
                  error={errors.password}
                  register={register({
                    required: 'Esse campo é obrigatório.',
                  })}
                />
                <div className='keepLogged'>
                  <input
                    className='checkbox'
                    type='checkbox'
                    id='keepLogged'
                    name='keepLogged'
                    checked={keepLogged}
                    onChange={(event) => setKeepLogged(event.target.checked)}
                  />
                  <label className='label' htmlFor='keepLogged'>Me mantenha logado</label>
                </div>
              </div>
              <div className='submit-container'>
                <input className='submit' type='submit' value='Entrar' />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login
