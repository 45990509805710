import React, { createContext, useState } from 'react';

const AppContext = createContext();

function Provider({ children }) {
  const [filter, setFilter] = useState(
    false
  );
  const [period, setPeriod] = useState(
    false
  );
  const [filterItems, setFilterItems] = useState([]);
  const [changes, setChanges] = useState(() => () => {});


  return (
    <AppContext.Provider
      value={{
        filter,
        setFilter,
        filterItems,
        setFilterItems,
        changes,
        setChanges,
        period,
        setPeriod
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppContext;

export { Provider };
