import React from 'react';

import SSMARequirement from './SSMARequirement';

const SsmaRequirementDocuments = ({
  employee,
  ssmaRequirement,
  scopeId,
  shouldUpdateData,
  setModal,
  setModalTitle,
  setModalBody,
  setModalButtons
}) => {

  let title = '';
  const { name, documents } = ssmaRequirement;

  if (name == 'Documentos obrigatórios') {
    title = name;
  } else {
    title = `Atividade: ${name}`;
  }

  return (
    <div>
      <div className='title'>
        { title }
      </div>
      <div className='divisor' />
      { documents.map((document, index) =>
        <SSMARequirement
          key={index}
          employee={employee}
          ssmaRequirement={ssmaRequirement}
          document={document}
          scopeId={scopeId}
          shouldUpdateData={shouldUpdateData}
          setModal={setModal}
          setModalTitle={setModalTitle}
          setModalBody={setModalBody}
          setModalButtons={setModalButtons}
        />
      )}
    </div>
  )
}

export default SsmaRequirementDocuments;