import React, { useState, useEffect } from 'react';

import { FiDownload } from 'react-icons/fi';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';

import Loading from '../Loading';
import SearchInput from '../SearchInput';
import Toast from '../Toast';

import DocumentManagementService from '../../services/DocumentManagementService';
import { handleNextPrevious, getPages } from '../../helpers/pagination/utils';
import { dateToCSVfilename } from '../../helpers/forms/convert';
import { saveFile } from '../../utils/files';

import './styles.scss';


const DriversReport = () => {
  const pageSize = 10;
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getTableData();
  }, [currentPage, searchText])

  const getTableData = async () => {
    let body = {
      search: searchText
    };

    try {
      const { data } = await DocumentManagementService.getVisitationReport(currentPage, pageSize, body);
      setData(data);
    } catch(error) {
      const {response: { data }} = error;
      console.error(error);
      Toast.error('Não foi possível obter os dados do relatório de motoristas terceiros')
    }
  }

  const handleCSVButton = async () => {
    try {
      Toast.warning('Gerando CSV das visitas dos motoristas');
      const response = await DocumentManagementService.downloadReportDriversCSVFile();
      Toast.hide();

      let filename = `relatorio-motoristas-${dateToCSVfilename(new Date)}.csv`
      await saveFile(response, filename);
      Toast.success('CSV gerado com sucesso');
    } catch (error) {
      console.error(error);
      let errorData = error?.response?.data;
      if (errorData?.message) Toast.error(errorData.message);
    }
  }

  const DriversTable = ({ tableData }) => {
    return(
      <table className='table table-striped table-borderless'>
        <thead>
          <tr>
            <th scope='col'>Motorista</th>
            <th scope='col'>Status</th>
            <th scope='col'>CPF</th>
            <th scope='col'>Placa do veículo</th>
            <th scope='col'>Veículo</th>
            <th scope='col'>Tempo de estadia na fábrica</th>
          </tr>
        </thead>
        <tbody>
          {
            tableData && tableData.map((visitation, index) => (
              <tr key={`visitation-${index}`} className='fade-in'>
                <td>{visitation.name}</td>
                <td>{visitation.status}</td>
                <td>{visitation.cpf}</td>
                <td>{visitation.license_plate}</td>
                <td>{visitation.vehicle_type}</td>
                <td>{visitation.visiting_time}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    )
  }

  return(
    <section className='drivers-report-table-page'>
      <div className='table-wrapper'>
        {
          data ? (
            <section className='drivers-table fade-in'>
              <div className='header'>
                <div className='title'>
                  MOTORISTAS TERCEIROS
                </div>
                <div className='right' onClick={ handleCSVButton } >
                  <FiDownload
                    size={22}
                    className='icon'
                  />
                  <p>Download da planilha</p>
                </div>
                <SearchInput
                  setValue={ setSearchText }
                  placeholder={'Buscar por nome ou cpf do motorista'}
                />
              </div>
              <div className='table-container'>
                <DriversTable tableData={data.results}/>
              </div>
              <div className='paginator-container'>
                <nav>
                  <ul className='pagination'>
                    <li className={`page-item ${data.previous ? '' : 'disabled'}`}>
                      <a
                        className='page-link'
                        onClick={() => handleNextPrevious(data.previous, setCurrentPage)}
                        >
                          <div className='item-right'>
                            <TiChevronLeft />
                            <p>Anterior</p>
                          </div>
                      </a>
                    </li>
                    {
                      getPages(data.count, pageSize) && getPages(data.count, pageSize).map(page => (
                        <li
                          key={page}
                          onClick={() => setCurrentPage(page)}
                          className={`page-item ${page == currentPage ? 'active' : ''} `}>
                          <a className='page-link'>{ page }</a>
                        </li>
                      ))
                    }
                    <li className={`page-item ${data.next ? '' : 'disabled'}`}>
                      <a
                        className='page-link'
                        onClick={() => handleNextPrevious(data.next, setCurrentPage)}
                        >
                          <div className='item-right'>
                              <p>Próximo </p>
                              <TiChevronRight />
                          </div>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </section>
          ) : (
            <div className='load-container'>
              <Loading/>
            </div>
          )
        }
      </div>
    </section>
  )
}

export default DriversReport;
