import api from './api'

class ProviderService {
  static addCollaborator(payload) {
    return api.post(
      'documents-management/provider/employee-provider-scope/', payload,
      { headers: { ...api.getAuthToken() }},
    );
  }

  static updateCollaborator(payload, id) {
    return api.put(
      `documents-management/provider/employee-provider-scope/${id}/`, payload,
      { headers: { ...api.getAuthToken() }},
    );
  }

  static getHiredScopeData(id) {
    return api.get(
      `documents-management/provider/hired-service-scopes/${id}/`,
      { headers: { ...api.getAuthToken() }},
    );
  }

  static listCollaborators(id) {
    return api.get(
      `documents-management/provider/employee-provider-scope/?provider_scope_service_id=${id}`,
      { headers: { ...api.getAuthToken() }},
    );
  }
}

export default ProviderService;
