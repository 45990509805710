import React, { useState, useContext } from 'react';
import AuthContext from '../../utils/auth';
import {
  FaFile, FaUserCheck, FaLink,
  FaTruckMoving, FaFolderOpen, FaTasks,
} from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GrFormClose } from 'react-icons/gr';
import { IoMdAlert, IoIosPeople } from 'react-icons/io';
import { MdInsertChart, MdList } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import Modal from '../../components/Modal';
import PreRegistrationLink from '../../components/PreRegistrationLink';
import LinkProviderModal from '../../components/LinkProviderModal';
import FilterContext from './context';
import TableFilter from '../TableFilter';
import TablePeriodFilter from '../TablePeriodFilter';
import { isFaturista, isEmployee, isAdmin, isSSMA, isOnlyProvider, isOnlySSMA } from '../../utils/auth';
import ProviderModal from '../LinkProviderModal/ProviderModal';
import ConfirmLinkProviderModal from '../LinkProviderModal/ConfirmLinkProviderModal';
import './styles.scss';

const SideNav = ({ handleIsExpanded, sideNavWidth, isExpanded }) => {
  const [preRegisterLink, setPreRegisterLink] = useState(false);
  const [linkProviderModal, setLinkProviderModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmLinkProviderModal, setConfirmLinkProviderModal] = useState(false);
  const [infoProviderScope, setInfoProviderScope] = useState(null)
  const [linkProviderScope, setLinkProviderScope] = useState(null)

  const userInfo = useContext(AuthContext);

  const { filter, filterItems, changes, setPeriod, period } = useContext(FilterContext)
  const handlePeriod = (period) => {
    setPeriod(period)
    setCurrentPage(1)
  }

  return (
    <section
      style={{ width: `${sideNavWidth}` }}
      className='side-nav'
    >
      <div className='image-container'>
        <img className='image' src={'https://gcb-gotervix-homolog.s3-us-west-2.amazonaws.com/static/images/logo.png'} />
      </div>
      <div className='nav-section-container'>
        {
          isEmployee(userInfo.roles) && (
            <>
              <div className='nav-section'>
                {
                  isExpanded && (
                    <div className={`title fade-in ${isExpanded ? 'expanded' : ''}`}>
                      ESCOPO DE SERVIÇO
                    </div>
                  )
                }
                <div className='main-button-container'>
                  <NavLink
                    to='/colaborador/novo-escopo-de-servico'
                    className={`main-button ${isExpanded ? 'expanded' : ''}`}>
                    <img src={"https://gcb-gotervix-homolog.s3-us-west-2.amazonaws.com/static/js/assets/images/add-buttom.png"} />
                    {
                      isExpanded
                        ? <span className='fade-in fade-in-1s'>Novo escopo</span>
                        : null
                    }
                  </NavLink>
                </div>
                <div className='itens-container'>
                  <NavLink
                    className={`item ${isExpanded ? 'expanded' : ''}`}
                    to='/colaborador/escopos-de-servico'
                  >
                    <MdList className={`icon ${isExpanded ? 'expanded' : ''}`} />
                    {
                      isExpanded
                        ? <span className='text fade-in fade-in-1s'>Escopos de serviço</span>
                        : null
                    }
                  </NavLink>
                </div>
              </div>
              <div className='nav-section'>
                {
                  isExpanded && (
                    <div className={`title fade-in ${isExpanded ? 'expanded' : ''}`}>
                      COLABORADORES
                    </div>
                  )
                }
                <div className='itens-container'>
                  <NavLink
                    className={`item ${isExpanded ? 'expanded' : ''}`}
                    to='/colaborador/escopos-contratados/todos'
                  >
                    <FaFolderOpen className={`icon ${isExpanded ? 'expanded' : ''}`} />
                    {
                      isExpanded
                        ? <span className='text fade-in fade-in-1s'>Escopos contratados</span>
                        : null
                    }
                  </NavLink>
                  <NavLink
                    className={`item ${isExpanded ? 'expanded' : ''}`}
                    to='/colaborador/registrar-ocorrencia'
                  >
                    <IoMdAlert className={`icon ${isExpanded ? 'expanded' : ''}`} />
                    {
                      isExpanded
                        ? <span className='text fade-in fade-in-1s'>Registrar ocorrência</span>
                        : null
                    }
                  </NavLink>
                  <NavLink
                    className={`item ${isExpanded ? 'expanded' : ''}`}
                    to='/relatorios'
                  >
                    <MdInsertChart className={`icon ${isExpanded ? 'expanded' : ''}`} />
                    {
                      isExpanded
                        ? <span className='text fade-in fade-in-1s'>Relatórios</span>
                        : null
                    }
                  </NavLink>
                </div>
              </div>
            </>
          )
        }
        {
          isFaturista(userInfo.roles) && (
            <div className='nav-section'>
              {
                isExpanded && (
                  <div className={`title fade-in ${isExpanded ? 'expanded' : ''}`}>
                    MOTORISTA
                  </div>
                )
              }
              <div className='main-button-container'>
                <NavLink
                  to='/faturista/novo-cadastro'
                  className={`main-button ${isExpanded ? 'expanded' : ''}`}>
                  <img src={"https://gcb-gotervix-homolog.s3-us-west-2.amazonaws.com/static/js/assets/images/add-buttom.png"} />
                  {
                    isExpanded
                      ? <span className='fade-in fade-in-1s'>Novo cadastro</span>
                      : null
                  }
                </NavLink>
              </div>
              <div className='itens-container'>
                <NavLink
                  className={`item ${isExpanded ? 'expanded' : ''}`}
                  to='/faturista/pre-cadastros'
                >
                  <FaUserCheck className={`icon ${isExpanded ? 'expanded' : ''}`} />
                  {
                    isExpanded
                      ? <span className='text fade-in fade-in-1s'>Pré-cadastros</span>
                      : null
                  }

                </NavLink>
                <NavLink
                  className={`item ${isExpanded ? 'expanded' : ''}`}
                  to='/faturista/historico-de-visitas'
                >
                  <FaTruckMoving className={`icon ${isExpanded ? 'expanded' : ''}`} />
                  {
                    isExpanded
                      ? <span className='text fade-in fade-in-1s'>Histórico de visitas</span>
                      : null
                  }
                </NavLink>

                <div
                  className={`item ${isExpanded ? 'expanded' : ''}`}
                  onClick={() => setPreRegisterLink(true)}
                >
                  <FaLink className={`icon ${isExpanded ? 'expanded' : ''}`} />
                  {
                    isExpanded
                      ? <span className='text fade-in fade-in-1s'>Gerar link de pré-cadastro</span>
                      : null
                  }
                </div>
              </div>
            </div>
          )
        }
        {
          isSSMA(userInfo.roles) && (
            <>
              <div className='nav-filter'>
                {isOnlySSMA(userInfo.roles) && filter && (
                  <TableFilter
                    data={filterItems}
                    handleChange={(value) => changes(value)}
                  />
                )}
                {isOnlySSMA(userInfo.roles) &&
                  period && (
                    <TablePeriodFilter
                      data={filterItems}
                      handlePeriod={handlePeriod}
                    />
                  )
                }
              </div>
              <div className='nav-section'>
                {
                  isExpanded && (
                    <div className={`title fade-in ${isExpanded ? 'expanded' : ''}`}>
                      SSMA
                    </div>
                  )
                }
                <div className='itens-container'>
                  <NavLink
                    className={`item ${isExpanded ? 'expanded' : ''}`}
                    to='/ssma/documentacoes'
                  >
                    <FaFile className={`icon ${isExpanded ? 'expanded' : ''}`} />
                    {
                      isExpanded
                        ? <span className='text fade-in fade-in-1s'>Análise de documentações</span>
                        : null
                    }
                  </NavLink>
                </div>
              </div>
            </>
          )
        }
        {
          isOnlyProvider(userInfo.roles) && (
            <>
              <div className='nav-filter'>
                {filter && (
                  <TableFilter
                    data={filterItems}
                    handleChange={(value) => changes(value)}
                  />
                )}

              </div>
              <div className='nav-section'>
                {
                  isExpanded && (
                    <div className={`title fade-in ${isExpanded ? 'expanded' : ''}`}>
                      FORNECEDOR
                    </div>
                  )
                }
                <div className='main-button-container'>
                  <div
                    onClick={() => setLinkProviderModal(true)}
                    className={`main-button ${isExpanded ? 'expanded' : ''}`}>
                    <img src={"https://gcb-gotervix-homolog.s3-us-west-2.amazonaws.com/static/js/assets/images/add-buttom.png"} />
                    {
                      isExpanded
                        ? <span className='fade-in fade-in-1s'>Vincular Escopo</span>
                        : null
                    }
                  </div>
                </div>
                <div className='itens-container'>
                  <NavLink
                    className={`item ${isExpanded ? 'expanded' : ''}`}
                    to='/fornecedor/documentos'
                  >
                    <FaFile className={`icon ${isExpanded ? 'expanded' : ''}`} />
                    {
                      isExpanded
                        ? <span className='text fade-in fade-in-1s'>Documentações da empresa</span>
                        : null
                    }
                  </NavLink>
                  <NavLink
                    className={`item ${isExpanded ? 'expanded' : ''}`}
                    to='/fornecedor/escopos-contratados'
                  >
                    <FaTasks className={`icon ${isExpanded ? 'expanded' : ''}`} />
                    {
                      isExpanded
                        ? <span className='text fade-in fade-in-1s'>Escopos contratados</span>
                        : null
                    }
                  </NavLink>
                </div>
              </div>
            </>
          )
        }
        {
          isAdmin(userInfo.roles) && (
            <div className='nav-section'>
              {
                isExpanded && (
                  <div className={`title fade-in ${isExpanded ? 'expanded' : ''}`}>
                    ADMINISTRADOR
                  </div>
                )
              }
              <div className='itens-container'>
                <NavLink
                  className={`item ${isExpanded ? 'expanded' : ''}`}
                  to='/admin'
                >
                  <IoIosPeople className={`icon ${isExpanded ? 'expanded' : ''}`} />
                  {
                    isExpanded
                      ? <span className='text fade-in fade-in-1s'>Painel administrativo</span>
                      : null
                  }
                </NavLink>
              </div>
            </div>
          )
        }
      </div>
      <div className={`hamburguer-menu ${isExpanded ? 'expanded' : ''}`}>
        <button className='button' onClick={handleIsExpanded}>
          {
            isExpanded
              ? <GrFormClose className='arrow fade-in' />
              : <GiHamburgerMenu className='fade-in' />
          }
        </button>
      </div>
      <Modal
        active={preRegisterLink}
        disableFunction={setPreRegisterLink}
        disableWhenClickAway={true}
        fixed={true}
      >
        <PreRegistrationLink setModal={setPreRegisterLink} />
      </Modal>
      <ProviderModal
        active={linkProviderModal}
        disableFunction={setLinkProviderModal}
        disableWhenClickAway={true}
        fixed={true}
      > {
          confirmLinkProviderModal ?
            <ConfirmLinkProviderModal setModal={setConfirmLinkProviderModal} infoProviderScope={infoProviderScope} linkProviderScope={linkProviderScope} />
            :
            <LinkProviderModal setModal={setLinkProviderModal} setConfirmModal={setConfirmLinkProviderModal} infoProviderScope={infoProviderScope} setInfoProviderScope={setInfoProviderScope} setLinkProviderScope={setLinkProviderScope} />
        }
      </ProviderModal>
    </section>
  );
}

export default SideNav
