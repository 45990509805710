import Login from './Login';
import PreRegistration from './PreRegistration';
import Register from './Register';
import VisitHistory from './VisitHistory';
import Driver from './Driver';
import NewRegister from './NewRegister';
import NewEscopoDeServico from './NewEscopoDeServico';
import ServiceScopeTable from './ServiceScopeTable';
import ScopeCollaborators from './ScopeCollaborators';
import SSMAPage from './SSMAPage';
import SSMADocumentation from './ssma/SSMADocumentation';
import SSMAValidateDocuments from './ssma/ValidateDocuments';
import RegisterOccurrence from './RegisterOccurrence';
import RobotUpload from './RobotUpload';
import FirstAccess from './FirstAccess';
import ProviderDocuments from './ProviderDocuments';
import ProviderHiredScopesTable from './ProviderHiredScopesTable';
import HiredScopes from './HiredScopes';
import Reports from './Reports';
import HiredScopeDetail from './HiredScopeDetail';

export {
  Login,
  Register,
  PreRegistration,
  VisitHistory,
  Driver,
  NewRegister,
  NewEscopoDeServico,
  ServiceScopeTable,
  ScopeCollaborators,
  SSMAPage,
  SSMADocumentation,
  SSMAValidateDocuments,
  RegisterOccurrence,
  RobotUpload,
  FirstAccess,
  ProviderDocuments,
  ProviderHiredScopesTable,
  HiredScopes,
  Reports,
  HiredScopeDetail
};
