import React, { useState, useEffect, useRef } from 'react';

import './styles.scss';

import Toast from '../Toast';
import { CoreService } from '../../services';


const PreRegistrationLink = ({setModal}) => {
  const [canCopy, setCanCopy] = useState();
  const [tempUrl, setTempUrl] = useState('');


  const inputRef = useRef(null);

  useEffect(() => {
    setCanCopy(document.queryCommandSupported('copy'));
    createTemporaryLink();
  }, [])

  const copyToClipboard = (e) => {
    inputRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    Toast.success('Link copiado com sucesso!')
  };

  const createTemporaryLink = async () => {
    const { data } = await CoreService.createPreRegisterUrl();
    setTempUrl(data.url);
  }

  return (
    <div className='pre-registration-link'>
      <div className='header'>
        Link de pré-cadastro
      </div>
      <div className='body'>
        <input
          ref={inputRef}
          className={`input ${canCopy ? '' : 'cant-copy'}`}
          type='text'
          readOnly
          value={tempUrl}
        />
      {
        canCopy && (
          <button
            className='button'
            onClick={ copyToClipboard }
          >
            Copiar link
          </button>
        )
      }
      </div>
      <div className='footer'>
        <button
          onClick={() => setModal(false)}
          className='btn cancel'
        >
          Cancelar
        </button>
        <button
          onClick={() => setModal(false)}
          className='btn ok'
        >
          OK
        </button>
      </div>
    </div>
  );
}

export default PreRegistrationLink
