import React, { useState, useEffect, useRef } from 'react';

import CalendarInput from '../../../components/CalendarInput';
import Toast from '../../../components/Toast';
import SupportedExtensionsInfo from '../../../components/SupportedExtensionsInfo';

import { FaTrash } from 'react-icons/fa';
import { BsPaperclip, BsUpload } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';

import { stringToDate, dateToString } from '../../../helpers/forms/convert';
import { filepathToBasename } from '../../../helpers/forms/parse';
import { DocumentManagementService } from '../../../services';
import { downloadDocumentFile, isFileExtensionSupported } from '../../../utils/files';

const SSMARequirement = ({
  employee,
  document,
  scopeId,
  shouldUpdateData,
  setModal,
  setModalTitle,
  setModalBody,
  setModalButtons
}) => {

  const [ssmaRequirementDocuments, setSsmaRequirementDocuments] = useState([]);
  const [newDocumentFile, setNewDocumentFile] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [newDocumentExpireDate, setNewDocumentExpireDate] = useState('');
  const [hasSubmited, sethasSubmited] = useState(false);
  const [statusColor, setStatusColor] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const [showSupportedExtensions, setShowSupportedExtensions] = useState(false);
  const attachFile = useRef(null);

  useEffect(() => {
    getDocument();
  }, [employee]);

  useEffect(() => {
    if (!newDocumentFile?.status || newDocumentFile?.status == 'waiting') setStatusColor('file-uploaded-waiting')
    else if (newDocumentFile.status == 'not_according') setStatusColor('file-uploaded-not-according')
    else if (newDocumentFile.status == 'not_applicable') setStatusColor('file-uploaded-not-applicable')
    else if (newDocumentFile.status == 'according') setStatusColor('file-uploaded-according')
  }, [newDocumentFile]);

  const refreshData = () => {
    shouldUpdateData();
  }

  const fileDrop = (event) => {
    event.preventDefault();
    const dragFile = event.dataTransfer.files;
    handleFile(dragFile);
  }

  const handleFileChange = (event) => {
    const inputFile = event.target.files;
    handleFile(inputFile);
  }

  const handleFile = (addFile) => {
    if (addFile.length) {
      const file = addFile[0];

      if (!isFileExtensionSupported(file)) {
        setShowSupportedExtensions(true);
        return;
      }

      file.id = `${(new Date()).getTime()}-${0}`;
      file.link = URL.createObjectURL(file);
      file.file_name = file.name.replace(/\s/g, '_');

      setShowSupportedExtensions(false);
      setNewDocumentFile(file);
      setIsChanged(true);
    }
  }

  const getDocumentationModalButtons = () => {
    const buttons = [
      {
        className: 'cancel',
        label: 'Cancelar',
        onClick: function () {
          setModal(false);
        }
      },
      {
        className: 'confirm',
        label: 'Confirmar',
        onClick: function () {
          removeDocumentFile();
          setModal(false);
          refreshData();
        }
      }
    ];

    return buttons;

  }

  const showDeleteDocumentModal = (e) => {
    if (!documentId) {
      Toast.error('Este documento não foi submetido');
      return;
    }

    e.preventDefault();

    const buttons = getDocumentationModalButtons();
    setModalTitle('Confirmação de remoção de documento de colaboradores');
    setModalBody(`Tem certeza que deseja remover o arquivo referente ao documento "${document.name}" do colaborador "${employee.name}"?`);
    setModalButtons(buttons);
    setModal(true);
  }

  const removeDocumentFile = async () => {
    if (documentId) {
      try {
        Toast.warning('Removendo documento');
        await DocumentManagementService.deleteProviderEmployeesDocumentation(documentId, scopeId);
        setNewDocumentExpireDate('');
        setNewDocumentFile(null);
        setDocumentId(null);
        sethasSubmited(false);
        refreshData();
        Toast.hide();
        Toast.success("Documento removido com sucesso");
      } catch (error) {
        console.log("removeDocumentFile", error);
      }
    }
  }

  const getDocument = async () => {
    employee.documents.map((documentEmployee) => {
      if (documentEmployee.document_type == document.id) {
        setNewDocumentExpireDate(documentEmployee.expires_at);
        let file = {};
        file.id = documentEmployee.id;
        file.file_name = filepathToBasename(documentEmployee.file)
        file.status = documentEmployee.status;
        file.reason = documentEmployee.reason;
        setNewDocumentFile(file);
        setDocumentId(documentEmployee.id);
        sethasSubmited(true);
      }
    })
  }

  const updateDocument = async () => {
    if (!isChanged) {
      Toast.error('Este documento não sofreu alterações');
      return;
    }

    if (!newDocumentFile || !newDocumentExpireDate) return;

    const formData = new FormData();

    if (newDocumentFile.link) {
      formData.append('file', newDocumentFile);
    }

    formData.append('expires_at', newDocumentExpireDate);
    formData.append('scope_service_id', scopeId);

    try {
      Toast.warning('Upload do arquivo em progresso');

      await DocumentManagementService.updateProviderEmployeesDocumentation(formData, documentId);

      Toast.hide();
      Toast.success("Documento atualizado com sucesso");

      refreshData();
      setIsChanged(false);

    } catch (error) {
      const { response: { data } } = error;

      Object.keys(data).map(function (field) {
        let error_message = field == 'file' ? data[field][0] : data[field]
        Toast.error(error_message);
      });
    }
  }

  const sendNewDocument = async (event) => {
    event.preventDefault();

    if (!isChanged) {
      Toast.error('Este documento não sofreu alterações');
      return;
    } else if (!newDocumentFile) {
      Toast.error('Insira um documento válido no formato de imagem ou PDF');
      return;
    } else if (!newDocumentExpireDate && document.mandatory_expires_at) {
      Toast.error('Digite uma data de validade');
      return;
    }

    if (hasSubmited) {
      updateDocument();
      return;
    }

    const formData = new FormData();
    formData.append('file', newDocumentFile);
    formData.append('expires_at', newDocumentExpireDate);
    formData.append('employee_provider', employee.id);
    formData.append('scope_service_id', scopeId);
    formData.append('document_type', document.id);

    try {
      Toast.warning('Upload do arquivo em progresso')
      const { data } = await DocumentManagementService.postProviderEmployeesDocumentation(formData);
      Toast.hide()
      Toast.success('Documento submetido com sucesso')
      ssmaRequirementDocuments.push(data);
      setSsmaRequirementDocuments(ssmaRequirementDocuments);
      setDocumentId(data.id)
      newDocumentFile.file_name = filepathToBasename(data.file)
      refreshData();
      setIsChanged(false);
      sethasSubmited(true);
    } catch (error) {
      if (error?.response) {
        const { response: { data, status } } = error;
        if ([400, 401, 403].includes(status)) {
          Object.keys(data).map(function (field) {
            let error_message = field == 'file' ? data[field][0] : data[field];
            Toast.error(error_message);
          });
        } else {
          Toast.error('Não foi possível realizar a operação. Erro no servidor!');
        }
      } else {
        Toast.error('Não foi possível realizar a operação. Verifique sua conexão!');
      }
    }
  }

  const handleFileDownload = async (documentId) => {
    if (!documentId) return;
    Toast.warning(`Iniciando download do documento`);
    const { status, message } = await downloadDocumentFile(documentId);
    Toast.hide()
    if (status == 200) {
      Toast.success(message);
      return;
    }
    Toast.error(message);
  }

  const showClearDocumentModal = (e) => {
    e.preventDefault();
    let buttons = getDocumentationModalButtons();
    let confirmButton = buttons[1]
    confirmButton.onClick = function () {
      setNewDocumentFile(null);
      setModal(false);
      setIsChanged(true);
    }
    buttons[1] = confirmButton;
    setModalTitle('Confirmação de remoção de documento de colaboradores');
    setModalBody(`Tem certeza que deseja remover o arquivo referente ao documento "${document.name}" do colaborador "${employee.name}"?`);
    setModalButtons(buttons);
    setModal(true);
  }

  const handleDateChange = (date) => {
    setNewDocumentExpireDate(dateToString(date));
    setIsChanged(true);
  }

  return (
    <div className='documentation'>
      {
        <div className='item'>
          <form className='form'>
            <div className='row'>
              <div className='column col-md-12 col-lg-6'>
                <label className='label'>{document.name}*</label>
                <div className='input-container'>
                  {
                    newDocumentFile
                      ?
                      <div className={`file-uploaded ${statusColor}`}>
                        <div className='left' onClick={(e) => handleFileDownload(documentId)}>
                          <BsPaperclip className='clip-icon' />
                          <span>{newDocumentFile.file_name}</span>
                        </div>
                        <div className='right'>
                          <FaTrash className={`trash-icon trash-icon-file`} onClick={(e) => showClearDocumentModal(e)} />
                        </div>
                      </div>
                      :
                      <>
                        <div className='file-upload'>
                          <input
                            type='file'
                            ref={attachFile}
                            onChange={handleFileChange}
                            hidden
                          />
                          <div
                            className='drop-zone'
                            onDragOver={(event) => event.preventDefault()}
                            onDragEnter={(event) => event.preventDefault()}
                            onDragLeave={(event) => event.preventDefault()}
                            onDrop={fileDrop}
                            onClick={() => attachFile.current.click()}
                          >

                            <span className='send-file'>
                              <BsUpload className='icon' />
                              Clique ou solte um documento para carregar o arquivo
                            </span>
                          </div>
                        </div>
                        {
                          showSupportedExtensions && <SupportedExtensionsInfo />
                        }
                      </>
                  }
                </div>
              </div>
              <div className='column col-md-12 col-lg-6'>
                <label className='label'>DATA DE VALIDADE{document.mandatory_expires_at ? '*' : ''} </label>
                <CalendarInput
                  name='equipament_expires_at'
                  className='date-input'
                  minDate={new Date()}
                  initialValue={newDocumentExpireDate ? stringToDate(newDocumentExpireDate) : ''}
                  onChange={(date) => handleDateChange(date)}
                />
              </div>
              {
                newDocumentFile?.reason &&
                <div className='document-analysis'>
                  <label className='label reason-title'>MOTIVO DA ANÁLISE</label>
                  <div className='reason-content'>
                    <span>{newDocumentFile.reason}</span>
                  </div>
                </div>
              }
            </div>
            <div className='document-actions'>
              <div
                className='save-file'
              >
                <div className="tooltips">
                  {
                    (!documentId || isChanged) && (
                      <div className='send-document' onClick={(e) => sendNewDocument(e)} >
                        <FaCheck />
                        <p>Enviar arquivo</p>
                      </div>
                    )
                  }
                </div>
              </div>
              <div
                className='delete-file'
                onClick={(e) => showDeleteDocumentModal(e)}
              >
                <FaTrash />
                <p>Limpar todos os campos</p>
              </div>
            </div>
          </form>
        </div>
      }
    </div>
  )
}

export default SSMARequirement;