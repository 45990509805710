import React, { useRef, useEffect, useState } from 'react'
import { format, addDays, isBefore } from 'date-fns'

import { FaSpinner, FaDownload } from 'react-icons/fa'

import { AiFillCalendar } from 'react-icons/ai'

import { BsPaperclip } from 'react-icons/bs'

import { DocumentManagementService } from '../../services'

import SimpleEvent from './../../helpers/SimpleEvent'

import CalendarInput from '../../components/CalendarInput'

import './styles.scss'
import ConfirmationModal from '../../components/ConfirmationModal'
import Modal from '../../components/Modal'
import Toast from '../../components/Toast'
import { downloadDocumentFile } from '../../utils/files'
import { ALLOWED_FILES } from '../../constants'

const simpleEvent = new SimpleEvent()

const Document = ({ document, docEvent, modalBody, setModalBody, setShouldShowModal, setShouldUpdateDoc }) => {
  const progressRef = useRef()
  const docRef = useRef()
  const [docID, setDocID] = useState(null)
  const [urlFile, setUrlFile] = useState('')
  const [file, setFile] = useState(null)
  const [date, setDate] = useState(null)
  const [saving, setSaving] = useState(false)
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [fieldError, setFieldError] = useState({
    date: null,
    file: null,
  })
  const [statusColor, setStatusColor] = useState('');

  useEffect(() => {
    setStatusColor(getStatusColor(document?.status))
  }, [document])

  useEffect(() => {
    $('.date-input').mask('00/00/0000')
    const subscribe = docEvent.subscribe('get-documentations', (isSave) => setSaving(isSaveing => {
      if (!isSaveing) return true
      return isSave
    }))
    return () => docEvent.unsubscribe(subscribe)
  }, [])

  useEffect(() => {
    if (shouldUpdate) {
      let documentName = `"${document?.document_type_name || document?.name}"`
      let modalMessage = 'Tem certeza que deseja atualizar o arquivo referente ao documento de'
      if (modalBody && !modalBody.includes(documentName)) {
        modalMessage = 'Tem certeza que deseja atualizar os arquivos referentes aos documentos de'
        documentName = `"${modalBody.split('"')[1]}" e ${documentName}`
      }
      setShouldShowModal(true)
      setModalBody(modalMessage + ' ' + `${documentName}?`)
    }
  }, [shouldUpdate])

  useEffect(() => {
    if (document.id) {
      try {
        const docDate = new Date(
          new Date(document.expires_at?.split('/').reverse().join('-')).setUTCHours(3)
        )

        setDate(docDate)
        setDocID(document.id)
        setUrlFile(document.file)
      } catch (e) {
      }
    }
  }, [document])

  useEffect(() => {
    if (saving) validateDocument()
  }, [saving])

  const dragOver = (e) => e.preventDefault()
  const dragEnter = (e) => e.preventDefault()
  const dragLeave = (e) => e.preventDefault()
  const fileDrop = (e) => {
    e.preventDefault()
    const [dragFile] = e.dataTransfer.files
    handleFile(dragFile)
  }

  const handleFileChange = (e) => {
    const [inputFile] = e.target.files
    handleFile(inputFile)
    setShouldUpdate(true)
  }

  const handleFile = (f) => {
    if (f) {
      const fError = validateFile(f)
      setFieldError(fErr => ({ ...fErr, file: fError }))
      if (!fError) setFile(f)
    }
  }

  const handleDateChange = (d) => {
    if (d) {
      const dError = validateDate(d)
      setFieldError(fErr => ({ ...fErr, date: dError }))
      if (!dError) {
        setDate(d)
        setShouldUpdate(true)
      }
    } else {
      setDate(null)
    }
  }

  const validateDate = (d) => {
    if (!d) {
      return 'Informe a data de validade.'
    } else {
      if (isBefore(d, (new Date))) {
        return 'A data de validade tem que ser maior do que a de hoje.'
      }
    }

    return null
  }

  const validateFile = (f) => {
    if (!f) {
      return 'Insira um arquivo para upload.'
    } else {
      const fileExt = String(f.name.split('.').pop()).toLowerCase()
      if (!ALLOWED_FILES.includes(fileExt)) {
        return `Insira um arquivo válido! Arquivos válidos: ${ALLOWED_FILES.join(', ')}.`
      }
    }

    return null
  }

  const validateDocument = () => {
    try {
      const error = {}
      if (!date && !file) {
        setSaving(false);
        return null;
      }

      error.date = validateDate(date)
      if (!docID) error.file = validateFile(file)

      if (!error.date && !error.file) {
        saveFiles()
      } else {
        setFieldError(error)
        setSaving(false)
      }
    } catch (error) {
      setSaving(false)
      console.log(error)
    }
  }

  const saveFiles = async () => {
    const progress = progressRef.current

    if (!shouldUpdate) {
      progress.classList.add('progress')
      progress.classList.add('progress-done')
      setSaving(false)
      return;
    }

    try {
      const formData = new FormData()
      formData.append('document_type', document.document_id)
      formData.append('expires_at', format(date, 'dd/MM/yyyy'))
      if (file) formData.append('file', file)

      const progressUploadHandle = (progressEvent) => {
        if (progress) {
          const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          progress.style.width = `${uploadPercentage}%`
          progress.classList.add('progress-uploading')
        }
      }

      const handleResponse = data => {
        progress.classList.remove('progress-uploading')
        progress.classList.add('progress-done')
        progress.style.width = ''

        setDocID(data.id)
        setUrlFile(data.file)

        setFile(null)
        setFieldError({})
        setSaving(false)
      }

      if (docID) {
        const { data } = await DocumentManagementService.patchProviderDocumentations(
          docID,
          formData,
          progressUploadHandle,
        )
        handleResponse(data)
        Toast.success('Documentação atualizada com sucesso.')
      } else {
        const { data } = await DocumentManagementService.postProviderDocumentations(
          formData,
          progressUploadHandle,
        )
        handleResponse(data)
        Toast.success('Documentação salva com sucesso.')
      }
      setShouldUpdate(false);
      setShouldShowModal(false);
      setShouldUpdateDoc(true);
      setModalBody('');
    } catch (error) {
      console.log('error saveFiles')
      console.log(error?.response ? error.response : error)
      Toast.error(error?.response ? error.response : error)
      progress.style.width = ''
      progress.classList.remove('progress-uploading')
      progress.classList.remove('progress-done')
      setSaving(false)
    }
  }

  const getStatusColor = (status) => {
    if (!status) return 'waiting';
    const classes = {
      'waiting': 'waiting',
      'according': 'according',
      'not_according': 'not-according',
      'not_applicable': 'not-applicable-color'
    }
    if (!Object.keys(classes).includes(status)) {
      throw new Error('Status do documento está incorreto')
    }
    return classes[status]
  }

  const handleFileDownload = async (documentId) => {
    if (!documentId) return;
    Toast.warning(`Iniciando download do documento`);
    const { status, message } = await downloadDocumentFile(documentId);
    Toast.hide()
    if (status == 200) {
      Toast.success(message);
      return;
    }
    Toast.error(message);
  }

  return (
    <div className="document">
      <div className="fields">
        <div className="field-container">
          <div className="field-label">{document?.name}*</div>
          <input
            ref={docRef}
            type="file"
            onChange={handleFileChange}
            hidden
          />
          <div className="drop-zone-container">
            <div
              ref={progressRef}
              className={`progress ${!file && !!urlFile ? 'progress-done' : ''} ${statusColor}`}
            >
            </div>
            <div
              className={`drop-zone ${!file && !!urlFile ? 'file-uploaded' : ''}`}
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
              onClick={() => docRef.current.click()}
            >
              {
                !file && !urlFile ? (
                  <img className="icon-upload" src={"https://gcb-gotervix-homolog.s3-us-west-2.amazonaws.com/static/js/assets/icons/icon-upload.svg"} />
                ) : (
                  <img className="icon-attachment" src={"https://gcb-gotervix-homolog.s3-us-west-2.amazonaws.com/static/js/assets/icons/icon-attachment.svg"} />
                )
              }
              {
                !file && !urlFile ? (
                  <span>Clique ou solte um documento para carrega o arquivo</span>

                ) : (
                  <span className={`${!file && !!urlFile ? 'text-white' : ''}`}>
                    {
                      file ? file?.name : urlFile?.split('/').pop()
                    }
                  </span>
                )
              }
            </div>
          </div>
          {
            fieldError.file && (
              <div className="error-text">{fieldError.file}</div>
            )
          }
        </div>
        <div className="field-container">
          <div className="field-label">DATA DE VALIDADE*</div>
          <CalendarInput
            className="date-input"
            placeholder="00/00/0000"
            minDate={addDays(new Date(), 1)}
            initialValue={date}
            onChange={handleDateChange}
          />
          {
            fieldError.date && (
              <div className="error-text">{fieldError.date}</div>
            )
          }
        </div>
        {/* {
          document.id &&
          <div className="field-container">
            <div className="field-label">ARQUIVO</div>
            <div className="tooltips">
              <FaDownload size={22} onClick={(e) => handleFileDownload(document.id)} />
              <span>
                Baixar arquivo
              </span>
            </div>
          </div>
        } */}
        <div className="spinner">
          {saving && (<FaSpinner />)}
        </div>
      </div>
      {
        document?.reason &&
        <div className='document-analysis'>
          <label className='label reason-title'>MOTIVO DA ANÁLISE</label>
          <div className='reason-content'>
            <span>{document.reason}</span>
          </div>
        </div>
      }
    </div>
  )
}

const getDocumentsRequired = async (setDocuments) => {
  try {
    const { data } = await DocumentManagementService.getProviderDocumentationsRequired()
    setDocuments(data)
  } catch (error) {
    console.log('ERROR DOCUMENTS REQUIRED', error)
  }
}

const ProviderDocuments = ({ history }) => {
  const formRef = useRef()
  const [documents, setDocuments] = useState([])
  const [modal, setModal] = useState(false);
  const [modalButtons, setModalButtons] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [shouldUpdateDoc, setShouldUpdateDoc] = useState(true);


  useEffect(() => {
    if (shouldUpdateDoc) {
      getDocumentsRequired(setDocuments);
      setShouldUpdateDoc(false);
    }
  }, [shouldUpdateDoc])

  const handleSave = (e) => {
    setModalTitle('Confirmação de atualização de documento')
    setModal(shouldShowModal)
    setModalButtons(getModalButtons())
  }

  const getModalButtons = () => {
    const buttons = [
      {
        className: 'cancel',
        label: 'Cancelar',
        onClick: function () {
          setModal(false);
        }
      },
      {
        className: 'confirm',
        label: 'Confirmar',
        onClick: function () {
          simpleEvent.notify('get-documentations', true);
          setModal(false);
        }
      }
    ];
    return buttons;
  }

  return (
    <div className="provider-documents-page">
      <Modal
        active={modal}
        disableFunction={setModal}
        disableWhenClickAway={true}
        fixed={true}
      >
        <ConfirmationModal title={modalTitle} body={modalBody} buttons={modalButtons} />
      </Modal>
      <h4>DOCUMENTAÇÕES DA EMPRESA</h4>
      <div className="documents-container">
        {
          Array.isArray(documents) && documents.map((doc, index) => (
            <Document
              key={`doc-${index}`}
              document={doc}
              docEvent={simpleEvent}
              setModalBody={setModalBody}
              setShouldShowModal={setShouldShowModal}
              modalBody={modalBody}
              setShouldUpdateDoc={setShouldUpdateDoc}
            />
          ))
        }
      </div>
      <div className="documents-actions">
        <button onClick={() => history.goBack()}>Cancelar</button>
        <button className="success-btn" onClick={handleSave} disabled={!shouldShowModal}>
          Submeter documentações
        </button>
      </div>
    </div>
  )
}

export default ProviderDocuments
