import React from 'react';

import './styles.scss';

const Input = ({register, name, error, label, ...rest}) => {
  return (
    <div className='form-input'>
      {
        <input
          className='input'
          id={ name }
          ref={ register }
          name={ name }
          placeholder={ label }
          {...rest}
        />
      }
      {
        error && (
          <span className='field-error'>
            { error.message }
          </span>
        )
      }
    </div>
  )
}

export default Input
