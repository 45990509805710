import React, { useContext } from 'react';

import { Route, Redirect } from 'react-router-dom';

import AuthContext, { isOnlyProvider } from '../utils/auth';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const AuthUser = useContext(AuthContext)

  const redirectPath = isOnlyProvider(AuthUser.roles) ? (
    '/fornecedor/escopos-contratados'
  ) : (
    '/colaborador/escopos-de-servico'
  )

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route { ...rest } render={ props => (
      AuthUser.isAuthenticated && restricted
        ? <Redirect to={redirectPath} />
        : <Component { ...props } />
      )}
    />
  );
};

export default PublicRoute;
