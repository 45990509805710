import axios from 'axios';

const { HOST } = process.env;


const axiosCreateOptions = {
  baseURL: `${HOST}/v1/`,
  headers: {'Content-Type' : 'application/json'}
}

const api = axios.create(axiosCreateOptions);

api.getAuthToken = () => {
    const token = localStorage.getItem('TOKEN')
    return { Authorization: 'Bearer ' + token }
  }

export default api;
