import React, { useState, useEffect } from 'react';
import ScopesTable from '../../components/ScopesTable';
import Loading from '../../components/Loading';
import { ServiceScopeService } from '../../services'
import Toast from '../../components/Toast';
import { dateToCSVfilename } from '../../helpers/forms/convert';
import { saveFile } from '../../utils/files';
import FilterContent from '../../components/FilterContent'
import './styles.scss';

// é um componente que exibe e gerencia a tabela de Escopos de Serviço com filtros e exportação CSV.
const ServiceScopeTable = () => {
  const [bodyFilter, setBodyFilter] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [shouldUpdateData, setShouldUpdateData] = useState(false);

  // Array responsável por definir as opções de filtro de status da tabela
  const statusArray = [
    { label: "Em edição", status: "editing", checked: false },
    { label: "Liberado", status: "approved", checked: false },
    { label: "Contratado", status: "hired", checked: false },
    { label: "Cancelado", status: "canceled", checked: false },
  ];

  // Array responsável por definir as opções de escolha da pesquisa
  const searchArray = [
    { label: "Título", checked: true },
    { label: "ID", checked: false },
    { label: "Solicitante", checked: false },
    { label: "Unidade", checked: false },
    { label: "Área", checked: false },
  ];

  // responsável por recuperar os itens da tabela de Escopos de Serviço, considerando filtros e paginação.
  const getTableItems = async (childData) => {
    childData != undefined ? setBodyFilter(childData) : '';
    let body = childData != undefined ? childData : bodyFilter;
    let response;
    
    if (!tableData) {
      const { data } = await ServiceScopeService.search(1, 10);
      response = data;
    } else {
      const { data } = await ServiceScopeService.search(currentPage, pageSize, body);
      response = data;
    }
    setTableData(response);
  }

    // caso o componente de filtro retorne um body para pesquisa ele seta a setCurrentPage para 1
    const handdleFilterChange = (childData) => {
      setCurrentPage(1)
      getTableItems(childData)
    }

  // responsável por atualizar a tabela de Escopos de Serviço, reiniciando os dados na primeira página.
  const refreshTable = async () => {
    if (currentPage == 1) {
      const { data } = await ServiceScopeService.search(1, 10);
      setTableData(data);
      setShouldUpdateData(false);
    } else {
      setCurrentPage(1);
    }
  }

  // responsável por alterar a página atual da tabela de Escopos de Serviço conforme a seleção do usuário.
  const handdleTableChangePage = (page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    getTableItems();
  }, [currentPage])

  useEffect(() => {
    if (!shouldUpdateData) return;
    refreshTable();
  }, [shouldUpdateData])

  // responsável por gerar um arquivo CSV dos Escopos de Serviço e inicia o download da planilha gerada.
  const handleCSVButton = async () => {
    
    // base do body antes de populado
    let body = { scopes_id : tableData.results[0].csv_data }

    // map populando o body
    // tableData.results.map((item)=>{
    //   body.scopes_id.push(item.id)
    // })

    try {
      Toast.warning('Gerando CSV dos escopos de serviços');
      const response = await ServiceScopeService.downloadCSVFile(body);
      Toast.hide();

      let filename = `escopos-servicos-${dateToCSVfilename(new Date)}.csv`
      await saveFile(response, filename);
      Toast.success('CSV gerado com sucesso');
    } catch (error) {
      console.error(error);
      let errorData = error?.response?.data;
      if (errorData?.message) Toast.error(errorData.message);
    }
  }

  // A função return renderiza a tabela de Escopos de Serviço com filtros, botão de download CSV e paginação.
  return (
    <section className='service-scope-table-page'>
      <div className='table-wrapper'>
        {
          tableData ? (
            <div>
              <FilterContent
              titleText={'ESCOPOS DE SERVIÇO'}
              searchFilter={searchArray}
              statusFilter={statusArray}
              onDataFromChild={handdleFilterChange} // Passando a função como prop
              handleCSVButton={() => {handleCSVButton()}} // Passando a função de download do csv
              Dates={true}
              />
            <ScopesTable
              data={tableData}
              currentPage={currentPage}
              pageSize={pageSize}
              handdleChangePage={handdleTableChangePage}
              showSearchBar={true}
              shouldUpdateData={setShouldUpdateData}
            />
            </div>
          ) : (
            <div className='load-container'>
              <Loading />
            </div>
          )
        }
      </div>
    </section>
  );
}

export default ServiceScopeTable
