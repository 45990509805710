import React, { useState, useEffect, useRef } from 'react';
import ModalWarning from '../ModalWarning';
import Loading from '../../components/Loading';
import { TiArrowRight, TiChevronLeft, TiChevronRight, TiChevronRightOutline } from 'react-icons/ti';
import { CgChevronRight, CgMenuRound, CgPushRight } from 'react-icons/cg';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FaChevronRight, FaDownload, FaEdit, FaRegCopy } from 'react-icons/fa';
import { MdCancel, MdChevronRight } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import { ServiceScopeService } from '../../services';
import SearchInput from '../SearchInput';
import Toast from '../Toast/index';
import { getPages } from '../../helpers/pagination/utils';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import './styles.scss';


const Actions = ({ id, active, handleActive, position, handdleChangePage, shouldUpdateData, status, setPageLoading }) => {
  // id: é um número inteiro que representa o id do escopo de serviço;
  // active: é um booleano que representa se o escopo de serviço está ativo ou não;
  // handleActive: é uma função que recebe o id do escopo de serviço e é responsável por mantê-lo aberto quando em modo de edição ou clonagem;
  // position: é uma string que indica a posição em que o menu de ações deve aparecer na tela;
  // handdleChangePage: é responsável por atualizar a página atual da tabela de dados
  // shouldUpdateData: é uma função que recebe um booleano e é responsável por atualizar os dados da tabela após alguma operação;
  // status: é uma string que representa o status do escopo de serviço, podendo ser "editing", "approved", "hired" ou "canceled".

  let history = useHistory()
  const [openModal, setOpenModal] = useState(false); // responsável pelo controle de abertura do modalWarning
  const [textModal, setTextModal] = useState(null); // responsável pelo texto do modal desvincular e deletar
  const containerRef = useRef(null);
  useOutsideAlerter(containerRef, () => handleActive(''))

  //Função responsável po cancelar serviço, atualizar dados e exibir mensagem de sucesso/erro.
  const handleCancelScopeService = async (id) => {
    try {
      setPageLoading(true)
      const { data } = await ServiceScopeService.cancel(id);
      Toast.success(data.message);
      await setOpenModal(false)
      await shouldUpdateData(true);
      await handdleChangePage(1);
    } catch (error) {
      const { data } = error.response
      Toast.error(`Erro: ${data.message}`);
    }

    await handleActive(id);
    await handleActive('');
    setPageLoading(false)
  }

  // Função para deletar um serviço de escopo com tratamento de erro.
  const handleDeleteScopeService = async (id) => {
    try {
      setPageLoading(true)
      const { data } = await ServiceScopeService.delete(id);
      Toast.success(data.message);
      await setOpenModal(false)
      await shouldUpdateData(true);
      await handdleChangePage(1);

    } catch (error) {
      const { data } = error.response
      Toast.error(`Erro: ${data.message}`);
    }
    await handleActive(id);
    await handleActive('');
    setPageLoading(false)
  }

  // Função para redirecionar o usuário para a página de clonagem de um escopo de serviço.
  const handleCloneScopeService = async (id) => {
    try {
      Toast.success(`Clonando escopo de serviço #${id}.`)
      history.push(`/colaborador/escopos-de-servico/clone/${id}`)
    } catch (error) {
      Toast.error('Impossivel clonar esse escopo de serviço.')
    }
  }

  // Função que trata o download de um arquivo PDFreferente a um determinado escopo de serviço.
  const handlePDFButton = async (id) => {
    try {
      handleActive('');
      setPageLoading(true)
      const { data } = await ServiceScopeService.getScopeServicePDF(id);
      const file = await new Blob([data], { type: 'application/pdf' });
      const url = await URL.createObjectURL(file);
      await window.open(url, 'Escopo de Serviço.pdf');
      setPageLoading(false)
    } catch (error) {
      const { response: { data } } = error;
      const blob = new Blob([data], { type: 'application/json' });
      const dataText = await blob.text()
      const dataJson = JSON.parse(dataText)
      Toast.error(`${dataJson.message}`);
    }
  }

  // Responsável por renderizar uma tabela de escopos de serviço com ações para cada item.
  return (
    <div className='scopes-table-actions-wrapper'>
      <button
        className='button'
        onClick={() => handleActive(id)}
      >
        <CgMenuRound />
        <IoMdArrowDropdown />
      </button>
      <ModalWarning
        isOpen={openModal}
        textModal={textModal + id + '?'}
        handleYesClick={async () => {
          if (status == 'hired') {
            await handleCancelScopeService(id);
          } else if (status == 'editing') {
            await handleDeleteScopeService(id)
          }
        }}
        handleNoClick={() => { setOpenModal(false) }}>
      </ModalWarning>
      {
        // Parte responsável por renderizar as ações do componente drop-down
        active && (
          <div ref={containerRef} className={`actions-container ${position} fade-in`}>
            {status === 'approved' && (
              <button
                className='action'
                onClick={() => handlePDFButton(id)}
              >
                <FaDownload className='icon' />
                Gerar PDF
              </button>
            )}
            {status !== 'hired' && status !== 'canceled' && (
              <Link to={`/colaborador/escopos-de-servico/${id}`} className='action'>
                <FaEdit className='icon' />
                Editar
              </Link>
            )}
            <button className='action' onClick={() => handleCloneScopeService(id)}>
              <FaRegCopy className='icon' />
              Clonar
            </button>
            {status !== 'editing' && status !== 'approved' && status !== 'canceled' && (
              <button className='action' onClick={async () => { await setTextModal('Você realmente deseja desvincular o escopo: '), setOpenModal(true); }}>
                <MdCancel className='icon' />
                Desvincular
              </button>
            )}
            {status === 'editing' && (
              <button className='action' onClick={async () => { await setTextModal('Você realmente deseja deletar o escopo: '), setOpenModal(true); }}>
                <MdCancel className='icon' />
                Deletar
              </button>
            )}
          </div>
        )
      }
    </div>
  )
}
// Componente de tabela com barra de pesquisa e paginação.
const ScopesTable = ({ data, currentPage, pageSize, handdleChangePage, showSearchBar, shouldUpdateData }) => {
  // data: é um array que contém os dados a serem exibidos na tabela.
  // currentPage: é um número que representa a página atual da tabela.
  // pageSize: é um número que representa a quantidade de linhas exibidas por página na tabela.
  // handdleChangePage: é uma função que é chamada sempre que ocorre uma alteração de página na tabela. É responsável por atualizar o estado de currentPage.
  // showSearchBar: é uma propriedade booleana que indica se a barra de pesquisa deve ser exibida ou não.
  // shouldUpdateData: é uma propriedade booleana que indica se os dados da tabela devem ser atualizados. Quando essa propriedade é true, a tabela é atualizada.

  const [actionSelected, setActionSelected] = useState('')
  const [pageInitial, setPageInitial] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);

  // Responsável checar se a página solicitada na tabela é a mesma renderizada para chamar o loading.
  const checkDiferencePage = async (page) => {
    if (page != pageInitial) {
      await setPageInitial(page)
      setPageLoading(true)
    }
  }

  // função usa o hook useEffect para executar uma ação após a renderização da tabela de escopos de serviço.
  useEffect(() => {
    const tableRows = document.querySelectorAll('.table tbody tr');
    const tableRowCount = tableRows.length;
    const dataResultCount = data.results ? data.results.length : 0;
    if (tableRowCount === dataResultCount) {
      setPageLoading(false)
    }
  }, [data]);

  // Responsável por tratar a ação do usuário de clicar no botão "Anterior" ou "Próximo" da paginação da tabela.
  const handleNextPrevious = (link) => {
    let page = 1
    if (link.includes('page')) page = link.slice(-1);

    handdleChangePage(page)
  }

  // Responsável receber um parâmetro que representa um status e retorna uma cor associada a esse status.
  const getStatusColor = (status) => {
    const statusColors = {
      editing: '#FABC34',
      approved: '#00B26B',
      hired: '#545CD8',
      canceled: '#707070',
    }

    return statusColors[status]
  }

  // A função retorna uma label ou etiqueta que representa o status de acordo com o escopo
  const getStatusLabel = (status) => {
    const statusColors = {
      editing: 'Em edição',
      approved: 'Liberado',
      hired: 'Contratado',
      canceled: 'Cancelado',
    }

    return statusColors[status]
  }

  // Parte responsável pela renderização do componente de tabela
  return (
    <section className='scope-service-page fade-in'>
      <div className='table-container'>
        { // loading do itens da tabela
          pageLoading && (
            <div className='page-loading'>
              <Loading />
            </div>
          )
        }
        <table className='table table-striped table-borderless'>
          {/* Titulos da tabela  */}
          <thead>
            <tr>
              <th scope='col'>Título</th>
              <th scope='col'>ID</th>
              <th scope='col'>Data de solicitação</th>
              <th scope='col'>Nº Vivix</th>
              <th scope='col'>Solicitante</th>
              <th scope='col'>Unidade</th>
              <th scope='col'>Área</th>
              <th scope='col'>Status</th>
              <th scope='col'>Ações</th>
            </tr>
          </thead>
          {/* Parte responsável por separar os dados de cada item da tabela */}
          <tbody>
            {
              data.results && data.results.map((item, index) => (
                <tr className='fade-in' key={item.id}>
                  <th className='pk scope-title' scope='row'>{item.title}</th>
                  <th className='pk' scope='row'>{item.id}</th>
                  <th className='pk' scope='row'>{item.date}</th>
                  <td>{item.vivix_number}</td>
                  <th className='pk' scope='row'>{item.requester}</th>
                  <td>{item.unit?.name}</td>
                  <td>{item.area?.name}</td>
                  <td className='status'>
                    <div
                      style={{ backgroundColor: getStatusColor(item.status) }}
                      className='status-container'
                    >
                      {getStatusLabel(item.status)}
                    </div>
                  </td>
                  <td>
                    {/* Componente drop-down de ações */}
                    <Actions
                      id={item.id}
                      active={item.id == actionSelected}
                      handleActive={setActionSelected}
                      position={index > 7 ? 'top' : 'bottom'}
                      shouldUpdateData={shouldUpdateData}
                      status={item.status}
                      handdleChangePage={handdleChangePage}
                      setPageLoading={setPageLoading}
                    />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      {/* Parte responsável pelo paginador da tabela */}
      <div className='paginator-container'>
        <nav>
          <ul className='pagination'>
            <li className={`page-item ${data.previous ? '' : 'disabled'}`}>
              <a
                className='page-link'
                onClick={() => (handleNextPrevious(data.previous), setPageLoading(true))}
              >
                <div className='item-right'>
                  <TiChevronLeft />
                  <p>Anterior</p>
                </div>
              </a>
            </li>
            {
              getPages(data.count, pageSize) && getPages(data.count, pageSize).map(page => (
                <li
                  key={page}
                  onClick={() => { handdleChangePage(page), checkDiferencePage(page) }}
                  className={`page-item ${page == currentPage ? 'active' : ''} `}>
                  <a className='page-link'>{page}</a>
                </li>
              ))
            }
            <li className={`page-item ${data.next ? '' : 'disabled'}`}>
              <a
                className='page-link'
                onClick={() => { handleNextPrevious(data.next), setPageLoading(true) }}
              >
                <div className='item-right'>
                  <p>Próximo </p>
                  <TiChevronRight />
                </div>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
}

export default ScopesTable
