import api from './api'

class ServiceScopeService {
  static search(page, pageSize, body) {
    return api.post(
      `scope-service/search/?&page=${page}&limit=${pageSize}`, body,
      { headers: { ...api.getAuthToken() } },
    );
  }

  static create(body) {
    return api.post(
      `scope-service/`, body,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static update(body, id) {
    return api.put(
      `scope-service/${id}/`, body,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static retrive(id) {
    return api.get(
      `scope-service/${id}/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static cancel(id) {
    return api.delete(
      `scope-service/${id}/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static delete(id) {
    return api.delete(
      `scope-service/${id}/delete/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getUnits() {
    return api.get(
      `scope-service/units/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getFormData() {
    return api.get(
      `scope-service/form-data/`,
      { headers: { ...api.getAuthToken() } }
    )
  }

  static getAreas() {
    return api.get(
      `scope-service/areas/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getContractTypes() {
    return api.get(
      `scope-service/contract-types/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getTechnicalDocuments() {
    return api.get(
      `scope-service/technical-documents/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getComplementaryServices() {
    return api.get(
      `scope-service/complementary-services/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getTechnicalProposalRequirements() {
    return api.get(
      `scope-service/technical-proposal-requirements/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getServiceRequirements() {
    return api.get(
      `scope-service/service-requirements/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getResponsibilities() {
    return api.get(
      `scope-service/responsibilities/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getMeals() {
    return api.get(
      `scope-service/meals/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getLaborTypes() {
    return api.get(
      `scope-service/labor-types/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getClosingConditions() {
    return api.get(
      `scope-service/closing-conditions/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getSsmaRequirements() {
    return api.get(
      `scope-service/ssma-requirements/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getSeniorAcessGroups() {
    return api.get(
      `scope-service/senior-acess-groups/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getRequirementsDocs(id) {
    return api.get(`scope-service/${id}/ssma-requirements-docs/`,
      { headers: { ...api.getAuthToken() } }
    );
  }

  static getScopeServicePDF(id) {
    return api.get(`scope-service/export/pdf/${id}/`,
      { headers: { ...api.getAuthToken() }, responseType: 'blob' }
    );
  }

  static clone(id) {
    return api.post(`scope-service/${id}/clone/`, null, {
      headers: {
        ...api.getAuthToken(),
      }
    });
  }

  static downloadCSVFile(body) {
    return api.post(`scope-service/export/csv/`, body, {
      headers: {
        ...api.getAuthToken(),
      },
      responseType: 'blob'
    });
  }

  static linkProvider(body) {
    // adiciona o escopo de serviço para o fornecedor
    return api.post(`scope-service/add-scope-service-provider/`, body, {
      headers: {
        ...api.getAuthToken(),
      }
    });
  }

  static updateScopePeriod(id, payload) {
    return api.patch(
      `scope-service/${id}/update-scope-duration/`, payload,
      { headers: { ...api.getAuthToken() } },
    );
  }

  static infoPreLinkScopeService(payload) {
    return api.post(`scope-service/info-pre-link-scope-service/`, payload,
      { headers: { ...api.getAuthToken() } },
    );
  }
}

export default ServiceScopeService;
