import SSMAReports from '../../components/SSMAReports';
import DriversReport from '../../components/DriversReport';

export default [
  {
    id: "ReportsCompaniesAndEmployees",
    path: "/relatorios",
    page: SSMAReports,
    title: "Empresas e colaboradores",
    exact: true
  },
  {
    id: "ReportsDrivers",
    path: "/relatorios/motoristas",
    page: DriversReport,
    title: "Motoristas"
  }
]
