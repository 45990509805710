class SimpleEvent {
  constructor() {
    this.subscribes = {}
  }

  subscribe(event, callback) {
    if (!Array.isArray(this.subscribes[event])) this.subscribes[event] = []

    const length = this.subscribes[event].push(callback)
    return { event, index: length - 1 }
  }

  length(event) {
    if (this.subscribes[event]) {
      return this.subscribes[event].filter(callback => !!callback).length
    }

    return 0
  }

  notify(event, data) {
    if (Array.isArray(this.subscribes[event]) && this.subscribes[event].length > 0 ) {
      try {
        this.subscribes[event].map(callback => callback && callback(data))
      } catch (error) {
        console.error('[SimpleEvent ERROR] Erro ao tentar notificar uma inscricao', error)
      }
    }
  }

  unsubscribe({ event, index }) {
    if (this.subscribes[event] && this.subscribes[event][index]) {
      delete this.subscribes[event][index]
    }
  }
}

export default SimpleEvent