import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

import ROUTES from './routes';
import './styles.scss';



const Reports = () => {
  return (
    <div className="page">
      <Router>
        <ul className="nav nav-pills">
          {
            Array.isArray(ROUTES) && ROUTES.map((route) => (
              <li className="nav-item" key={`nav-item-report-${route.id}`}>
                <NavLink
                  className="nav-link"
                  to={route.path}
                  exact={route.exact}
                  key={route.id}
                >
                  {route.title}
                </NavLink>
              </li>

            ))
          }
        </ul>
        <Switch>
          {
            Array.isArray(ROUTES) && ROUTES.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props) => (
                  <route.page
                  />
                )}
              />
            ))
          }
        </Switch>
      </Router>
    </div>
  );
}

export default Reports;
