import React, { useState, useEffect, useContext } from 'react';
import HiredScopesTable from '../HiredScopesTable';
import Loading from '../Loading';
import FilterContent from '../../components/FilterContent'
import { DocumentManagementService } from '../../services'
import Toast from '../../components/Toast';
import { dateToCSVfilename } from '../../helpers/forms/convert';
import { saveFile } from '../../utils/files';
import { useHistory } from 'react-router-dom';
import './style.scss';

// Componente que exibe uma tabela de escopos de serviço contratados com opções de filtro e busca.
const HiredScopes = ({ onClickTd }) => {
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [bodyFilter, setBodyFilter] = useState({});
  let history = useHistory();
  const pageSize = 10;
  let pathName = history.location.pathname;

  // Array responsável por definir as opções de filtro de status da tabela
  const statusArray = [
    { label: "Pendente com restriçoes", status: "pendent", checked: false },
    { label: "Em aberto", status: "open", checked: false },
    { label: "Aprovado", status: "approved", checked: false },
    { label: "Aguardando análise", status: "waiting", checked: false },
    { label: "Pendente Dados Fornecedor", status: "missing", checked: false },
  ];

  // Array responsável por definir as opções de escolha da pesquisa
  const searchArray = [
    { label: "ID do Escopo", checked: true },
    { label: "Título do escopo", checked: false },
    { label: "Gestor do contrato", checked: false },
    { label: "Fornecedor", checked: false },
  ];

  // Responsável por fazer uma requisição assíncrona para obter dados de escopos de serviço contratados a partir de um filtro de busca.
  const getTableItems = async (newBody) => {
    newBody != undefined ? setBodyFilter(newBody) : null;
    let body = newBody != undefined ? newBody : bodyFilter;
    let response = {};

    if (pathName.includes('meus-escopos')) {
      body.my_scope_manager = true;
    }

    try {
      const { data } = await DocumentManagementService.searchProviderHiredServiceScopes(currentPage, pageSize, body);
      response = data;
    } catch (error) { }

    setTableData(response);
  }

  // Responsável por receber os dados de filtro de busca de escopos de serviço contratados e os formata para uso na requisição da tabela.
  const getBody = (childData) => {
    let newBody = {
      search_company_or_manager: childData.search,
      analysis_status: childData.status,
      type_search: childData.type_search.split(" ")[0]
    }

    if (childData.provide_scope_initial_date && childData.provide_scope_end_date) {
      newBody.provide_scope_initial_date = childData.provide_scope_initial_date.replaceAll('-', '/').split("/").reverse().join("/"),
        newBody.provide_scope_end_date = childData.provide_scope_end_date.replaceAll('-', '/').split("/").reverse().join("/")
    }

    newBody.manage_scopes = true;

    setCurrentPage(1)
    getTableItems(newBody);
  }

  const handleCSVButton = async () => {

    // base do body antes de populado
    let body = {
      id: tableData.results[0].csv_data,
      csv_type: "hired"
    }

    // map populando o body
    // tableData.results.map((item)=>{
    //   body.hired_ids.push(item.id)
    // })

    // console.log(body)

    try {
      Toast.warning('Gerando CSV dos escopos contratados');
      const response = await DocumentManagementService.downloadCSVFile(body);
      Toast.hide();

      let filename = `escopos-contratados-${dateToCSVfilename(new Date)}.csv`
      await saveFile(response, filename);
      Toast.success('CSV gerado com sucesso');
    } catch (error) {
      console.error(error);
      let errorData = error?.response?.data;
      if (errorData?.message) Toast.error(errorData.message);
    }
  }

  useEffect(() => {
    getTableItems();
  }, [currentPage]);

  // Responsável por retorna uma seção contendo a tabela, o componente "FilterContent" com opções de filtro e busca, e um componente "Loading".
  return (
    <section className='provider-hired-scopes-table-page'>
      <div className='table-wrapper'>
        {
          tableData ? (
            <div>
              <FilterContent
                titleText={'ESCOPOS DE SERVIÇO CONTRATADOS'}
                searchFilter={searchArray}
                statusFilter={statusArray}
                onDataFromChild={getBody} // Passando a função como prop
                handleCSVButton={() => { handleCSVButton() }} // Passando a função de download do csv
                Dates={true}
              />
              <HiredScopesTable
                data={tableData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                onClickTd={onClickTd}
              />
            </div>
          ) : (
            <div className='load-container'>
              <Loading />
            </div>
          )
        }
      </div>
    </section>
  );
}

export default HiredScopes;
