import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('pt-BR', ptBR)


const CalendarInput = ({
  id, name, placeholder, className,
  minDate, maxDate, initialValue,
  component, onChange, disabled,
  readOnly, required,
  ...rest
}) => {
  const calendarId = id || name;
  return (
    <DatePicker
      required={required}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      locale="pt-BR"
      dateFormat="P"
      id={calendarId}
      name={name}
      placeholderText={placeholder}
      className={className}
      minDate={minDate}
      maxDate={maxDate}
      selected={initialValue}
      onChange={onChange}
      customInput={component}
      disabled={disabled}
      readOnly={readOnly}
      autoComplete="off"
      {...rest}
    />
  )
}

export default CalendarInput;
