import React from 'react';

import './styles.scss';

import Toast from '../../../components/Toast';

import { UserService } from './../../../services';
import { useHistory } from 'react-router-dom';
import { Cookies } from 'react-cookie-consent';
import { cookieName, cookieNameLegacy } from './../../../constants/index';

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const ConfirmationBox = ({ data, disableFunction }) => {
  let history = useHistory();

  const formatText = (text) => {
    let splited_text = text.split('_')
    if (splited_text.length > 1) {
      text = `${splited_text[0]} ${splited_text[1].toUpperCase()}`
    }
    return capitalize(text)
  }

  const createPreRegister = async (driver) => {
    let data;
    try{
      if (driver.dt) {
        const response = await UserService.createPreRegister(driver, true);
        data = response.data
        history.go('/faturista/historico-de-visitas');
      } else {
        const response = await UserService.createPreRegister(driver);
        data = response.data
        history.go('/pre-cadastros');
      }
      Toast.success('Motorista cadastrado com sucesso', {timeOut: 2000});
      Cookies.remove(cookieName);
      Cookies.remove(cookieNameLegacy);
    } catch(err) {
      const {response: {data}} = err;
      if (data.errors) {
        data.errors.map(error => {
          let {driver, vehicle, visitation, dt} = error;
          if (driver) {
            if (driver.cpf) Toast.error(driver.cpf[0]);
            if (driver.full_name) Toast.error(driver.full_name[0]);
            if (driver.phone) Toast.error(driver.phone[0]);
            if (driver.cnh_number) Toast.error(driver.cnh_number[0]);
            if (driver.consent_form) Toast.error(driver.consent_form[0]);
          }
          else if (vehicle) {
            if (vehicle.license_plate) Toast.error(vehicle.license_plate[0]);
            if (vehicle.city) Toast.error(vehicle.city[0]);
            if (vehicle.state) Toast.error(vehicle.state[0]);
            if (vehicle.container_number) Toast.error(vehicle.container_number[0]);
          }
          else if (visitation) {
            if (visitation.visit_type) Toast.error(visitation.visit_type[0]);
            if (visitation.client_name) Toast.error(visitation.client_name[0]);
            if (visitation.company_name) Toast.error(visitation.company_name[0]);
            if (visitation.visit_type) Toast.error(visitation.visit_type[0]);
            if (visitation.dt) Toast.error(visitation.dt);
            if (visitation.employee) Toast.error(visitation.employee[0]);
          }
          else if (dt) {
            Toast.error(dt[0]);
          }
        });
      }
    }
  }

  const handleConfirm = (data) => {
    createPreRegister(data);
    disableFunction(false);
  }

  return (
    <div className='register-confirmation'>
      <div className='header'>
        Tem certeza que deseja finalizar o cadastro?
      </div>
      <div className='body'>
        {/* pessoal */}
        <div className='title'>Confirme os dados:</div>
        <div className='item'>
          <span className='label'>CPF: </span>
          { data.cpf }
        </div>
        <div className='item'>
          <span className='label'>Motorista: </span>
          { data.full_name }
        </div>
        <div className='item'>
          <span className='label'>Telefone: </span>
          { data.phone }
        </div>
        <div className='item'>
          <span className='label'>Número CNH: </span>
          { data.cnh_number }
        </div>
        <div className='item'>
          <span className='label'>Vencimento da CNH: </span>
          { data.cnh_expires_at }
        </div>
        {/* veiculo */}
        <div className='item'>
          <span className='label'>Placa do veículo: </span>
          { data.license_plate }
        </div>
        <div className='item'>
          <span className='label'>Cidade: </span>
          { data.city }
        </div>
        <div className='item'>
          <span className='label'>Estado: </span>
          { data.state }
        </div>
        <div className='item'>
          <span className='label'>Tipo do veículo: </span>
          { formatText(data.vehicle_type) }
        </div>
        {data.container_number && <div className='item'>
          <span className='label'>Número do container: </span>
           { formatText(data.container_number) }
        </div>}
        {/* visita */}
        <div className='item'>
          <span className='label'>Tipo do motorista: </span>
          { formatText(data.driver_type) }
        </div>
        { data.company_name && <div className='item'>
          <span className='label'>Nome da transportadora: </span>
          { data.company_name }
        </div>}
        { data.client_name && <div className='item'>
          <span className='label'>Nome do cliente: </span>
          { data.client_name }
        </div>}
        <div className='item'>
          <span className='label'>Tipo de visita: </span>
          { formatText(data.visit_type) }
        </div>
        { data.dt && <div className='item'>
          <span className='label'>Documento de transporte (DT): </span>
          { data.dt }
        </div>}
        { data.note && <div className='item'>
          <span className='label'>Obs: </span>
          { data.note }
        </div>}
      </div>
      <div className='footer'>
        <button
          className='button cancel'
          onClick={() => disableFunction(false)}
        >
          Cancelar
        </button>
        <button
          className='button confirm'
          onClick={ (e) => handleConfirm(data) }
        >
          Cadastrar
        </button>
      </div>
    </div>
  );
}

export default ConfirmationBox
