import React, { useState, useEffect } from 'react';

import './styles.scss';
import { AiOutlineSearch } from 'react-icons/ai';


const SearchInput = ({ setValue, ...rest }) => {
  const [input, setInput] = useState('');

  const handleSubmit = () => {
    event.preventDefault();
    setValue(input)
  }

  useEffect(() => {
    if(input.length < 1)
      setValue(input)
  }, [input]);

  return (
      <div className='search-input-component'>
        <form className='form' onSubmit={ handleSubmit }>
          <input
            value={ input }
            onChange={ (event) => setInput(event.target.value) }
            className='search-input'
            {...rest}
          />
          <button className='button'>
            <AiOutlineSearch className='icon' />
          </button>
        </form>
      </div>
  )
}

export default SearchInput
