import React, { useState, useEffect } from 'react';
import { format, isBefore, parseISO } from 'date-fns'; // import de tratamento de datas
import './styles.scss';
import { vivixLogoImage } from '../../constants';
import { ServiceScopeService } from '../../services';
import { CKClassic } from '../../components/CKEditor';
import CalendarInput from '../../components/CalendarInput/index';
import Toast from '../../components/Toast';
import Loading from '../../components/Loading';
import { BiExpand } from 'react-icons/bi';
import { AiOutlineInfoCircle, AiFillQuestionCircle } from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import slugify from 'slugify';
import { isVivixNumberValid } from '../../helpers/forms/isValid';

const slugifyField = (text) => {
  const config = {
    replacement: '_',
    lower: true,
    remove: /[*+~.()'"!:@,;/]/g
  }

  return slugify(text, config)
}

const enableCheck = (clicked) => {
  return clicked == 12 ? true : false
}

const customStyles = {
  placeholder: () => ({
    fontSize: '14px',
    color: 'rgba(54, 54, 54, 0.6)',
  }),

  ValueContainer: () => ({
    padding: '0px 20px',
    paddingLeft: '20px',
  }),

  indicatorSeparator: () => ({
  }),

  control: (provided, state) => ({
    ...provided,
    paddingLeft: '12px',
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? 'rgba(112, 112, 112, 0.55)' : 'rgba(112, 112, 112, 0.55)',
    '&:hover': {
      borderColor: state.isFocused ? 'rgba(112, 112, 112, 0.55)' : 'rgba(112, 112, 112, 0.55)'
    }
  }),

  singleValue: () => ({
    fontSize: '14px',
    color: 'rgba(54, 54, 54, 0.6) !important',
  }),
}

const FormSelect = ({ placeholder, isMulti, ...rest }) => (
  <Select
    styles={customStyles}
    isMulti={isMulti ? true : false}
    placeholder={placeholder}
    {...rest}
  />
)

const NewEscopoDeServico = ({ match }) => {
  const [documentosTecnicos, setDocumentosTecnicos] = useState(false);
  const [units, setUnits] = useState([]);
  const [areas, setAreas] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [technicalDocuments, setTechnicalDocuments] = useState([]);
  const [complementaryServices, setComplementaryServices] = useState([]);
  const [technicalProposalRequirements, setTechnicalProposalRequirements] = useState([]);
  const [serviceRequirements, setServiceRequirements] = useState([]);
  const [responsibilities, setResponsibilities] = useState([]);
  const [meals, setMeals] = useState([]);
  const [laborTypes, setLaborTypes] = useState([]);
  const [closingConditions, setClosingConditions] = useState([]);
  const [ssmaRequirements, setSsmaRequirements] = useState([]);
  const [serviceDescription, setServiceDescription] = useState(false);
  const [seniorAcessGroups, setSeniorAcessGroups] = useState([]);

  const [isSaving, setIsSaving] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const SCOPE_ID = match.params.id;
  const isCloning = match.url.includes('/clone/');

  // campos de data
  const [date, setDate] = useState(); // Responsável por setar a data do escopo na área (ESCOPO DE SERVIÇO)
  const [initialDate, setInitialDate] = useState(); // Responsável por setar a data inicial do calendario (Seção 7)
  const [endDate, setEndDate] = useState(); // Responsável por setar a data final do calendario (Seção 7)
  const [formData, setFormData] = useState(null);
  const [scopeData, setScopeData] = useState(null);
  const [exibirInfo, setExibirInfo] = useState(false);
  const { register, handleSubmit, control, errors , setValue, getValues, watch, clearErrors } = useForm();
  const { requisitos_da_roposta_tecnica_checkbox } = watch();
  const { requisitos_do_servico_checkbox } = watch();
  const { responsibilities_checkbox } = watch();
  const { ssmaRequirements_checkbox } = watch();
  const { closing_condition_note } = watch();
  const { reference_documents } = watch();

  let history = useHistory();

  const registerNotes = () => {
    register(
      { name: 'contract_type_note', type: 'custom' },
      {
        required: { value: false, message: 'Esse campo é obrigatório.' }
      });

    register(
      { name: 'service_description', type: 'custom' },
      {
        required: { value: true, message: 'Esse campo é obrigatório.' }
      });

    register(
      { name: 'service_note', type: 'custom' },
      {
        required: { value: false, message: 'Esse campo é obrigatório.' }
      });

    register(
      { name: 'complementary_service_note', type: 'custom' },
      {
        required: { value: false, message: 'Esse campo é obrigatório.' }
      });

    register(
      { name: 'closing_condition_note', type: 'custom' },
      {
        required: { value: false, message: 'Esse campo é obrigatório.' }
      });
  }

  const registerRichTextFields = () => {
    // registra os campos com formatação rich text
    // Documentos de referência
    register(
      { name: 'reference_documents', type: 'custom' },
      {
        required: { value: false }
      });
  }

  const chageRequire = (inputName, bool) => {
    const oldValue = getValues(inputName);
    register(
      { name: inputName, type: 'custom' },
      {
        required: { value: bool, message: 'Esse campo é obrigatório.' }
      });
    setValue(inputName, oldValue);
  }

  const handlDocumentosTecnicos = (e) => {
    if (documentosTecnicos) {
      setDocumentosTecnicos(false);
    } else {
      setDocumentosTecnicos(true);

      technicalDocuments.map(item => {
        setValue(slugifyField(item.name), false)
        clearErrors(slugifyField(item.name))
      })
    }
  }

  const handlDocumentosTecnicosRequired = () => {
    const checkboxes = technicalDocuments.map(item => (
      getValues(slugifyField(item.name))
    ))

    return checkboxes.every(e => e == false) && !documentosTecnicos
  }


  /* Essa função é usada no trecho de código anterior para determinar se 
   os campos de checkbox devem ser obrigatórios, dependendo do estado 
   atual dos checkboxes*/
  const handleRequisitosSSMARadioRequired = () => {
    let checkboxes = ssmaRequirements.map(item => (
      getValues(slugifyField(item.name))
    ))

    checkboxes.push(getValues('ssmaRequirements_checkbox'))

    return checkboxes.every(e => e == false)
  }

  const handleClosingConditionsRequired = () => {
    const checkboxes = closingConditions.map(item => (
      getValues(slugifyField(item.name))
    ))

    return checkboxes.every(e => e == false)
  }

  const handleRequisitosDoServicoRequired = () => {
    let checkboxes = serviceRequirements.map(item => (
      getValues(slugifyField(item.name))
    ))

    checkboxes.push(getValues('requisitos_do_servico_checkbox'))

    return checkboxes.every(e => e == false)
  }

  const handleResponsabilidadesVivixRequired = () => {
    let checkboxes = responsibilities.map(item => (
      getValues(slugifyField(item.name))
    ))

    checkboxes.push(getValues('responsibilities_checkbox'))

    return checkboxes.every(e => e == false)
  }

  const handleServiceDescription = () => {
    setServiceDescription(!serviceDescription)
  }

  const getUnits = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getUnits();
    }
    let parsedData = data.map(item => (
      { value: item.id, label: item.name }
    ))
    setUnits(parsedData)
  }

  const getAreas = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getAreas();
    }
    let parsedData = data.map(item => (
      { value: item.id, label: item.name }
    ))
    setAreas(parsedData)
  }

  const getContractTypes = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getContractTypes();
    }
    setContractTypes(data)
  }

  const getTechnicalDocuments = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getTechnicalDocuments();
    }
    setTechnicalDocuments(data)
  }

  const getComplementaryServices = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getComplementaryServices();
    }
    setComplementaryServices(data)
  }

  const getTechnicalProposalRequirements = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getTechnicalProposalRequirements();
    }
    setTechnicalProposalRequirements(data)
  }

  const getServiceRequirements = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getServiceRequirements();
    }
    setServiceRequirements(data)
  }

  const getResponsibilities = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getResponsibilities();
    }
    setResponsibilities(data)
  }

  const getMeals = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getMeals();
    }
    setMeals(data)
  }

  const getLaborTypes = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getLaborTypes();
    }
    setLaborTypes(data)
  }

  const getClosingConditions = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getClosingConditions();
    }
    setClosingConditions(data)
  }

  const getSsmaRequirements = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getSsmaRequirements();
    }
    setSsmaRequirements(data)
  }

  const getSeniorAcessGroups = async (data) => {
    if (!data) {
      const { data } = await ServiceScopeService.getSeniorAcessGroups();
    }
    let parsedData = data.map(item => (
      { value: item.id, label: item.name }
    ))
    setSeniorAcessGroups(parsedData)
  }

  const handdleNoteRequired = (checkboxes, note) => {
    const values = checkboxes.map(item => (
      getValues(slugifyField(item.name))
    ))

    if (values.every(e => e == false) && isSaving) {
      chageRequire(note, true)
    } else {
      chageRequire(note, false)
    }
  }

  useEffect(() => {
    getAllData();
    setMasks();
    registerNotes();
    registerRichTextFields();
  }, [])

  useEffect(() => {
    if (!requisitos_da_roposta_tecnica_checkbox) {
      setValue('other_technical_proposal_requirement', '')
    }
  }, [requisitos_da_roposta_tecnica_checkbox])

  useEffect(() => {
    if (!requisitos_do_servico_checkbox) {
      setValue('other_service_requirement', '')
    }
  }, [requisitos_do_servico_checkbox])

  useEffect(() => {
    if (!responsibilities_checkbox) {
      setValue('other_responsibility', '')
    }
  }, [responsibilities_checkbox])

  useEffect(() => {
    if (!ssmaRequirements_checkbox) {
      setValue('other_responsibility', '')
    }
  }, [ssmaRequirements_checkbox])

  useEffect(() => {
    if (!scopeData || !formData) return;
    loadData(scopeData);
    setPageLoading(false);
  }, [scopeData, formData])

  const setMasks = () => {
    $('#vivix_number').mask('SS00_S00_SS000_S0_0000_00', {
      onKeyPress: function (value, event) {
        event.currentTarget.value = value.toUpperCase();
      }
    });
    $('#date').mask('00/00/0000');
    $('#initial_date').mask('00/00/0000');
    $('#end_date').mask('00/00/0000');
    $('#revision').mask('0000');
    $('#phone').mask('+ZZZZZZZZZZZZZZZZZZ', {
      translation: {
        'Z': {
          pattern: /[0-9]|[()-\s]/, optional: true
        }
      }
    });
  }

  const renderTAFDescription = (description) => {
    const descriptionItems = description.split('XXXX');
    return (
      <div>
        <span className='text'>{descriptionItems[0]}</span>
        <input
          id='closing_condition_taf_days'
          name='closing_condition_taf_days'
          className='number'
          type='number'
          ref={register}
          min='0'
          style={{ width: '60px' }}
        />
        <span className='text'>{descriptionItems[1]}</span>
      </div>
    )
  }

  const getAllData = async () => {
    const data = await loadForm();
    if (SCOPE_ID) {
      let scope = {};
      if (isCloning) {
        scope = await cloneScopeService();
      } else {
        scope = await retriveScopeService();
      }
     setScopeData(scope);
    }
    if (data) {
      getUnits(data['units']);
      getAreas(data['areas']);
      getContractTypes(data['contract-types']);
      getTechnicalDocuments(data['technical-documents']);
      getComplementaryServices(data['complementary-services']);
      getTechnicalProposalRequirements(data['technical-proposal-requirements']);
      getServiceRequirements(data['service-requirements']);
      getResponsibilities(data['responsibilities']);
      getMeals(data['meals']);
      getLaborTypes(data['labor-types']);
      getClosingConditions(data['closing-conditions']);
      getSsmaRequirements(data['ssma-requirements']);
      getSeniorAcessGroups(data['senior-acess-groups']);
      setFormData(data);
    }
    if (!SCOPE_ID) setPageLoading(false);
  }

  const loadForm = async () => {
    const { data } = await ServiceScopeService.getFormData();
    return data;
  }

  const reduceIds = (items, condition) => {
    let reducedItems = items.reduce((acc, item) => {
      if (getValues(slugifyField(item.name)) == condition) {
        acc.push(item['id'])
      }
      return acc
    }, [])

    return reducedItems
  }

  const parseDate = (date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }


  // Função manipula eventos de checkboxes, verifica obrigatoriedade e limpa erros relacionados.
  const onChangCheckBoxCombo = (fields, value) => {
    const required = value()

    if (!required) clearErrors(fields.map(field => slugifyField(field.name)))

    return required
  }

  const onSubmit = async (data) => {
    // adiciona o loading para evitar duplicação de solicitaçẽos de criação/edição
    if (pageLoading) return;
    if (SCOPE_ID) setLoadingMessage('Atualizando o escopo de serviço');
    else setLoadingMessage('Criando novo escopo de serviço');
    setPageLoading(true);
    
    let body = {
      vivix_number: data.vivix_number,
      title: data.title,
      date: date ? parseDate(date) : parseDate(new Date()),
      requester: data.requester,
      phone: data.phone,
      revision: data.revision,
      sector: data.sector,
      email: data.email,
      status: isSaving ? 'editing' : 'approved',
      unit_id: data.unit_id ? data.unit_id.value : null,
      area_id: data.area_id ? data.area_id.value : null,
      provide_scope: {
        objective: data.objective,
        contract_type: reduceIds(contractTypes, true),
        contract_type_note: data.contract_type_note,
        service_description: data.service_description || '',
        not_applicable_technical_document: documentosTecnicos,
        technical_document: reduceIds(technicalDocuments, true),
        service_note: data.service_note,
        complementary_service: reduceIds(complementaryServices, true),
        complementary_service_note: data.complementary_service_note,
        technical_proposal_requirement: reduceIds(technicalProposalRequirements, true),
        other_technical_proposal_requirement: data.other_technical_proposal_requirement,
        service_requirement: reduceIds(serviceRequirements, true),
        other_service_requirement: data.other_service_requirement,
        responsibility: reduceIds(responsibilities, true),
        other_responsibility: data.other_responsibility,
        meal_id: data.meal_id != 0 ? Number(data.meal_id) : null,
        initial_date: initialDate ? parseDate(initialDate) : null,
        end_date: endDate ? parseDate(endDate) : null,
        deadline: data.deadline || null,
        work_hours: data.work_hours,
        labor_type_id: data.labor_type_id != 0 ? Number(data.labor_type_id) : null,
        third_party_employees: data.third_party_employees || null,
        closing_condition: reduceIds(closingConditions, true),
        closing_condition_note: data.closing_condition_note,
        closing_condition_taf_days: Number(data.closing_condition_taf_days) || null,
        warranty: data.warranty,
        reference_documents: data.reference_documents,
        ssma_requirements: reduceIds(ssmaRequirements, true),
        other_ssma_requirements: data.other_ssma_requirements,
        senior_acess_group_ids: data.senior_acess_group_ids ? Array.from(data.senior_acess_group_ids).reduce((acc, item) => {
          acc.push(item['value'])
          return acc
        }, []) : []

      }
    }

    if(SCOPE_ID && !isCloning) await updateService(body);
    else await createService(body);
    setPageLoading(false);
  }

  const setCheckboxValues = (items, condition) => {
    items.map(item => {
      setValue(slugifyField(item.name), condition)
    })
  }

  const parseStringToDate = (date) => {
    let newDate

    try {
      const splitedDate = date.split('/')
      newDate = new Date(+splitedDate[2], splitedDate[1] - 1, +splitedDate[0])
    } catch (error) {
      newDate = null
    }

    return newDate
  }

  const retriveScopeService = async () => {
    const { data } = await ServiceScopeService.retrive(SCOPE_ID);
    return data;
  }

  const cloneScopeService = async () => {
    const { data } = await ServiceScopeService.clone(SCOPE_ID);
    return data;
  }

  // função responsável por receber e setar dados quando o escopo está sendo editado/liberado
  const loadData = async (data) => {
    setValue('vivix_number', data.vivix_number);
    setValue('title', data.title);
    setDate(parseStringToDate(data.date))
    setValue('requester', data.requester);
    setValue('phone', data.phone);
    setValue('revision', data.revision);
    setValue('sector', data.sector);
    setValue('email', data.email);
    setValue('status', data.status);
    if (data.area) setValue('area_id', { value: data.area.id, label: data.area.name });
    if (data.unit) setValue('unit_id', { value: data.unit.id, label: data.unit.name });
    setValue('objective', data.provide_scope.objective);
    setCheckboxValues(data.provide_scope.contract_type, true);
    setValue('contract_type_note', data.provide_scope.contract_type_note);
    setValue('service_description', data.provide_scope.service_description);
    setDocumentosTecnicos(data.provide_scope.not_applicable_technical_document)
    setCheckboxValues(data.provide_scope.technical_document, true);
    setValue('service_note', data.provide_scope.service_note);
    setCheckboxValues(data.provide_scope.complementary_service, true);
    setValue('complementary_service_note', data.provide_scope.complementary_service_note);
    setCheckboxValues(data.provide_scope.technical_proposal_requirement, true);
    setValue('other_technical_proposal_requirement', data.provide_scope.other_technical_proposal_requirement);
    setCheckboxValues(data.provide_scope.service_requirement, true);
    setValue('other_service_requirement', data.provide_scope.other_service_requirement);
    setCheckboxValues(data.provide_scope.responsibility, true);
    setValue('other_responsibility', data.provide_scope.other_responsibility);
    setValue('meal_id', String(data.provide_scope.meal_id));

    /* Função resposável por checar se a data inicial vinda do banco de dados
    se enquadra na regra de serviso, data inicial deve ser maior >= a data atual*/
    const actuallyDate = parseISO(format(new Date(), 'yyyy-MM-dd'))
    if (data.provide_scope.initial_date != '' && data.provide_scope.initial_date != null){
      const initialDate = parseISO(data.provide_scope.initial_date.replaceAll('/', '-').split("-").reverse().join("-"))
      // se data inicial carregada for antes da data atual o campo permanece limpo
      if (isBefore(initialDate, actuallyDate)){ 
        Toast.error('Data inicial invalida, insira uma nova data para poder salvar as alterações')
      }else{ // se data se enquadrar a regra de negócio setar datas vindas do banco
        setValue('initial_date', parseStringToDate(data.provide_scope.initial_date));
      }
    }
    if (data.provide_scope.end_date != '' && data.provide_scope.end_date != null){
      const endDate = parseISO(data.provide_scope.end_date.replaceAll('/', '-').split("-").reverse().join("-"))
      // se data final carregada for antes da data atual o campo permanece limpo
      if (isBefore(endDate, actuallyDate)){ 
        Toast.error('Data final invalida, insira uma nova data para poder salvar as alterações')
      }else{ // se data se enquadrar a regra de negócio setar datas vindas do banco
        setValue('end_date', parseStringToDate(data.provide_scope.end_date));
      }
    }

    setValue('deadline', data.provide_scope.deadline);
    setValue('work_hours', data.provide_scope.work_hours);
    setValue('labor_type_id', String(data.provide_scope.labor_type_id));
    setValue('third_party_employees', data.provide_scope.third_party_employees);
    setCheckboxValues(data.provide_scope.closing_condition, true);
    setValue('closing_condition_note', data.provide_scope.closing_condition_note);
    setValue('closing_condition_taf_days', data.provide_scope.closing_condition_taf_days);
    setValue('warranty', data.provide_scope.warranty);
    setValue('reference_documents', data.provide_scope.reference_documents);
    setCheckboxValues(data.provide_scope.ssma_requirements, true);
    setValue('other_ssma_requirements', data.provide_scope.other_ssma_requirements);
    setValue('senior_acess_group_ids', data.provide_scope.senior_acess_group_ids.reduce((acc, item) => {
      acc.push({ value: item.id, label: item.name })
      return acc
    }, []))

    handdleNoteRequired(contractTypes, 'contract_type_note')
    handdleNoteRequired(closingConditions, 'closing_condition_note')

    if (data.provide_scope.other_technical_proposal_requirement != '' && data.provide_scope.other_technical_proposal_requirement != null) setValue('requisitos_da_roposta_tecnica_checkbox', true)
    if (data.provide_scope.other_service_requirement != '' && data.provide_scope.other_service_requirement != null) setValue('requisitos_do_servico_checkbox', true)
    if (data.provide_scope.other_responsibility != '' && data.provide_scope.other_responsibility != null) setValue('responsibilities_checkbox', true)
    if (data.provide_scope.other_ssma_requirements != '' && data.provide_scope.other_ssma_requirements != null) setValue('ssmaRequirements_checkbox', true)
  }
 
  // função responsável por *resetar erros, mudar validações e definir tipo de ação 
  const resetRequired =  async () => {
    // Reconhecer campos com erros e limpar os mesmos
    const fields = Object.keys(getValues())
    clearErrors(fields)

    await chageRequire('contract_type_note', false)
    await chageRequire('service_description', false)
    await chageRequire('closing_condition_note', false)
  }

  const handleCloneScopeService = async () => {
    if (isCloning) return;
    try {
      Toast.success(`Clonando escopo de serviço #${SCOPE_ID}.`)
      history.push(`/colaborador/escopos-de-servico/clone/${SCOPE_ID}`)
    } catch (error) {
      Toast.error('Impossivel clonar esse escopo de serviço.')
    }
  }

  const createService = async (body) => {
    try {
      const { data } = await ServiceScopeService.create(body)
      if (isSaving) {
        Toast.success('Escopo de serviço salvo com sucesso!')
        history.replace(`/colaborador/escopos-de-servico/${data.id}`)
      } else {
        Toast.success('Escopo de serviço criado com sucesso!')
        history.replace(`/colaborador/escopos-de-servico/${data.id}/parecer-ssma`)
      }
    } catch (error) {
      const { response: { data } } = error;
      if (data.message) {
        Toast.error(data.message);
        return;
      }
      if (data.errors?.non_field_errors) {
        Toast.error(data.errors.non_field_errors[0]);
        return;
      }
      Toast.error('Erro ao criar o Escopo de Serviço. Tente novamente.');
    }
    setLoadingMessage(null);
    setPageLoading(false);
  }

  const updateService = async (body) => {
    try {
      const { data } = await ServiceScopeService.update(body, SCOPE_ID)
      if (isSaving) {
        Toast.success('Escopo de serviço atualizado com sucesso!')
      } else {
        Toast.success('Escopo de serviço criado com sucesso!')
        history.replace(`/colaborador/escopos-de-servico/${data.id}/parecer-ssma`)
      }
    } catch (error) {
      const { response: { data } } = error;
      if (data.message) {
        Toast.error(data.message);
        return;
      }
      if (data.errors?.non_field_errors) {
        Toast.error(data.errors.non_field_errors[0]);
        return;
      }
      Toast.error('Erro ao atualizar o Escopo de Serviço. Tente novamente.');
    }
    setLoadingMessage(null);
    setPageLoading(false);
  }

  // Função responsável por detectar e setar:
  // A data do escopo (ESCOPO DE SERVIÇO)
  // A data inicial (SEÇÃO 7)
  // A data final (SEÇÃO 7)
  const handleDatepicker = (date, id) => {
    if (id == 'date') setDate(date);
    else if (id == 'initial_date') setInitialDate(date);
    else if (id == 'end_date') setEndDate(date);
  }

  // Função responsável por recuperar e exibir determinado erro
  const toastyErrors = () => {
    if (!Object.keys(errors).length === 0) {
      Toast.error('Por favor, verifique os campos do formulario.')
    }
  }

  return (
    <section className='new-escopo-de-servico-page'>
      {
        pageLoading && (
          <div className='page-loading'>
            <Loading message={loadingMessage} />
          </div>
        )
      }
      <form className={`form fade-in ${pageLoading ? 'd-none' : ''}`} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className='escopo-de-servico-container'>
          <div className='header'>
            <div className='title'>
              ESCOPO DE SERVIÇO
            </div>
            <div className='image-container'>
              <img src={vivixLogoImage} />
            </div>
          </div>
          <div className='body'>
            <div className='form-row row'>
              <div className='item col-sm-12 col-md-6'>
                <label htmlFor='id' className='label disabled'>ID DO ESCOPO</label>
                <input
                  id='id'
                  name='id'
                  className='input'
                  placeholder={(SCOPE_ID && !isCloning) ? SCOPE_ID : '-----'}
                  disabled
                />
              </div>
            </div>

            <div className='form-row row'>
              <div className='item col-sm-12 col-md-6'>
                <label
                  className='label'
                  htmlFor='vivix_number' 
                  >Nº VIVIX 
                  <AiFillQuestionCircle  
                  onMouseEnter={() => setExibirInfo(true)} 
                  onMouseLeave={() => setExibirInfo(false)} 
                  className='icon-vivix'/>
                </label>
                {exibirInfo && (
                  <div className='open-vivix'>
                    <div className='popup-vivix'>
                      <div className='text-vivix'>
                        Aplicável apenas para o departamento de engenharia. Código gerado pelo Projetwise.<p/>
                      </div>
                    </div>
                  </div>
                )}
                <input
                  id='vivix_number'
                  name='vivix_number'
                  className='input'
                  placeholder='Número Vivix'
                  defaultValue=''
                  ref={register({
                    validate: { value: value => isVivixNumberValid(value, isSaving) || "Nº Vivix deve possuir o formato: EEUU_LYY_TTAAA_DZ_NNNN_RR" }
                  })}
                />
                {
                  errors.vivix_number && (
                    <span className='error'>* {errors.vivix_number.message}</span>
                  )
                }
              </div>
              <div className='item col-sm-12 col-md-6'>
                <label htmlFor='revision' className='label'>REVISÃO*</label>
                <input
                  id='revision'
                  name='revision'
                  className='input'
                  placeholder='Revisão'
                  defaultValue=''
                  ref={register(
                    { required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }
                  )}
                />
                {
                  errors.revision && (
                    <span className='error'>* {errors.revision.message}</span>
                  )
                }
              </div>
            </div>

            <div className='form-row row'>
              <div className='item col-sm-12 col-md-6'>
                <label htmlFor='title' className='label'>TÍTULO*</label>
                <input
                  id='title'
                  name='title'
                  className='input'
                  placeholder='Título'
                  defaultValue=''
                  ref={register(
                    { required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }
                  )}
                />
                {
                  errors.title && (
                    <span className='error'>* {errors.title.message}</span>
                  )
                }
              </div>
              <div className='item col-sm-12 col-md-6'>
                <label htmlFor='date' className='label'>DATA</label>
                <CalendarInput
                  id='date'
                  name='date'
                  className='input'
                  placeholder='Selecione uma data'
                  initialValue={date}
                  minDate={new Date()}
                  onChange={date => handleDatepicker(date, 'date')}
                  component={
                    <input ref={register} />
                  }
                />
                {
                  errors.date && (
                    <span className='error'>* {errors.date.message}</span>
                  )
                }
              </div>
            </div>

            <div className='form-row row'>
              <div className='item col-sm-12 col-md-6'>
                <label htmlFor='unidade' className='label'>UNIDADE*</label>
                <Controller
                  id='unit_id'
                  name='unit_id'
                  placeholder='Unidade'
                  as={FormSelect}
                  options={units}
                  control={control}
                  rules={{ required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }}
                />
                {
                  errors.unit_id && (
                    <span className='error'>* {errors.unit_id.message}</span>
                  )
                }
              </div>
              <div className='item col-sm-12 col-md-6'>
                <label htmlFor='area' className='label'>ÁREA*</label>
                <Controller
                  id='area_id'
                  name='area_id'
                  placeholder='Área'
                  as={FormSelect}
                  options={areas}
                  control={control}
                  rules={{ required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }}
                />
                {
                  errors.area_id && (
                    <span className='error'>* {errors.area_id.message}</span>
                  )
                }
              </div>
            </div>

            <div className='form-row row'>
              <div className='item col-sm-12 col-md-6'>
                <label htmlFor='requester' className='label'>SOLICITANTE*</label>
                <input
                  id='requester'
                  name='requester'
                  className='input'
                  placeholder='Solicitante'
                  defaultValue=''
                  ref={register(
                    { required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }
                  )}
                />
                {errors.requester && (<span className='error'>* {errors.requester.message}</span>)}
              </div>
              <div className='item col-sm-12 col-md-6'>
                <label htmlFor='sector' className='label'>DEPARTAMENTO*</label>
                <input
                  id='sector'
                  name='sector'
                  className='input'
                  placeholder='Departamento'
                  defaultValue=''
                  ref={register(
                    { required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }
                  )}
                />
                {
                  errors.sector && (
                    <span className='error'>* {errors.sector.message}</span>
                  )
                }
              </div>
            </div>

            <div className='form-row row'>
              <div className='item col-sm-12 col-md-6'>
                <label htmlFor='phone' className='label'>TELEFONE*</label>
                <input
                  id='phone'
                  name='phone'
                  className='input'
                  placeholder='Telefone'
                  defaultValue=''
                  ref={register(
                    { required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }
                  )}
                />
                {
                  errors.phone && (
                    <span className='error'>* {errors.phone.message}</span>
                  )
                }
              </div>
              <div className='item col-sm-12 col-md-6'>
                <label htmlFor='email' className='label'>E-MAIL DO GESTOR DO CONTRATO*</label>
                <input
                  id='email'
                  type='email'
                  name='email'
                  title='Digite um endereço de email válido.'
                  className='input'
                  placeholder='E-mail'
                  defaultValue=''
                  ref={register(
                    { required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }
                  )}
                />
                {
                  errors.email && (
                    <span className='error'>* {errors.email.message}</span>
                  )
                }
              </div>
            </div>

          </div>
        </div>
        <div className='escopo-de-servico-fornecimento'>
          <div className='header'>
            ESCOPO DO FORNECIMENTO
          </div>
          <div className='body'>

            {/* SEÇÃO 1 */}
            <div className='section'>
              <div className='title'>1. Objetivo*</div>
              <div className='section-row'>
                <textarea
                  id='objective'
                  name='objective'
                  className='textarea'
                  defaultValue=''
                  ref={register(
                    { required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }
                  )}
                />
                {
                  errors.objective && (
                    <span className='error'>* {errors.objective.message}</span>
                  )
                }
              </div>
            </div>

            {/* SEÇÃO 2 */}
            <div className='section'>
              <div className='title'>2. Tipo de Contrato</div>
              {
                Array.isArray(contractTypes) && contractTypes.map(item => (
                  <div className='section-row checkbox' key={item.id}>
                    <label className='label' htmlFor={slugifyField(item.name)}>
                      <span className='title'>{item.name}</span>
                      <span className='text'>{item.description}</span>
                    </label>
                    <input
                      id={slugifyField(item.name)}
                      name={slugifyField(item.name)}
                      type='checkbox'
                      className='checkbox'
                      placeholder='E-mail'
                      defaultValue=''
                      ref={register}
                      onChange={() => handdleNoteRequired(contractTypes, 'contract_type_note')}
                    />
                  </div>

                ))
              }
              {/* <div className='section-row'>
                <div className='tooltip'>
                  <AiOutlineInfoCircle size={'0.9rem'} className='icon'/>
                  <span className='text'>
                    Caso nenhum item acima seja selecionado, o campo de nota se torna obrigatório
                  </span>
                </div>
              </div> */}
              <div className='section-row'>
                <div className='label'>Nota</div>
                <CKClassic
                  className='ck-input'
                  inputName={'contract_type_note'}
                  getValues={getValues}
                  setValue={setValue}
                />
                {
                  errors.contract_type_note && (
                    <span className='error'>* {errors.contract_type_note.message}</span>
                  )
                }
              </div>
            </div>

            {/* SEÇÃO 3 */}
            <div className='section'>
              <div className='title'>3. Serviço*</div>
              <div className='section-row'>
                <div className='label'>3.1. Descrição do Serviço*</div>
                <div className={`modal ${serviceDescription ? 'active' : ''}`}>
                  <div
                    className={`overlay ${serviceDescription ? 'active' : ''}`}
                    onClick={handleServiceDescription}>
                  </div>
                  <div className={`expand-icon-container ${serviceDescription ? 'expanded' : ''}`}>
                    <BiExpand
                      className='icon'
                      onClick={handleServiceDescription}
                    />
                  </div>
                  <div className={`modal-container ${serviceDescription ? 'active' : ''}`}>
                    <CKClassic
                      className='ck-input'
                      inputName={'service_description'}
                      getValues={getValues}
                      setValue={setValue}
                    />
                  </div>
                </div>
                {
                  !serviceDescription && errors.service_description && (
                    <span className='error'>* {errors.service_description.message}</span>
                  )
                }
              </div>
              <div className='section-row'>
                <div className='label'>3.2. Documentos técnicos a serem fornecidos: (Selecione ao menos uma opção)*</div>
              </div>
              <div className='section-row checkbox'>
                <label htmlFor='documentos_tecnicos_radio' className='label'>Não aplicável</label>
                <input
                  id='documentos_tecnicos_radio'
                  className='radio'
                  type='radio'
                  checked={documentosTecnicos}
                  onClick={handlDocumentosTecnicos}
                />
              </div>
              <div className='section-row checkbox-group wrap'>
                {
                  Array.isArray(technicalDocuments) && technicalDocuments.map(item => (
                    <div className='item col-sm-12 col-md-4' key={item.id}>
                      <div className='input-container'>
                        <label className='label' htmlFor={slugifyField(item.name)}>
                          <span className='title'>{item.name}</span>
                        </label>
                        <input
                          id={slugifyField(item.name)}
                          name={slugifyField(item.name)}
                          type='checkbox'
                          disabled={documentosTecnicos}
                          className='checkbox'
                          onChange={() => onChangCheckBoxCombo(technicalDocuments, handlDocumentosTecnicosRequired)}
                          ref={register(
                            { required: { value: handlDocumentosTecnicosRequired() && !isSaving, message: 'Esse campo é obrigatório.' } }
                          )}
                        />
                      </div>
                      {
                        errors[slugifyField(item.name)] && (
                          <span className='error'>* {errors[slugifyField(item.name)].message}</span>
                        )
                      }
                    </div>
                  ))
                }
              </div>
              <div className='section-row'>
                <div className='label'>Nota</div>
                <CKClassic
                  className='ck-input'
                  inputName={'service_note'}
                  getValues={getValues}
                  setValue={setValue}
                />
                {
                  errors.service_note && (
                    <span className='error'>* {errors.service_note.message}</span>
                  )
                }
              </div>
            </div>

            {/* SEÇÃO 4 */}
            <div className='section'>
              <div className='title'>4. Serviços Complementares</div>
              <div className='section-row checkbox-group wrap'>
                {
                  Array.isArray(complementaryServices) && complementaryServices.map(item => (
                    <div className='item col-sm-12 col-md-4' key={item.id}>
                      <div className='input-container'>
                        <label className='label' htmlFor={slugifyField(item.name)}>
                          <span className='title'>{item.name}</span>
                        </label>
                        <input
                          id={slugifyField(item.name)}
                          name={slugifyField(item.name)}
                          type='checkbox'
                          className='checkbox'
                          ref={register()}
                        />
                      </div>
                      {
                        errors[slugifyField(item.name)] && (
                          <span className='error'>* {errors[slugifyField(item.name)].message}</span>
                        )
                      }
                    </div>
                  ))
                }
              </div>
              <div className='section-row'>
                <div className='label'>Nota</div>
                <CKClassic
                  className='ck-input'
                  inputName={'complementary_service_note'}
                  getValues={getValues}
                  setValue={setValue}
                />
                {
                  errors.complementary_service_note && (
                    <span className='error'>* {errors.complementary_service_note.message}</span>
                  )
                }
              </div>
            </div>

            {/* SEÇÃO 5 */}
            <div className='section'>
              <div className='title'>5. Responsabilidades da Contratada</div>
              <div className='section-row'>
                <div className='label'>5.1. Requisitos da Proposta Técnica</div>
              </div>
              <div className='section-row'>
                <div className='label'>O fornecedor deverá informar na proposta técnica:</div>
              </div>
              {
                Array.isArray(technicalProposalRequirements) && technicalProposalRequirements.map(item => (
                  <div className='section-row checkbox' key={item.id}>
                    <label className='label' htmlFor={slugifyField(item.name)}>
                      <span className='title'>{item.name}</span>
                    </label>
                    <input
                      id={slugifyField(item.name)}
                      name={slugifyField(item.name)}
                      type='checkbox'
                      className='checkbox'
                      defaultValue=''
                      ref={register}
                    />
                  </div>
                ))
              }
              <div className='section-row checkbox'>
                <label className='label' htmlFor='requisitos_da_roposta_tecnica_checkbox'>
                  <span className='title other'>
                    Outros:
                    <input
                      disabled={!requisitos_da_roposta_tecnica_checkbox}
                      id='other_technical_proposal_requirement'
                      name='other_technical_proposal_requirement'
                      type='text'
                      className='input'
                      defaultValue=''
                      ref={register({
                        required: { value: requisitos_da_roposta_tecnica_checkbox, message: 'Esse campo é obrigatório.' }
                      })}
                    />
                    {
                      errors.other_technical_proposal_requirement && (
                        <span className='error'>* {errors.other_technical_proposal_requirement.message}</span>
                      )
                    }
                  </span>
                </label>
                <input
                  id='requisitos_da_roposta_tecnica_checkbox'
                  name='requisitos_da_roposta_tecnica_checkbox'
                  type='checkbox'
                  className='checkbox'
                  defaultValue=''
                  ref={register}
                />
              </div>

              <div className='section-row'>
                <div className='label'>5.2. Requisitos do Serviço*</div>
              </div>
              <div className='section-row'>
                <div className='label'>A contratada deverá fornecer:</div>
              </div>
              {
                Array.isArray(serviceRequirements) && serviceRequirements.map(item => (
                  <div className='section-row checkbox' key={item.id}>
                    <label className='label' htmlFor={slugifyField(item.name)}>
                      <span className='title'>{item.name}</span>
                      {
                        errors[slugifyField(item.name)] && (
                          <span className='error'>* {errors[slugifyField(item.name)].message}</span>
                        )
                      }
                    </label>
                    <input
                      id={slugifyField(item.name)}
                      name={slugifyField(item.name)}
                      type='checkbox'
                      className='checkbox'
                      defaultValue=''
                      onChange={() => onChangCheckBoxCombo(serviceRequirements, handleRequisitosDoServicoRequired)}
                      ref={register(
                        { required: { value: handleRequisitosDoServicoRequired() && !isSaving, message: 'Esse campo é obrigatório.' } }
                      )}
                    />
                  </div>
                ))
              }
              <div className='section-row checkbox'>
                <label className='label' htmlFor='requisitos_do_servico_checkbox'>
                  <span className='title other'>
                    Outros:
                    <input
                      disabled={!requisitos_do_servico_checkbox}
                      id='other_service_requirement'
                      name='other_service_requirement'
                      type='text'
                      className='input'
                      defaultValue=''
                      ref={register(
                        { required: { value: requisitos_do_servico_checkbox && !isSaving, message: 'Esse campo é obrigatório.' } }
                      )}
                    />
                    {
                      errors.other_service_requirement && (
                        <span className='error'>* {errors.other_service_requirement.message}</span>
                      )
                    }
                  </span>
                </label>
                <input
                  id='requisitos_do_servico_checkbox'
                  name='requisitos_do_servico_checkbox'
                  type='checkbox'
                  className='checkbox'
                  defaultValue=''
                  onChange={() => onChangCheckBoxCombo(serviceRequirements, handleRequisitosDoServicoRequired)}
                  ref={register}
                />
              </div>

            </div>

            {/* SEÇÃO 6 */}
            <div className='section'>
              <div className='title'>6. Responsabilidades Vivix*</div>
              <div className='section-row'>
                <div className='label'>A Vivix deverá fornecer:</div>
              </div>
              {
                Array.isArray(responsibilities) && responsibilities.map(item => (
                  <div className='section-row checkbox' key={item.id}>
                    <label className='label' htmlFor={slugifyField(item.name)}>
                      <span className='title'>{item.name}</span>
                      {
                        errors[slugifyField(item.name)] && (
                          <span className='error'>* {errors[slugifyField(item.name)].message}</span>
                        )
                      }
                    </label>
                    <input
                      id={slugifyField(item.name)}
                      name={slugifyField(item.name)}
                      type='checkbox'
                      className='checkbox'
                      defaultValue=''
                      onChange={() => onChangCheckBoxCombo(responsibilities, handleResponsabilidadesVivixRequired)}
                      ref={register(
                        { required: { value: handleResponsabilidadesVivixRequired() && !isSaving, message: 'Esse campo é obrigatório.' } }
                      )}
                    />
                  </div>
                ))
              }
              <div className='section-row checkbox'>
                <label className='label' htmlFor='responsibilities_checkbox'>
                  <span className='title other'>
                    Outros:
                    <input
                      disabled={!responsibilities_checkbox}
                      id='other_responsibility'
                      name='other_responsibility'
                      type='text'
                      className='input'
                      defaultValue=''
                      ref={register({
                        required: { value: responsibilities_checkbox && !isSaving, message: 'Esse campo é obrigatório.' }
                      })}
                    />
                    {
                      errors.other_responsibility && (
                        <span className='error'>* {errors.other_responsibility.message}</span>
                      )
                    }
                  </span>
                </label>
                <input
                  id='responsibilities_checkbox'
                  name='responsibilities_checkbox'
                  type='checkbox'
                  className='checkbox'
                  defaultValue=''
                  onChange={() => onChangCheckBoxCombo(responsibilities, handleResponsabilidadesVivixRequired)}
                  ref={register}
                />
              </div>

              <div className='section-row'>
                <div className='label'>Refeição*:</div>
              </div>
              {
                Array.isArray(meals) && meals.map(item => (
                  <div className='section-row checkbox' key={item.id}>
                    <label htmlFor={slugifyField(item.name)} className='label'>
                      {item.name}
                      {
                        errors.meal_id && (
                          <span className='error'>* {errors.meal_id.message}</span>
                        )
                      }
                    </label>
                    <input
                      id={slugifyField(item.name)}
                      name='meal_id'
                      value={item.id}
                      className='radio'
                      type='radio'
                      ref={register(
                        { required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }
                      )}
                    />
                  </div>
                ))
              }
            </div>

            {/* SEÇÃO 7 Área das datas e prazos*/ } 
            <div className='section'>
              <div className='title'>7. Prazo e Período da prestação do Serviço</div>
              <div className='section-row'>
                <div className='label'>7.1. Data início*</div>
              </div>
              <div className='section-row'>
                <Controller
                  control={control}
                  name='initial_date'
                  defaultValue={initialDate}
                  rules={{ // Definir se determinado campo é obrigatorio ou não
                    required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' }
                  }}
                  render={(field) => (
                    <>
                      <CalendarInput
                        required={!!errors.initial_date}
                        id='initial_date'
                        initialValue={initialDate}
                        minDate={new Date()}
                        maxDate={endDate}
                        onChange={(date) => {
                          field.onChange(date)
                          handleDatepicker(date, 'initial_date')
                        }}
                        component={
                          <input
                            ref={field.ref}
                          />
                        }
                      />
                      {!!errors.initial_date ?
                          <span className='error'>* {errors.initial_date.message} </span> :
                        null
                      }
                    </>
                  )}
                />
              </div>
              <div className='section-row'>
                <div className='label'>7.2. Data fim*</div>
              </div>
              <div className='section-row'>
              <Controller
                control={control}
                name='end_date'
                defaultValue={endDate}
                rules={{
                  required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' }
                }}
                render={(field) => (
                  <>
                    <CalendarInput
                      required={!!errors.end_date}
                      id='end_date'
                      initialValue={endDate}
                      minDate={initialDate || new Date()}
                      onChange={(date) => {
                        field.onChange(date)
                        handleDatepicker(date, 'end_date')
                      }}
                      component={
                        <input
                          ref={field.ref}
                        />
                      }
                    />
                    {!!errors.end_date ?
                        <span className='error'>* {errors.end_date.message} </span> :
                      null
                    }
                  </>
                )}
              />
              </div>
              <div className='section-row'>
                <div className='label'>7.3. Prazo de execução (em dias)</div>
              </div>
              <div className='section-row'>
                <input
                  id='deadline'
                  name='deadline'
                  className='number'
                  type='number'
                  ref={register({ max: { value: 32767, message: 'Valor máximo permitido é 32767.' } })}
                  min='0'
                />
                {
                  errors.deadline && (
                    <span className='error'>* {errors.deadline.message}</span>
                  )
                }
              </div>
              <div className='section-row'>
                <div className='label'>7.4. Horário de trabalho*</div>
              </div>
              <div className='section-row'>
                <input
                  id='work_hours'
                  name='work_hours'
                  className='text'
                  type='text'
                  ref={register(
                    { required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }
                  )}
                  min='0'
                />
                <br/>
                {
                  errors.work_hours && (
                    <span className='error'>* {errors.work_hours.message}</span>
                  )
                }
              </div>
            </div>

            {/* SEÇÃO 8 */}
            <div className='section'>
              <div className='title'>8. Estimativa de mão de obra</div>
              <div className='section-row'>
                <div className='label'>8.1. Tipo de mão de obra*</div>
              </div>
              {
                Array.isArray(laborTypes) && laborTypes.map(item => (
                  <div className='section-row checkbox' key={item.id}>
                    <label htmlFor={slugifyField(item.name)} className='label'>
                      {item.name}
                      {
                        errors.labor_type_id && (
                          <span className='error'>* {errors.labor_type_id.message}</span>
                        )
                      }
                    </label>
                    <input
                      id={slugifyField(item.name)}
                      name='labor_type_id'
                      value={item.id}
                      className='radio'
                      type='radio'
                      ref={register(
                        { required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }
                      )}
                    />
                  </div>
                ))
              }
              <div className='section-row'>
                <div className='label'>8.2. Quantitativo de colaboradores terceiros</div>
              </div>
              <div className='section-row labor-quantity'>
                <input
                  id='third_party_employees'
                  name='third_party_employees'
                  className='number'
                  type='number'
                  ref={register}
                  min='0'
                />
                <span className='info'>
                  Preencher o campo informando quantos funcionário aproximados será necessário para realizar o serviço.
                </span>
              </div>
            </div>

            {/* SEÇÃO 9 */}
            <div className='section'>
              <div className='title'>9. Condições de Encerramento do Serviço*</div>
              {
                Array.isArray(closingConditions) && closingConditions.map(item => (
                  <div className='section-row checkbox' key={item.id}>
                    <label className='label' htmlFor={slugifyField(item.name)}>
                      <span className='title'>{item.name}</span>
                      {!item.description.includes('XXXX') ? <span className='text'>{item.description}</span> :
                        renderTAFDescription(item.description)
                      }
                      {
                        errors[slugifyField(item.name)] && (
                          <span className='error'>* {errors[slugifyField(item.name)].message}</span>
                        )
                      }
                    </label>
                    <input
                      id={slugifyField(item.name)}
                      name={slugifyField(item.name)}
                      type='checkbox'
                      className='checkbox'
                      placeholder='E-mail'
                      defaultValue=''
                      onChange={() => {
                        onChangCheckBoxCombo(closingConditions, handleClosingConditionsRequired)
                        handdleNoteRequired(closingConditions, 'closing_condition_note')
                      }}
                      ref={register(
                        { required: { value: !(getValues('closing_condition_note') != '') && handleClosingConditionsRequired() && !isSaving, message: 'Esse campo é obrigatório.' } }
                      )}
                    />
                  </div>
                ))
              }
              {/* <div className='section-row'>
                <div className='tooltip'>
                  <AiOutlineInfoCircle size={'0.9rem'} className='icon'/>
                  <span className='text'>
                    Caso nenhum item acima seja selecionado, o campo de nota se torna obrigatório
                  </span>
                </div>
              </div> */}
              <div className='section-row'>
                <div className='label'>Nota</div>
                <CKClassic
                  className='ck-input'
                  inputName={'closing_condition_note'}
                  getValues={getValues}
                  setValue={setValue}
                />
                {
                  errors.closing_condition_note && (
                    <span className='error'>* {errors.closing_condition_note.message}</span>
                  )
                }
              </div>
            </div>

            {/* SEÇÃO 10 */}
            <div className='section'>
              <div className='title'>10. Garantias*</div>
              <div className='section-row'>
                <textarea
                  id='warranty'
                  name='warranty'
                  className='textarea'
                  defaultValue=''
                  ref={register(
                    { required: { value: true && !isSaving, message: 'Esse campo é obrigatório.' } }
                  )}
                />
                {
                  errors.warranty && (
                    <span className='error'>* {errors.warranty.message}</span>
                  )
                }
              </div>
            </div>

            {/* SEÇÃO 11 */}
            <div className='section'>
              <div className='title'>11. Documentos de Referência</div>
              <CKClassic
                className='ck-input'
                inputName={'reference_documents'}
                getValues={getValues}
                setValue={setValue}
              />
            </div>

            {/* SEÇÃO 12 Área de escolha das atividades a serem realizadas*/}
            <div className='section'>
              <div className='title'>12. Requisitos de SSMA*</div>
              <div className='section-row'>
                <div className='label'>QUAIS ATIVIDADES ESPECIAIS A EMPRESA TERCEIRA CONTRATADA PRECISARÁ REALIZAR?</div>
              </div>
              <div className='section-row checkbox-group wrap'>
                {
                  Array.isArray(ssmaRequirements) && ssmaRequirements.map(item => (
                    <div className='item col-sm-12 col-md-4' key={item.id}>
                      <div className='input-container'>
                        <label className='label' htmlFor={slugifyField(item.name)}>
                          <span className='title'>{item.name}</span>
                        </label>
                        <input
                          id={slugifyField(item.name)}
                          name={slugifyField(item.name)}
                          type='checkbox'
                          defaultChecked={enableCheck(item.id)}
                          className='checkbox'
                          onChange={() => onChangCheckBoxCombo(ssmaRequirements, handleRequisitosSSMARadioRequired)}
                          ref={register(
                            { required: { value: handleRequisitosSSMARadioRequired() && !isSaving, message: 'Esse campo é obrigatório.' } }
                          )}
                        />
                      </div>
                      {
                        errors[slugifyField(item.name)] && (
                          <span className='error'>* {errors[slugifyField(item.name)].message}</span>
                        )
                      }
                    </div>
                  ))
                }
              </div>
              <div className='section-row checkbox'>
                <label className='label' htmlFor='ssmaRequirements_checkbox'>
                  <span className='title other'>
                    Outros:
                    <input
                      disabled={!ssmaRequirements_checkbox}
                      id='other_ssma_requirements'
                      name='other_ssma_requirements'
                      type='text'
                      className='input'
                      defaultValue=''
                      ref={register({
                        required: { value: ssmaRequirements_checkbox && !isSaving, message: 'Esse campo é obrigatório.' }
                      })}
                    />
                    {
                      errors.other_ssma_requirements && (
                        <span className='error'>* {errors.other_ssma_requirements.message}</span>
                      )
                    }
                  </span>
                </label>
                <input
                  id='ssmaRequirements_checkbox'
                  name='ssmaRequirements_checkbox'
                  type='checkbox'
                  className='checkbox'
                  defaultValue=''
                  onChange={() => onChangCheckBoxCombo(ssmaRequirements, handleRequisitosSSMARadioRequired)}
                  ref={register}
                />
              </div>
            </div>

            {/* SEÇÃO 13 Área responsável pelos botões de ações do escopo*/}
            <div className='section'>
              <div className='title'>13. Grupo de acesso Sênior</div>
              <div className='section-row'>
                <div style={{ width: '100%', maxWidth: '466px' }}>
                  <Controller
                    id='senior_acess_group_ids'
                    name='senior_acess_group_ids'
                    placeholder='Escolha um grupo'
                    isMulti={true}
                    as={FormSelect}
                    options={seniorAcessGroups}
                    control={control}
                  // rules={{ required: 'Esse campo é obrigatório.' }}
                  />
                  {
                    errors.senior_acess_group_ids && (
                      <span className='error'>* {errors.senior_acess_group_ids.message}</span>
                    )
                  }
                </div>
              </div>
              <div className='section-row'>
                <div className='tooltip'>
                  <AiOutlineInfoCircle size={'0.9rem'} className='icon' />
                  <span className='text'>
                    Você pode selecionar mais de uma opção.
                  </span>
                </div>
              </div>
            </div>

          </div>

          <div className='footer'>
            <div className='left'>
              <button
                type='button'
                className='button cancel'
                onClick={() => {
                  Toast.success('Alterações canceladas com sucesso.')
                  history.replace('/colaborador/escopos-de-servico')
                }}
              >
                Cancelar
              </button>
              {
                (SCOPE_ID && !isCloning) &&
                <button
                  type='button'
                  className='button cancel'
                  onClick={() => {
                    handleCloneScopeService()
                  }}
                >
                  Clonar
                </button>
              }
            </div>
            <div className='right'>
              {
                SCOPE_ID && ["hired", "canceled"].includes(getValues("status")) ? (
                  null
                ) : (
                  <>
                    <button type='submit' className='button save' onClick={ async (e) => {await setIsSaving(true); resetRequired(); toastyErrors(); handleSubmit()(); }}>
                      Salvar
                    </button>
                    <button type='submit' className='button realise' onClick={async (e) => { await setIsSaving(false);  toastyErrors();  handleSubmit()(); }} >
                      Liberar
                    </button>
                  </>
                )
              }
            </div>
          </div>

        </div>
      </form>
    </section>
  );
}

export default NewEscopoDeServico;
