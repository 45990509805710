import { toast } from 'react-toastify';

import './styles.scss'

const config = {
  position: toast.POSITION.BOTTOM_CENTER,
  hideProgressBar: true,
}

class Toast {
  static success(text) {
    toast.success(text, config)
  }

  static warning(text) {
    toast.warning(text, {...config, hideProgressBar: false })
  }

  static error(text) {
    toast.error(text, config)
  }

  static hide() {
    toast.dismiss();
  }
}

export default Toast;
