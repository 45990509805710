import React, { useEffect, useState } from 'react';

import './styles.scss';

import Toast from '../../Toast';
import { ServiceScopeService } from '../../../services';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';


const ConfirmLinkProviderModal = ({ setModal, setConfirmModal, infoProviderScope, linkProviderScope }) => {
    const { register, handleSubmit, errors } = useForm();
    const [submited, setSubmited] = useState(false);
    let history = useHistory();

    useEffect(() => {
        $('#purchase_order_number').mask('0000000000');
        $('#scope_service').mask('0#');
    }, []);

    const linkScopeServiceToProvider = async (body) => {
        if (submited) {
            console.log(body)
            try {
                const { data } = await ServiceScopeService.linkProvider(body);
                Toast.success(data.message);
                setModal(false);
                // direciona para a tela inicial do fornecedor (o router força o refresh da página)
                history.push('/')
            } catch (err) {
                const { response: { data } } = err;
                let errors = data.error || { message: [data.message] } || {};
                Object.keys(errors).map(function (field) {
                    Toast.error(errors[field][0]);
                });
            }
        }

    }

    useEffect(() => {
        linkScopeServiceToProvider(linkProviderScope)
    }, [submited])

    const convertToLocaleDateStrin = (dateString) => {
        const date = new Date(dateString)
        return date.toLocaleDateString().toUpperCase()
    }

    return (
        <div className='link-scope-service'>
            <div className='header'>
                Confirmar escopo de serviço
            </div>
            <div className='body'>
                <div id='confirm-hook-form' className='link-scope-service-form'>              
                        <div className='scope-id'> ID DO ESCOPO: {linkProviderScope.scope_service.toUpperCase()}</div>
                        <div className='scope-title'> TÍTULO DA ATIVIDADE: {infoProviderScope.title.toUpperCase()}</div>
                        <div> GESTOR DO CONTRATO: {infoProviderScope.requester.toUpperCase()}</div>
                        <div> PERÍODO DA ATIVIDADE: {convertToLocaleDateStrin(infoProviderScope.initial_date)} - {convertToLocaleDateStrin(infoProviderScope.end_date)}</div>
                </div>
            </div>
            <div className='footer'>
                <button
                    onClick={() => setModal(false)}
                    className='btn cancel'
                >
                    Voltar
                </button>
                <button
                    on
                    onClick={() => setSubmited(true)}
                    className='btn ok'
                >
                    Confirmar
                </button>
            </div>
        </div>
    );
}

export default ConfirmLinkProviderModal;
