import api from './api'


class VehicleService {
    static postVehicleStatus(body) {
        return api.post('vehicles/active/', body, {
          headers: {
            ...api.getAuthToken(),
          }
        });
    }
}

export default VehicleService;
