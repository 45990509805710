import api from './api'

class UserService {
    static postDriverProfile(body) {
        return api.post('users/drivers/profile/', {...body});
    }

    static patchDrivers(id, body) {
      return api.patch(`users/drivers/${id}/`, body, {
        headers: {
          ...api.getAuthToken(),
        }
      });
    }

    static getToken(data) {
        return api.post('token/', { ...data })
    }

    static getUserInfo() {
        return api.get('users/user_info/', {
            headers: {
              ...api.getAuthToken(),
            }
          })
    }

    static createPreRegister(data, user=false) {
      if (user) {
        return api.post('users/drivers/', { ...data }, {
          headers: {
            ...api.getAuthToken(),
          }
        });
      }
      return api.post('users/drivers/', { ...data })
    }

    static postProvideCompleteInfo(body) {
      return api.post('users/user_provider_complete_info/', body, {
        headers: {
          ...api.getAuthToken(),
        }
      });
  }
}

export default UserService;
