import React from 'react';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';

import { getPages } from '../../../helpers/pagination/utils';

import SearchInput from '../../SearchInput';

import './styles.scss';


const DocumentationTable = ({ data, currentPage, pageSize, handdleChangePage, onItemClick }) => {
  const handleNextPrevious = (link) => {
    let page = 1
    if (link.includes('page')) page = link.slice(-1);

    handdleChangePage(page)
  }

  const getStatusColor = (status) => {
    const statusColors = {
      waiting: '#FABC34',
      approved: '#00B26B',
      analyzing: '#545CD8',
      pendent: '#D92550',
      missing: '#ff8000',
    }

    return statusColors[status]
  }

  const getStatusLabel = (status) => {
    const statusColors = {
      waiting: 'Aguardando',
      approved: 'Aprovada',
      analyzing: 'Em análise',
      pendent: 'Pendente com restrições',
      missing: 'Pendente Dados Fornecedor',
    }

    return statusColors[status]
  }

  const periodLabel = (start, end) => {
    const convertDateBR = d => d?.split('-').reverse().join('/')

    start = convertDateBR(start)
    end = convertDateBR(end)
    return `${start} - ${end}`
  }

  return (
    <section className='ssma-documentation-table fade-in'>
      {/* <div className='header'>
        <div className='title'>
          ANÁLISE DE DOCUMENTAÇÕES DE ESCOPOS DE SERVIÇO
        </div>
        {
          showSearchBar && (
            <SearchInput
              setValue={ setSearch }
              placeholder='Pesquisar por gestor, contrato ou ID'
              onClick={() => handdleChangePage(1)}
            />
          )
        }
      </div> */}
      <div className='table-container'>
        <table className='table table-striped table-borderless'>
          <thead>
            <tr>
              <th scope='col'>ID</th>
              <th scope='col'>Título do escopo</th>
              <th scope='col'>Gestor do contrato</th>
              <th scope='col'>Período da atividade</th>
              <th scope='col'>Tipos de documentação</th>
              <th scope='col'>Status</th>
              <th scope='col'>Analisado por</th>
            </tr>
          </thead>
          <tbody>
            {
              data?.results && data.results.map((item, index) => (
                <tr className='fade-in item' key={item.id} onClick={() => onItemClick(item.id)}>
                  <th className='pk' scope='row'>{item.id}</th>
                  <th className='pk scope-title' scope='row' style={{ textAlign: 'left' }}>{item.title}</th>
                  <th className='pk' scope='row'>{item.requester}</th>
                  <td>{periodLabel(item.initial_date, item.end_date)}</td>
                  <td className='description'>{item.type_documents}</td>
                  <td className='status'>
                    <div
                      style={{ backgroundColor: getStatusColor(item.status) }}
                      className='status-container'
                    >
                      {getStatusLabel(item.status)}
                    </div>
                  </td>
                  <td>{item.ssma_approved_by}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className='paginator-container'>
        <nav>
          <ul className='pagination'>
            <li className={`page-item ${data.previous ? '' : 'disabled'}`}>
              <a
                className='page-link'
                onClick={() => handleNextPrevious(data.previous)}
              >
                <div className='item-right'>
                  <TiChevronLeft />
                  <p>Anterior</p>
                </div>
              </a>
            </li>
            {
              getPages(data.count, pageSize) && getPages(data.count, pageSize).map(page => (
                <li
                  key={page}
                  onClick={() => handdleChangePage(page)}
                  className={`page-item ${page == currentPage ? 'active' : ''} `}>
                  <a className='page-link'>{page}</a>
                </li>
              ))
            }
            <li className={`page-item ${data.next ? '' : 'disabled'}`}>
              <a
                className='page-link'
                onClick={() => { handleNextPrevious(data.next) }}
              >
                <div className='item-right'>
                  <p>Próximo </p>
                  <TiChevronRight />
                </div>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
}

export default DocumentationTable
