import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { stringToDate, dateToString } from '../../helpers/forms/convert';
import { ProviderService, ServiceScopeService } from '../../services';

import CalendarInput from '../../components/CalendarInput';
import Loading from '../../components/Loading';
import ProviderScopeSummary from '../../components/ProviderScopeSummary';
import Toast from '../../components/Toast';

import { MdKeyboardArrowRight } from 'react-icons/md';

import './styles.scss';

const EmployeeDetail = ({ employee }) => {
  const [open, setOpen] = useState(false);
  const [ssmaRequirements, setSsmaRequirements] = useState([]);

  useEffect(() => {
    let employeeSsmaRequirements = employee.ssma_requirements;
    employeeSsmaRequirements = employeeSsmaRequirements.filter(
      (requirement) => (
        !['Documentos obrigatórios', 'Documentos para descarte de resíduos']
        .includes(requirement.name)
      )
    )
    setSsmaRequirements(employeeSsmaRequirements);
  }, [])

  const getEmployeeType = (type) => {
    if(type == 'own_employee') return 'Próprio';
    return 'Terceiro';
  }

  return (
    <section className='employee-detail-container'>
      <div
        className='header row'
        onClick={() => setOpen(!open)}
      >
        <div className='employee-name col-md-6'>
          <div className='icon'>
            <MdKeyboardArrowRight className={open ? 'rotate' : ''}/>
          </div>
          <span>{employee.employee_provider.name}</span>
        </div>
        <div className='vivix-access col-md-6'>
          Acesso Vivix: {employee.employee_provider.access_permitted}
        </div>
      </div>
      {
        open ? (
          <>
            <div className='employee-data row'>
              <div className='left col-md-6'>
                <span>CPF: {employee.employee_provider.cpf}</span>
                <span>FUNÇÃO: {employee.employee_provider.office}</span>
                <span>TELEFONE: {employee.employee_provider.phone}</span>
              </div>
              <div className='right col-md-6'>
                <span>
                  DATA DE ADMISSÃO: {employee.employee_provider.admission_date}
                </span>
                <span>
                  TIPO DE FUNCIONÁRIO: {getEmployeeType(employee.employee_provider.employee_type)}
                </span>
                <span>
                  SUGESTÃO DE DATA PARA INTEGRAÇÃO: {employee.suggestion_integration_date}
                </span>
              </div>
            </div>
            <div className='employee-activities'>
              <span>ATIVIDADES:</span>
              <div className='activities'>
                {
                  ssmaRequirements && ssmaRequirements.map((requirement, index) => (
                    <div
                      key={`employee-${employee.id}-activity-${index}`}
                    >
                      {requirement.name}
                    </div>
                  ))
                }
              </div>
            </div>
          </>
        ) : null
      }
    </section>
  )
}

const HiredScopeDetail = ({ match }) => {
  let history = useHistory();
  const [hiredScopeData, setHiredScopeData] = useState(null);
  const [providerEmployees, setProviderEmployees] = useState(null);
  const [initialDate, setInitialDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errorField, setErrorField] = useState('');

  const getHiredScopeData = async () => {
    try {
      const { data } = await ProviderService.getHiredScopeData(match.params.id);
      setHiredScopeData(data);

      let scopeInitialDate = data.initial_date != 'Indefinido' ? data.initial_date : '';
      setInitialDate(scopeInitialDate);

      let scopeEndDate = data.end_date != 'Indefinido' ? data.end_date : '';
      setEndDate(scopeEndDate);

    } catch(error) {
      Toast.error('Não foi possível carregar as informações do Escopo de Serviço contratado.');
    }
  }

  const getProviderEmployees = async () => {
    try {
      const { data } = await ProviderService.listCollaborators(match.params.id);
      setProviderEmployees(data);
    } catch(error) {
      Toast.error('Não foi possível carregar os colaboradores do Escopo de Serviço contratado.');
    }
  }

  const updateScopePeriod = async () => {
    if(!initialDate) {
      setErrorField('initial');
      return;
    } else if(!endDate) {
      setErrorField('end');
      return;
    }

    setErrorField('');

    let body = {
      initial_date: initialDate,
      end_date: endDate
    }
    try {
      await ServiceScopeService.updateScopePeriod(match.params.id, body);
      Toast.success('Período da atividade atualizado com sucesso!');
    } catch(error) {
      const { response: { data } } = error;
      if(data.message) Toast.error(data.message);
      else {
        Object.keys(data).map((field) => {
          Toast.error(data[field][0]);
        });
      }
    }
  }

  useEffect(() => {
    getHiredScopeData();
    getProviderEmployees();
  }, [])

  return (
    <section className='hired-scope-detail-page'>
      {
        hiredScopeData ? (
          <>
            <div className='page-header'>
              SERVIÇO CONTRATADO
            </div>
            <div className='page-content'>
              <div className='summary'>
                <ProviderScopeSummary data={hiredScopeData}/>
              </div>
              <div className='scope-period'>
                <span>PERÍODO DA ATIVIDADE*</span>
                <div className='date-selection'>
                  <div className='initial-date'>
                    <CalendarInput
                      id={'hired-scope-period-initial-date'}
                      className='date-selection-container'
                      placeholder='Data de início'
                      initialValue={initialDate ? stringToDate(initialDate) : ''}
                      onChange={(date) => {
                        setInitialDate(dateToString(date));
                        if(errorField == 'initial') setErrorField('');
                      }}
                      showPopperArrow={false}
                      popperPlacement="bottom-start"
                      popperModifiers={
                        {
                          position: 'absolute',
                          top: 0,
                          right: 0,
                        }
                      }
                    />
                  </div>
                  <span>&nbsp;&nbsp;&nbsp;a&nbsp;&nbsp;&nbsp;</span>
                  <div className='end-date'>
                    <CalendarInput
                      id={'hired-scope-period-end-date'}
                      className='date-selection-container'
                      minDate={initialDate && initialDate > new Date() ? stringToDate(initialDate) : new Date()}
                      placeholder='Data fim'
                      initialValue={endDate ? stringToDate(endDate) : ''}
                      onChange={(date) => {
                        setEndDate(dateToString(date));
                        if(errorField == 'end') setErrorField('');
                      }}
                      showPopperArrow={false}
                      popperPlacement="bottom-start"
                      popperModifiers={
                        {
                          position: 'absolute',
                          top: 0,
                          right: 0,
                        }
                      }
                    />
                  </div>
                </div>
                {
                  errorField ? (
                    <div className='error-field'>
                      Informe a data {errorField == 'initial' ? 'de início' : 'fim'}.
                    </div>
                  ) : null
                }
              </div>
              <div className='employees-container'>
                <span>Colaboradores</span>
                {
                  providerEmployees && providerEmployees.map((employee, index) => (
                    <EmployeeDetail
                      key={`provider-employee-detail-${index}`}
                      employee={employee}
                    />
                  ))
                }
              </div>
            </div>
            <div className='page-footer'>
              <button
                className='btn'
                onClick={() => history.goBack()}
              >
                Cancelar
              </button>
              <button
                className='btn confirm'
                onClick={updateScopePeriod}
              >
                Salvar
              </button>
            </div>
          </>
        ) : (
          <div className='load-container'>
            <Loading/>
          </div>
        )
      }
    </section>
  )
}

export default HiredScopeDetail;