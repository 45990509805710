import React from 'react';

import './styles.scss';

const Loading = ({ message }) => {
  return (
    <div className='lds-ellipsis'><label className='loading-message'>{ null || message}</label><div></div><div></div><div></div><div></div></div>
  )
}

export default Loading
