import React, { useState, useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import Toast from '../../../components/Toast';

import { DocumentManagementService } from '../../../services'

import "./styles.scss";
import SsmaRequirementDocuments from "./SsmaRequirementDocuments";

const CollaboratorDocumentation = ({
  employee,
  ssmaRequirements,
  scopeId,
  shouldUpdateData,
  setModal,
  setModalTitle,
  setModalBody,
  setModalButtons
}) => {
  const [collaboratorSectionOpen, setCollaboratorSectionOpen] = useState(false);
  const [companyDocsOk, setCompanyDocsOk] = useState(false)

  useEffect(() => {
    checkCompanyDocs()
  }, [])

  const checkCompanyDocs = async (setDocuments) => {
    try {
      const { data } = await DocumentManagementService.getProviderDocumentationsRequired()
      data.map((item, index) => {
        if (item.file !== null) {
          setCompanyDocsOk(true)
        } else {
          setCompanyDocsOk(false)
        }
      })
    } catch (error) {
      console.log('ERROR DOCUMENTS REQUIRED', error)
    }
  }

  const checkUploadedDocuments = () => {
    Toast.warning("Aguarde, validando documentos....");

    let documentsErrors = [];

    ssmaRequirements[0].documents.forEach((document) => {
      const employeeDocument = employee.documents.find((ed) => ed.name === document.name);

      if (employeeDocument) {
        if (!employeeDocument.file) {
          documentsErrors.push(document.name);
        }
      } else {
        documentsErrors.push(document.name);
      }
    });

    if (documentsErrors.length > 0) {
      Toast.hide();

      if (documentsErrors.length > 1) {
        Toast.error(`Os documentos não foram enviados ${documentsErrors.join(", ")}`);
      } else {
        Toast.error(`O documento não foi enviado ${documentsErrors[0]}`);
      }
    } else if (!companyDocsOk) {
      Toast.error(`Os documentos da empresa não foram enviados`);
    } else {
      Toast.hide();
      Toast.success("Todos os documentos foram enviados e validados com sucesso");
    }
  }

  return (
    <div className="collaborator-container">
      <div className="header" onClick={() => setCollaboratorSectionOpen(!collaboratorSectionOpen)}>
        <div className="icon-arrow">
          <MdKeyboardArrowRight className={collaboratorSectionOpen ? "rotate" : ""} />
        </div>
        <span className="title">Documentação | {employee.name} </span>
      </div>
      {
        collaboratorSectionOpen &&
          <div className="documentations">
            {
              ssmaRequirements && ssmaRequirements.map((ssmaRequirement, index) =>
                <SsmaRequirementDocuments
                  key={index}
                  employee={employee}
                  ssmaRequirement={ssmaRequirement}
                  scopeId={scopeId}
                  shouldUpdateData={shouldUpdateData}
                  setModal={setModal}
                  setModalTitle={setModalTitle}
                  setModalBody={setModalBody}
                  setModalButtons={setModalButtons}
                />
              )
            }
            <button className="success-btn" onClick={checkUploadedDocuments} type="button">Confirmar envio de documento</button>
          </div>
      }

    </div>
  )
}

export default CollaboratorDocumentation;
