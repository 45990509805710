import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { useForm } from "react-hook-form";

import { UserService } from "./../../services";
import Input from "../../components/Input";
import Toast from "../../components/Toast";

import "./styles.scss";

const FirstAccess = () => {
  let history = useHistory();

  const { register, handleSubmit, errors } = useForm();

  const doFirstAccess = async (form) => {
    try {
      await UserService.postProvideCompleteInfo(form);
      return [true];
    } catch (error) {
      const errorMsg = error?.response?.data.msg;
      if (errorMsg) return [false, errorMsg];
      return [false, "Não foi possível completar o cadastro!"];
    }
  };

  const onSubmit = async (data) => {
    data['cnpj'] = data['cnpj'].replace(/[^0-9]/g, '')
    const [login, msg] = await doFirstAccess(data);
    if (login) {
      Toast.success("Cadastro completado com sucesso!");
      history.push("/fornecedor/escopos-contratados");
    } else {
      Toast.error(msg);
    }
  };

  useEffect(() => {
    $("#cnpj").mask("99.999.999/9999-99");
    $("#phone_main").mask("+ZZZZZZZZZZZZZZZZZZ", {
      translation: {
        Z: {
          pattern: /[0-9]|[()-\s]/,
          optional: true,
        },
      },
    });
    $("#phone_ssma").mask("+ZZZZZZZZZZZZZZZZZZ", {
      translation: {
        Z: {
          pattern: /[0-9]|[()-\s]/,
          optional: true,
        },
      },
    });
    $("#phone_rh").mask("+ZZZZZZZZZZZZZZZZZZ", {
      translation: {
        Z: {
          pattern: /[0-9]|[()-\s]/,
          optional: true,
        },
      },
    });
    $("#phone_technical_area").mask("+ZZZZZZZZZZZZZZZZZZ", {
      translation: {
        Z: {
          pattern: /[0-9]|[()-\s]/,
          optional: true,
        },
      },
    });
  }, []);

  return (
    <section className="access-page">
      <div className="access-container">
        <div className="logo-wrapper">
          <img
            className="image"
            src={
              "https://gcb-gotervix-homolog.s3-us-west-2.amazonaws.com/static/images/logo.png"
            }
          />
        </div>
        <div className="form-wrapper">
          <div className="text-container">
            <h3 className="title">Complete seu cadastro</h3>
            <p className="text">
              Precisamos de mais algumas informações para contato
            </p>
          </div>
          <div className="form-container">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="input-container">
                <Input
                  name="email"
                  label="E-mail para notificações"
                  defaultValue=""
                  error={errors.email}
                  register={register({
                    required: "Esse campo é obrigatório.",
                  })}
                />
                <div className="alert-content">
                  <HiOutlineInformationCircle />
                  <p className="alert-email">
                    Favor inserir e-mail checado com frequência
                  </p>
                </div>
                <Input
                  name="cnpj"
                  label="CNPJ*"
                  //´*´ se required
                  defaultValue=""  //ref company login
                  // disabled
                  error={errors.cnpj}
                  register={register({
                    required: "Esse campo é obrigatório.",
                    //Verificar required
                  })}
                />
                <Input
                  name="phone_main"
                  label="Telefone (Principal)*"
                  defaultValue=""
                  error={errors.phone_main}
                  register={register({
                    required: "Esse campo é obrigatório.",
                  })}
                />
                <Input
                  name="phone_ssma"
                  label="Telefone (SSMA)"
                  defaultValue=""
                  error={errors.phone_ssma}
                  register={register()}
                />
                <Input
                  name="phone_rh"
                  label="Telefone (RH)"
                  defaultValue=""
                  error={errors.phone_rh}
                  register={register()}
                />
                <Input
                  name="phone_technical_area"
                  label="Telefone (Área Técnica)"
                  defaultValue=""
                  error={errors.phone_technical_area}
                  register={register()}
                />
                <p className="text">
                  As informações acima devem ser atualizadas a cada um ano
                </p>
              </div>
              <div className="submit-container">
                <input className="submit" type="submit" value="Concluir" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FirstAccess;
