import React, { useState, useEffect } from 'react';

import './styles.scss';

import { AiOutlineClose } from 'react-icons/ai';
import { FiSearch } from 'react-icons/fi';

const TableFilter = ({ data, handleChange }) => {
  const [menuActive, setMenuActive] = useState(false)
  const [tempItems, setTempItems] = useState([])
  const [items, setItems] = useState([])
  const [search, setSearch] = useState('')
  const [filtered, setFiltered] = useState(false)

  const parseItems = () => {
    let parsedData = data.map(item => {
      return {
        ...item,
        options: item.options.map(option => ({ ...option, selected: false }))
      }
    })
    setTempItems(parsedData)
    setItems(parsedData)
  }

  const selectItem = (op) => {
    let newItems = tempItems.map(item => {
      return {
        ...item,
        options: item.options.map(option => {
          if(op.id == option.id){
            return { ...option, selected: !option.selected }
          } else {
            return option
          }
        })
      }
    })
    setTempItems([...newItems])
  }

  const removeItem = (op) => {
    let newItems = items.map(item => {
      return {
        ...item,
        options: item.options.map(option => {
          if(op.id == option.id){
            return { ...option, selected: false }
          } else {
            return option
          }
        })
      }
    })
    setItems([...newItems]);
    setTempItems([...newItems]);
    setMenuActive(false);
  }

  const handdleClear = (e) => {
    e.preventDefault();
    parseItems();
    setSearch('')
    setMenuActive(false);
  }

  const handdleFilter = (e) => {
    e.preventDefault();

    setItems([...tempItems]);
    setFiltered(true)
    setMenuActive(false);
  }

  const handdleGetData = async () => {
    const body = {}

    items.map(section => {
      body[section.id] = section.options.filter(opt => opt.selected).map(opt => opt.id)
    })

    handleChange(body)
  }

  const handleChangeSerach = (event) => {
    setSearch(event.target.value)
  }

  const handleSearchFilter = (opition) => {
    if(search.length < 2) return true;

    let op = opition.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    let searchText = search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    return op.includes(searchText)
  }

  useEffect(() => {
    if(filtered) handdleGetData();
  }, [items])

  useEffect(() => {
    parseItems();
  }, [data])

  return (
    <div className='table-filter-component-wrapper'>
      <div className='service-scope-table-filter'>
        <button onClick={ () => setMenuActive(!menuActive) } className='label'>Filtros</button>
        {
          menuActive && (
            <form className='filter fade-in'>
              <div className='header'>
                <div className='input-container'>
                  <input
                    className='input'
                    placeholder='Buscar categoria'
                    value={ search }
                    onChange={ handleChangeSerach }
                  />
                  <FiSearch className='icon' />
                </div>
              </div>
              <div className='body'>
                {
                  Array.isArray(tempItems) && tempItems.map((item, index) => (
                    <div className='section' key={ index }>
                      <div className='title'>{ item.title }</div>
                      {
                        item.options && item.options.map(option => {
                          if(handleSearchFilter(option.name)) return (
                            <span
                              key={ option.id }
                              className={`item ${option.selected ? 'selected' : ''}`}
                              onClick={ ()=> selectItem(option) }
                            >
                              { option.name }
                            </span>
                          )
                          else return null
                        })
                      }
                    </div>
                  ))
                }
              </div>
              <div className='footer'>
                <button className='clear btn' onClick={ handdleClear }>Limpar</button>
                <button className='filter btn' onClick={ handdleFilter }>Filtrar</button>
              </div>
            </form>
          )
        }
      </div>
      <div className='selected-filters'>
        {
          items && items.map((item, index) => (
            <div key={index}>
              {
                item.options && item.options.map(option =>
                  option.selected ? (
                    <span
                      key={ option.id }
                      className={`item ${option.selected ? 'selected' : ''} fade-in`}
                      onClick={ () => removeItem(option) }
                    >
                    { item.title }: { option.name }
                    <AiOutlineClose className='icon' />
                  </span>
                  ) : null
                )
              }
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default TableFilter
