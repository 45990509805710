import React, { useContext } from 'react';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';
import { handleNextPrevious, getPages } from '../../helpers/pagination/utils';
import AuthContext, { isOnlyProvider } from '../../utils/auth'
import './styles.scss';

// Componente que renderiza uma tabela de escopos contratados com paginação.
const HiredScopesTable = ({ data, currentPage, setCurrentPage, pageSize, onClickTd }) => {
  const userInfo = useContext(AuthContext);
  const onlyProvider = isOnlyProvider(userInfo.role);

  // Responsável por retornar a cor correspondente ao status de um escopo contratado.
  const getStatusColor = (status) => {
    const statusColors = {
      open: '#FABC34',
      approved: '#00B26B',
      waiting: '#707070',
      pendent: '#D92550',
      missing: '#ff8000',
    };

    return statusColors[status];
  };

  // Responsável por retornar a label correspondente ao status de um escopo contratado.
  const getStatusLabel = (status) => {
    const statusColors = {
      open: 'Em aberto',
      approved: 'Aprovada',
      waiting: 'Aguardando análise',
      pendent: 'Pendente com restrições',
      missing: 'Pendente Dados Fornecedor',
    };

    return statusColors[status];
  };

  // Responsável por retorna uma tabela de escopos contratados contendo paginação, com dados fornecidos pelo componente pai.
  return (
    <section className='provider-scopes-table fade-in'>
      <div className='table-container'>
        <table className='table table-striped table-borderless'>
          <thead>
            <tr>
              <th scope='col'><div id='scope-id'>ID do Escopo</div></th>
              <th scope='col'>Título do escopo</th>
              <th scope='col'>Gestor do contrato</th>
              <th scope='col'>Período da atividade</th>
              <th scope='col'>Acesso refeitório</th>
              <th scope='col'>Status</th>
              {
                    !onlyProvider && (
                      <th scope='col'>Fornecedor</th>
                    )
              }
            </tr>
          </thead>
          <tbody>
            {
              data.results && data.results.map((item, index) => (
                <tr
                  key={`provider-scope-table-row-${index}-${item.id}`}
                  className='fade-in'
                  onClick={() => onClickTd(item.id)}
                >
                  <td>{ item.id }</td>
                  <td style={{textAlign: 'left'}}>{ item.title }</td>
                  <td>{ item.requester }</td>
                  <td>{ item?.initial_date } - { item?.end_date }</td>
                  <td>{ item.meal }</td>
                  <td className='status'>
                    <div
                      style={{ backgroundColor: getStatusColor(item.status) }}
                      className='status-container'
                    >
                      { getStatusLabel(item.status) }
                    </div>
                  </td>
                  {
                    !onlyProvider && (
                      <td>{ item.provider_company }</td>
                    )
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className='paginator-container'>
        <nav>
          <ul className='pagination'>
            <li className={`page-item ${data.previous ? '' : 'disabled'}`}>
              <a
                className='page-link'
                onClick={() => handleNextPrevious(data.previous, setCurrentPage)}
                >
                 <div className='item-right'>
                    <TiChevronLeft />
                    <p>Anterior</p>
                  </div>
              </a>
            </li>
            {
              getPages(data.count, pageSize) && getPages(data.count, pageSize).map(page => (
                <li
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  className={`page-item ${page == currentPage ? 'active' : ''} `}>
                  <a className='page-link'>{ page }</a>
                </li>
              ))
            }
            <li className={`page-item ${data.next ? '' : 'disabled'}`}>
              <a
                className='page-link'
                onClick={() => handleNextPrevious(data.next, setCurrentPage)}
                >
                  <div className='item-right'>
                      <p>Próximo </p>
                      <TiChevronRight />
                  </div>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  )
};

export default HiredScopesTable;
