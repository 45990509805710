import React, { useState, useEffect, useContext } from 'react';

import ProviderScopesTable from '../../components/ProviderScopesTable';
import Loading from '../../components/Loading';
import TableFilter from '../../components/TableFilter';

import { DocumentManagementService } from '../../services'
import FilterContext from '../../components/SideNav/context';

import './styles.scss';

const ProviderHiredScopesTable = () => {
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  //const [filterItems, setFilterItems] = useState([])
  const [filterBody, setFilterBody] = useState(null);
  const pageSize = 10;
  const [searchText, setSearchText] = useState('');
  const { setFilter, setFilterItems, setChanges } = useContext(FilterContext);

  const getTableItems = async () => {
    let body = getBody();
    let response = {};

    if (!tableData) {
      try {
        const { data } = await DocumentManagementService.searchProviderHiredServiceScopes(1, pageSize);
        response = data;
      } catch(error) {}
    } else {
      try {
        const { data } = await DocumentManagementService.searchProviderHiredServiceScopes(currentPage, pageSize, body);
        response = data;
      } catch(error) {}
    }

    setTableData(response);
  }

  const getBody = () => {
    return { search: searchText, ...filterBody};
  }

  const getFilterItems = () => {
    const status = {
      title: 'Status', id: 'status', options: [
        {id: 'pendent', name: 'Pendente com restriçoes'},
        {id: 'open', name: 'Em aberto'},
        {id: 'approved', name: 'Aprovado'},
        {id: 'waiting', name: 'Aguardando análise'},
        {id: 'missing', name: 'Pendente Dados Fornecedor'},
      ]
    }
    setFilterItems([status])
  }

  const handdleFilterChange = (data) => {
    setFilterBody(data)
    setCurrentPage(1)
  }

  useEffect(() => {
    setFilter(true);
    setChanges(() => (value) => handdleFilterChange(value));
    getFilterItems();
    return () => {
      setFilter(false);
      setChanges(() => () => { });
      setFilterItems([]);
    }
  }, [])

  useEffect(() => {
    getTableItems();
  }, [currentPage, filterBody, searchText]);

  return (
    <section className='provider-hired-scopes-table-page'>
      <div className='page-header'>
        <div className='left'>
          {/* {
            filterItems.toString() && (
              <TableFilter
                data={ filterItems }
                handleChange={ handdleFilterChange }
              />
            )
          } */}
        </div>
      </div>
      <div className='table-wrapper'>
        {
          tableData ? (
            <ProviderScopesTable
              data={ tableData }
              currentPage={ currentPage }
              setCurrentPage={ setCurrentPage }
              pageSize={ pageSize }
              setSearch={ setSearchText }
            />
          ) : (
            <div className='load-container'>
              <Loading />
            </div>
          )
        }
      </div>
    </section>
  );
}

export default ProviderHiredScopesTable;
