import React from 'react';
import CookieConsent from "react-cookie-consent";
import './styles.scss';
import { daysToExpire, cookieSecurity, cookieName } from './../../constants/index';


const summaryMessage = 'Nós usamos Cookies e outras tecnologias semelhantes \
para melhorar a sua experiência em nossos serviços. Os Cookies que são \
estritamente necessários para operar este site são: Cookies funcionais \
para aprimorar sua experiência (por exemplo, lembrar suas configurações) \
e Cookies de desempenho para medir o desempenho do site e \
melhorar sua experiência. Ao utilizar nossos serviços, você \
concorda com tal monitoramento. '

const CookieAlert = ({onAccept}) => {
    return (
        <CookieConsent
            onAccept={onAccept}
            cookieName={cookieName}
            cookieValue={true}
            expires={daysToExpire}
            overlay={true}
            cookieSecurity={cookieSecurity}
            enableDeclineButton={true}
            setDeclineCookie={false}
            declineButtonText='Recusar'
            buttonText='Aceitar'
            containerClasses='cookie-content'
            buttonClasses='registerButton'
            buttonWrapperClasses='button-container'
            contentStyle={{
                textAlign: 'justify'
            }}
            buttonStyle={{
                width: '100px',
                height: '40px',
                fontSize: '14px',
                border: 'none',
                borderRadius: '4px',
                backgroundColor: '#3AC47D',
                color: 'white',
                outline: 'none',
            }}
            declineButtonStyle={{
                width: '100px',
                height: '40px',
                fontSize: '14px',
                borderRadius: '4px',
                backgroundColor: '#363636B5',
                color: 'white',
                outline: 'none',
                border: '1px solid white'
            }}
        >
            { summaryMessage }
            Informamos ainda que atualizamos nossa 
            <a target='_blank' href="http://www.gcb.com.br/pdfs/politica-de-privacidade.pdf"> [Política de Privacidade]</a>.
            Conheça nosso [Portal de Privacidade] e veja a nossa nova política.
        </CookieConsent>
    );
}


export default CookieAlert;