import CommonService from './CommonService';
import CoreService from './CoreService';
import UserService from './UserService';
import RobotService from './RobotService';
import VisitationService from './VisitationService';
import VehicleService from './VehicleService';
import ServiceScopeService from './ServiceScopeService';
import DocumentManagementService from './DocumentManagementService'
import ProviderService from './ProviderService'

export {
  CommonService, CoreService, UserService, VisitationService,
  RobotService, VehicleService, ServiceScopeService, DocumentManagementService, ProviderService
}
