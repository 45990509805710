import React, { useState, useEffect } from 'react';

import './styles.scss';

import { TiChevronRight, TiChevronLeft } from 'react-icons/ti';
import { FaTruckMoving } from 'react-icons/fa';

import { VisitationService, VehicleService } from '../../services/';

import Loading from '../Loading';
import Switch from 'react-switch';

import { isCpfValid } from './../../helpers/forms/isValid';
import { getPages, handleNextPrevious } from '../../helpers/pagination/utils';
import Toast from '../Toast';

const VehiclesTableActions = ({ vehicle }) => {
  const [active, setActive] = useState(vehicle.active);

  const handleToogleButton = async (vehicle, status) => {
    let vehicleId = vehicle.id;
    let body = {vehicleId, active: status};
    try {
      const { data } = await VehicleService.postVehicleStatus(body);
      setActive(data.active);
    } catch(error) {
      const { response : { data } } = error;
      let errorMessage = data.message || error.message;
      Toast.error(errorMessage);
    }
  }

  return (
    <div className='vehicles-table-actions'>
      <span className='switch-container'>
        <Switch
          checked={active}
          onChange={() => handleToogleButton(vehicle, !active)}
          onColor='#000'
          onHandleColor='#f0f0f0'
          checkedIcon={null}
          uncheckedIcon={null}
          handleDiameter={18}
          height={24}
          width={36}
        />
      <label className='label-switch'>{ active ? 'Sim' : 'Não'}</label>
      </span>
    </div>
  );
}

const headers = ['Placa', 'Estado', 'Cidade', 'Veículo', 'Nº (container)']

const VehiclesTable = ({ pageSize, driverFilter, driverId, cpf, fullList, selectVehicle, onClickRow}) => {
  const [vehiclesTableData, setVehiclesTableData] = useState([]);
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState();

  const handleVehiclesTableData = async () => {
    // obtem os veiculos de um motorista pelo cpf ou id
    let request = null;
    const body = {}

    if (cpf) {
      body.cpf = cpf;
    } else if (driverId) {
      body.id = driverId;
    }

    if ((cpf || driverId) && !fullList) {
      request = VisitationService.vehiclesFromDriver;
    } else if (fullList) {
      request = VisitationService.vehicles;
    }
    if (!request) return;

    setVehiclesTableData({...vehiclesTableData, results:[]});
    setIsLoading(true)
    const { data } = await request(currentPage, pageSize, body);
    setVehiclesTableData(data);
    setIsLoading(false)
  }

  useEffect(() => {
    handleVehiclesTableData();
  }, [currentPage])

  useEffect(() => {
    if (cpf && isCpfValid(cpf)) handleVehiclesTableData();
    else setVehiclesTableData([]);

  }, [cpf])

  useEffect(() => {
    if (fullList) handleVehiclesTableData();
  }, [fullList])

  return (
    <section className='vehicles-table fade-in'>
      <div className='header'>
        <FaTruckMoving className='icon' />
        VEÍCULOS
      </div>
      <div className='table-container'>
        {
          isLoading && (
            <div className='loading-container'>
              <Loading />
            </div>
          )
        }
        <table className='table table-striped table-borderless'>
          <thead>
            <tr>
            {
              headers.map(item => (
                <th key={item.id} scope='col'>{item}</th>
                ))
            }
            {
              !driverFilter
                && (
                  <th scope='col'>Ativo</th>
                )
            }
              {/* <th width='10%' className='pk' scope='col'>Data</th> */}
            </tr>
          </thead>
          <tbody>
            {
              vehiclesTableData.results && vehiclesTableData.results.map(row => (
                <tr key={row.id} className={onClickRow ? 'row-vehicle-table' : ''} onClick={() => {onClickRow && selectVehicle({ label: row.license_plate }, true)}}>
                  <th className='pk' scope='row'>{row.license_plate}</th>
                  <td>{row.city.state.uf}</td>
                  <td>{row.city.name}</td>
                  <td>{row.vehicle_type.name}</td>
                  <td>{row.container_number || '-'}</td>
                  {
                    !driverFilter && (
                      <td className='action'>
                        <VehiclesTableActions vehicle={row} />
                      </td>
                    )
                  }
                </tr>
              ))
            }

          </tbody>
        </table>
      </div>
      <div className='paginator-container'>
        <nav>
          <ul className='pagination'>
            <li className={`page-item ${vehiclesTableData.previous ? '' : 'disabled'}`}>
              <a
                className='page-link'
                onClick={() => handleNextPrevious(vehiclesTableData.previous, setCurrentPage)}
              >
                <TiChevronLeft />
              </a>
            </li>
            {
              getPages(vehiclesTableData.count, pageSize) && getPages(vehiclesTableData.count, pageSize).map(page => (
                <li
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  className={`page-item ${page == currentPage ? 'active' : ''} `}>
                  <a className='page-link'>{page}</a>
                </li>
              ))
            }
            <li className={`page-item ${vehiclesTableData.next ? '' : 'disabled'}`}>
              <a
                className='page-link'
                onClick={() => handleNextPrevious(vehiclesTableData.next, setCurrentPage)}
              >
                <TiChevronRight />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
}

export default VehiclesTable
