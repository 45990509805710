import React, { useEffect, useState } from 'react';

import './styles.scss';

import Toast from '../Toast';
import { ServiceScopeService } from '../../services';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';


const LinkProviderModal = ({ setModal, setConfirmModal, infoProviderScope, setInfoProviderScope, setLinkProviderScope }) => {
  const { register, handleSubmit, errors } = useForm();
  let history = useHistory();

  useEffect(() => {
    $('#purchase_order_number').mask('0000000000');
    $('#scope_service').mask('0#');
  }, []);

  const confirmLinkProvider = async (body) => {
    try {
      const { data } = await ServiceScopeService.infoPreLinkScopeService(body);
      setInfoProviderScope(data.message);
      setLinkProviderScope(body);
      setConfirmModal(true);
    } catch (err) {
      const { response: { data } } = err;
      let errors = data || { message: [data] } || {};
      Object.keys(errors).map(function (field) {
        Toast.error(errors[field]);
      });
    }
  }

  return (
    <div className='link-scope-service'>
      <div className='header'>
        Vincular novo escopo de serviço
      </div>
      <div className='body'>
        <form id='hook-form' className='link-scope-service-form' onSubmit={handleSubmit(confirmLinkProvider)} autoComplete="off">
          <div>
            <label className='label'>Número do pedido*</label>
            <input
              id='purchase_order_number'
              name='purchase_order_number'
              className={`input`}
              type='text'
              ref={register({
                required: 'Esse campo é obrigatório.',
              })}
            />
            {
              errors.purchase_order_number && (
                <span className='field-error'>
                  {errors.purchase_order_number.message}
                </span>
              )
            }
          </div>
          <div>
            <label className='label'>ID DO ESCOPO*</label>
            <input
              id='scope_service'
              name='scope_service'
              className={`input`}
              type='text'
              ref={register({
                required: 'Esse campo é obrigatório.',
              })}
            />
            {
              errors.scope_service && (
                <span className='field-error'>
                  {errors.scope_service.message}
                </span>
              )
            }
          </div>
        </form>
      </div>
      <div className='footer'>
        <button
          onClick={() => setModal(false)}
          className='btn cancel'
        >
          Voltar
        </button>
        <button
          type='submit'
          form='hook-form'
          className='btn ok'
        >
          Avançar
        </button>
      </div>
    </div>
  );
}

export default LinkProviderModal;
