import React, { useState, useEffect } from 'react';

import './styles.scss';

import Loading from '../../../components/Loading'
import CollaboratorForm from '../CollaboratorForm';

import Modal from '../../../components/Modal';
import { IoMdAdd, IoIosArrowForward } from 'react-icons/io';
import FireEmployeeModal from '../../../components/FireEmployeeModal'

const Collaborators = ({ scopeID, collaborators, getCollaborators, hiredScopeData }) => {
  const [collaboratorCollapse, setCollaboratorCollapse] = useState('')
  const [newCollaboratorCollapse, setNewCollaboratorCollapse] = useState(false);
  const [pageIsLoding, setPageIsLoading] = useState(true)
  const [linkFireEmployeeModal, setFireEmployeeModal] = useState(false);
  const [collaborator, setCollaborator] = useState({});

  const handleCollapse = (isNewScope, id) => {
    if (isNewScope) {
      // fecha os outros collapses
      setCollaboratorCollapse('')
      setNewCollaboratorCollapse(!newCollaboratorCollapse)
    } else {
      if (id == collaboratorCollapse) setCollaboratorCollapse('')
      else setCollaboratorCollapse(id)
    }
  }

  const getData = () => {
    setPageIsLoading(false)
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <section className='scope-collaborators-page-collaborators fade-in fade-in-1s'>
      {
        pageIsLoding && (
          <div className='loading'>
            <Loading />
          </div>
        )
      }
      {
        hiredScopeData && (
          <>
            <div className='collaborators'>
              <div className={`new-collaborator ${newCollaboratorCollapse ? 'open' : ''}`}>
                <div className='header'>
                  <IoMdAdd className='icon' />
                  <span onClick={() => handleCollapse(true)} className='add-text'>
                    Adicionar novo colaborador
                  </span>
                </div>
                {newCollaboratorCollapse && (
                  <div className={`body ${newCollaboratorCollapse ? 'open' : ''}`}>
                    <div className='form-collaborator'>
                      <CollaboratorForm
                        create={true}
                        ssmaRequirements={hiredScopeData.ssma_requeriments}
                        scopeID={scopeID}
                        collapse={() => setNewCollaboratorCollapse(false)}
                        onSuccess={getCollaborators}
                      />
                    </div>
                  </div>
                )}
              </div>
              {
                collaborators && collaborators.map(collaboratorElem => (
                  <div className='collaborator' key={collaboratorElem.id}>
                    <div className='header'>
                      <div className='left' onClick={() => handleCollapse(false, collaboratorElem.id)}>
                        <IoIosArrowForward className={`icon ${collaboratorCollapse == collaboratorElem.id ? '' : 'rotate'}`} />
                        <span className='name-text'>
                          {collaboratorElem.employee_provider.name}
                        </span>
                      </div>
                      <div className='right'>
                        <span className={`acess-text`}>
                          Acesso Vivix:
                          <span className={`${collaboratorElem.employee_provider.access_permitted === 'Bloqueado' ? 'blocked' : 'active'}`}>
                            {collaboratorElem.employee_provider.access_permitted}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className={`body ${collaboratorCollapse == collaboratorElem.id ? 'open' : ''}`}>
                      <CollaboratorForm
                        create={false}
                        ssmaRequirements={hiredScopeData.ssma_requeriments}
                        scopeID={scopeID}
                        collaborator={collaboratorElem}
                        collapse={() => setCollaboratorCollapse('')}
                        onSuccess={getCollaborators}
                        turnOff={() => {
                          setCollaborator(collaboratorElem)
                          setFireEmployeeModal(true)
                        }}
                      />
                    </div>
                  </div>
                ))
              }

            </div>
          </>
        )
      }
      <Modal
        active={linkFireEmployeeModal}
        disableFunction={setFireEmployeeModal}
        disableWhenClickAway={true}
        fixed={true}
      >
        <FireEmployeeModal employeeData={collaborator} getCollaborators={getCollaborators} setModal={setFireEmployeeModal} scopeID={scopeID} />
      </Modal>

    </section>
  )
}

export default Collaborators
