import React, { useState, useEffect } from 'react';

import './styles.scss';

import UserCard from '../../components/UserCard';

import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';

import { VisitationService } from '../../services';

import Input from '../../components/Input';
import SearchInput from '../../components/SearchInput';
import Loading from '../../components/Loading';

import { getPages, handleNextPrevious } from '../../helpers/pagination/utils';

const PreRegistration = () => {
  const [visitsTableData, setVisitsTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [userCardActive, setUserCardActive] = useState(null);
  const [userCardData, setUserCardData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const handleVisitsTableData = async () => {
    setVisitsTableData({...visitsTableData, results:[]});
    setIsLoading(true)
    try {
      const { data } = await VisitationService.preRegistrations(currentPage, pageSize, search)
      setVisitsTableData(data);
      setIsLoading(false)
    } catch (error) {
      const { data } = await error.response
      if(data.detail == 'Invalid page.') {
        setCurrentPage(1)
      }
    }
  }

  const handleCurrentPage = (page) => {
    setUserCardActive(null);
    setCurrentPage(page);
  }

  const handleUserCardActive = (id, data) => {
    if(id == userCardActive) {
      setUserCardActive(null)
      setUserCardData(data)
    }
    else {
      setUserCardActive(id)
      setUserCardData(data)
    }
  }

  useEffect(() => {
    handleVisitsTableData();
  }, [currentPage, search])

  return (
    <section className='pre-registrations-page'>
      {
        userCardActive != null && (
          <div className='user-card fade-in'>
            <UserCard
              visitID={ userCardActive }
              visitData={ userCardData }
              disableCard={ setUserCardActive }
            />
          </div>
        )
      }
      <div className='header-container'>
        <div className='header'>
          <div className='title'>NOVOS PRÉ-CADASTROS</div>
          <SearchInput
            setValue={ setSearch }
            placeholder='Pesquisar motorista (nome ou cpf)'
          />
        </div>
      </div>
      <div className='table-container'>
        {
          isLoading && (
            <div className='loading-container'>
              <Loading />
            </div>
          )
        }
        <table className='table table-striped table-borderless table-responsive-lg'>
          <thead>
            <tr>
              <th width='10%' className='pk' scope='col'>Motorista</th>
              <th scope='col'>CPF</th>
              <th scope='col'>Telefone</th>
              <th scope='col'>CNH</th>
              <th scope='col'>Validade CNH</th>
              <th scope='col'>Placa do veículo</th>
              {/* <th scope='col'>Veículo</th> */}
              {/* <th scope='col'>Estado</th>
              <th scope='col'>Cidade</th> */}
              {/* <th scope='col'>Cliente</th>
              <th scope='col'>Transportadora</th>
              <th scope='col'>Nº (container)</th> */}
              <th scope='col'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {
              visitsTableData.results && visitsTableData.results.map(item => (
                <tr className='fade-in' key={item.id}>
                  <th className='pk' scope='row'>{ item.driver.full_name }</th>
                  <td>{ item.driver.cpf }</td>
                  <td>{ item.driver.phone }</td>
                  <td>{ item.driver.cnh_number || '-' }</td>
                  <td>{ item.driver.cnh_expires_at || '-' }</td>
                  <td>{ item.vehicle.license_plate }</td>
                  {/* <td>{ item.vehicle.vehicle_type.name }</td> */}
                  {/* <td>{ item.vehicle.city.state.uf }</td>
                  <td>{ item.vehicle.city.name }</td> */}
                  {/* <td>{ item.client_name || '-' }</td>
                  <td>{ item.company_name || '-' }</td>
                  <td>{ item.vehicle.container_number || '-' }</td> */}
                  <td className='action'>
                    <button
                      onClick={ () => handleUserCardActive(item.id, item) }
                      className='button'
                    >
                      Ver cadastro
                    </button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className='paginator-container'>
        <nav>
          <ul className='pagination'>
            <li className={`page-item ${visitsTableData.previous ? '' : 'disabled'}`}>
              <a
                className='page-link'
                onClick={() => handleNextPrevious(visitsTableData.previous, setCurrentPage)}
                >
                  <div className='item-right'>
                    <TiChevronLeft />
                    <p>Anterior</p>
                  </div>
              </a>
            </li>
            {
              getPages(visitsTableData.count, pageSize) && getPages(visitsTableData.count, pageSize).map(page => (
                <li
                  key={page}
                  onClick={() => handleCurrentPage(page)}
                  className={`page-item ${page == currentPage ? 'active' : ''} `}>
                  <a className='page-link'>{ page }</a>
                </li>
              ))
            }
            <li className={`page-item ${visitsTableData.next ? '' : 'disabled'}`}>
              <a
                className='page-link'
                onClick={() => handleNextPrevious(visitsTableData.next, setCurrentPage)}
                >
                  <div className='item-right'>
                      <p>Próximo </p>
                      <TiChevronRight />
                  </div>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
}

export default PreRegistration
